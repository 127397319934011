import {useEffect, useMemo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {noop} from 'lodash';

import {trackEvent} from '~/shared/services/analytics';
import {createLogger} from '~/shared/logging';
import {media} from '~/shared/theme/utils';
import {pushRoute, getCurrentLocation, removeQueries} from '~/shared/router';
import {selectUserData, selectUserLoaded, selectUserLoading} from '~/shared/store/selectors';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import headerBgUrl from '~/assets/images/homepage/background-header.jpg';

import LoginOrRegister from './LoginOrRegister';

const LoginOrRegisterWrapper = styled.div`
  background-color: ${({theme}) => theme.modal.backgroundColor};
  height: 100vh;
  ${media.minTablet`
    background-image: url(${headerBgUrl});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    overflow: visible;
    min-height: 0;
    flex-shrink: 0;
  `}
`;

const logger = createLogger('SignInPage');

const SignInPage = () => {
  const userData = useSelector(selectUserData);
  const userLoaded = useSelector(selectUserLoaded);
  const userLoading = useSelector(selectUserLoading);

  const {query} = getCurrentLocation();
  const {returnUrl, successReturnUrl: successReturnUrlFromQuery} = query;

  const successReturnUrl = successReturnUrlFromQuery || returnUrl;
  const strongLoginFromQuery = query.strongLogin === 'true';
  const rootReturnUrlFromQuery = query.rootReturnUrl;

  const isStrongLogin = (rootReturnUrlFromQuery || successReturnUrl) && strongLoginFromQuery;

  useEffect(() => {
    logger.log('*** initializing sign in page ***');

    const noUser = userLoaded && !userLoading && !userData;

    if (isStrongLogin && noUser) {
      removeQueries(['strongLogin', 'rootReturnUrl', 'successReturnUrl']);
    }

    const stayOnPage = isStrongLogin || !userData;
    if (stayOnPage) {
      if (query?.mode !== 'login') {
        trackEvent('hasViewedRegistrationPopup');
      }
      return;
    }

    if (!successReturnUrl) {
      navigateToDefaultStartPage({keepQuery: false});
      return;
    }

    pushRoute(decodeURIComponent(successReturnUrl), {keepQuery: false});
  }, [userData, successReturnUrl, isStrongLogin, userLoaded, userLoading, query?.mode]);

  const backUrl = useMemo(() => {
    if (!returnUrl || returnUrl.includes('user-report')) {
      return '/';
    }

    return returnUrl;
  }, [returnUrl]);

  return (
    <LoginOrRegisterWrapper>
      <LoginOrRegister
        pageView
        backgroundColor="transparent"
        mode={query.mode}
        backButtonUrl={backUrl}
        isStrongLogin={isStrongLogin}
        onSuccess={
          !rootReturnUrlFromQuery
            ? noop
            : () => {
                pushRoute(rootReturnUrlFromQuery, {hard: true, root: true});
              }
        }
      />
    </LoginOrRegisterWrapper>
  );
};

export default SignInPage;
