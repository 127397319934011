import {useCallback, useMemo, useState} from 'react';

import styled from 'styled-components';

import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {flipOnLTR} from '~/shared/theme/utils';
import {SupportedLanguages, DEFAULT_CULTURE, DEFAULT_LANGUAGE_KEY, EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {setCurrentLanguageCookie} from '~/shared/utils/cookies';
import Button from '~/shared/components/Button';
import {changeLanguagePrefixToUrlOrPath, getLocalizationService} from '~/shared/services/localisationService';
import {Link} from '~/shared/router';
import heIcon from '~/assets/images/icons/he.svg';
import enIcon from '~/assets/images/icons/en.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

const MAP_ICONS = {
  he: heIcon,
  en: enIcon,
};

// #region styled components
const Root = styled.div`
  display: flex;
`;

const ChangeLangButton = styled(Button)`
  direction: ltr;
  ${flexCenterVertically};
  height: 30px;
  align-self: center;
  padding: 0;
  ${flipOnLTR`
    margin-right: 24px;
  `}
`;

const CircleFlag = styled(ImageWithAlt)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ClickOutsideWithoutExtraProps = ({isOpen: _ignore, ...rest}) => <ClickOutsideDiv {...rest} />;
const Dropdown = styled(ClickOutsideWithoutExtraProps).attrs({
  tabIndex: -1,
})`
  direction: ltr !important;
  display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
  width: 160px;
  position: absolute;
  top: 35px;
  ${flipOnLTR`
    left: 0px;
  `}
  padding: 10px 0;
  box-shadow: ${({theme}) => theme.shadows.shadow2};
  background: ${({theme}) => theme.colors.surface};
  border-radius: 4px;
  color: ${({theme}) => theme.colors.gray000};
  z-index: ${({theme}) => theme.zIndex.languageChangeButton};
`;

const languageOptionAttrs = () => ({role: 'link'});
const LanguageOption = styled(Link).attrs(languageOptionAttrs)`
  ${flexCenterVertically};
  height: 40px;
  cursor: pointer;
  padding: 0 20px;

  &:hover {
    background: ${({theme}) => theme.colors.gray910};
  }
`;

const OptionText = styled.div`
  margin-left: 10px;
`;
// #endregion

const LanguageSelector = () => {
  const {t, currentLanguageKey} = getLocalizationService();
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguageObj = useMemo(
    () => SupportedLanguages.find(({key}) => key === currentLanguageKey) || EMPTY_OBJECT,
    [currentLanguageKey],
  );

  const onDropdownClickOutside = useCallback(
    e => {
      if (!isOpen) return;
      e.stopPropagation();
      setIsOpen(false);
    },
    [isOpen],
  );

  const handleLanguageSelection = useCallback(
    (languageKey = DEFAULT_LANGUAGE_KEY) => {
      if (currentLanguageObj.key === languageKey) {
        setIsOpen(false);
        return;
      }

      setIsOpen(false);

      const culture =
        SupportedLanguages.find(({key: supportedLanguageKey}) => supportedLanguageKey === languageKey)?.culture ||
        DEFAULT_CULTURE;

      setCurrentLanguageCookie({culture, languageKey});
      // eslint-disable-next-line no-restricted-properties
      const {pathname, search} = window.location || '';
      // eslint-disable-next-line no-restricted-properties
      let fullPathname = pathname;
      if (search) {
        fullPathname += search;
      }
      // eslint-disable-next-line no-restricted-properties
      const newUrl = changeLanguagePrefixToUrlOrPath(fullPathname, languageKey);
      // eslint-disable-next-line no-restricted-properties
      window.location.replace(newUrl);
    },
    [currentLanguageObj.key],
  );

  return (
    <Root>
      <ChangeLangButton
        isOpen={isOpen}
        onClick={() => setIsOpen(s => !s)}
        isExpandable
        aria-controls="header-language-dropdown"
        aria-label={t('change_language')}
      >
        <CircleFlag src={MAP_ICONS[currentLanguageObj.icon]} alt={currentLanguageObj.name} />
      </ChangeLangButton>

      <Dropdown isOpen={isOpen} onClickOutside={onDropdownClickOutside} id="header-language-dropdown">
        {SupportedLanguages.map(({key, name, icon}) => (
          <LanguageOption key={key} onClick={() => handleLanguageSelection(key)}>
            <CircleFlag src={MAP_ICONS[icon]} noAlt />
            <OptionText>{name}</OptionText>
          </LanguageOption>
        ))}
      </Dropdown>
    </Root>
  );
};

export default LanguageSelector;
