import {useContext} from 'react';

import BillingLines from '~/shared/components/BillingLines';

import ShoppingCart from './ShoppingCart';

const Billings = () => {
  const {currentModalName, billingLines, currentCoupon} = useContext(ShoppingCart.Context);
  return (
    <BillingLines
      currentModalName={currentModalName}
      billingLines={billingLines}
      currentCoupon={currentCoupon}
      isLoading={false}
    />
  );
};

export default Billings;
