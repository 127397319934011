// To be used only by managers/store modules

import {omit} from 'lodash';

import {mapAddressFromServerOrCookies} from '~/shared/utils/address';

import {makeThunkAsyncActionCreator, makeActionCreator} from '../redux-toolbelt';
import {Address, IShoppingCart, ShoppingCartDish, User} from '../models';

export const updateAddress = makeActionCreator<'updateAddress', {address: Address; shouldUpdateAddressKey: boolean}>(
  'updateAddress',
);

export const clearOrderData = makeActionCreator<'clearOrderData', never>('clearOrderData');

export const setShoppingCartGuid = makeActionCreator<
  'setShoppingCartGuid',
  Partial<{shoppingCartGuid: string; shoppingCartGuidTimestamp: number}>
>('setShoppingCartGuid');

export const setBillingLines = makeActionCreator<'setBillingLines', IShoppingCart['billingLines']>('setBillingLines');
export const updateLastShoppingCartDishId = makeActionCreator<'updateLastShoppingCartDishId', number>(
  'updateLastShoppingCartDishId',
);
export const setDishes = makeActionCreator<'setDishes', ShoppingCartDish[]>('setDishes');

export const registerUser = makeThunkAsyncActionCreator<SignUpCredentials, User>(
  'registerUser',
  async (signUpCredentials, {apiService}) => {
    const {
      data: rawUser,
      errors,
      success: userRegisterSuccess,
    } = await apiService.registerUser({autoGeneratePassword: true, ...signUpCredentials});

    if (userRegisterSuccess === false) {
      return Promise.reject({register: {errors}});
    }

    return {...rawUser, addresses: []};
  },
);

export const login = makeThunkAsyncActionCreator<UserCredentials, User>(
  'login',
  async (userCredentials, {apiService}) => {
    const userLoginCredentials = omit(userCredentials, ['mode', 'returnUrl']);
    const isVerificationLogin = Boolean(
      'authenticationCode' in userLoginCredentials &&
        userLoginCredentials.authenticationCode &&
        userLoginCredentials.authenticationToken,
    );

    const apiServiceAction = isVerificationLogin ? 'getUserV2' : 'getUser';
    const {
      data: user,
      errors,
      success: userFetchSuccess,
    } = await apiService[apiServiceAction](userLoginCredentials);

    if (userFetchSuccess === false) {
      const errorData = {requireCaptcha: user.requireCaptcha, errors};
      return Promise.reject({login: errorData});
    }

    const {data: addressesFromServer} = await apiService.getUserAddresses({});

    const addresses = addressesFromServer.map(mapAddressFromServerOrCookies) as Address[];
    return {...user, addresses};
  },
);

export interface SignUpCredentials {
  firstName: string;
  lastName: string;
  cellPhone: string;
  wantPromotion: boolean;
  email: string;
  facebookUserId: string;
  facebookUserAccessToken: string;
  password: string;
  activationToken: string;
  activationCode: string;
  autoGeneratePassword?: string;
  shoppingCartGuid?: string;
  websiteID?: string;
  domainID?: string;
  culture?: string;
  uiCulture?: string;
}

export interface LoginWithPassword {
  email: string;
  password: string;
}

export interface LoginWithFacebook {
  facebookUserId: string;
  facebookUserAccessToken: string;
}

interface LoginWithMobileCode {
  email: string;
  authenticationToken: string;
  authenticationCode: string;
}

export type UserCredentials = Partial<LoginWithPassword | LoginWithMobileCode | LoginWithFacebook>;
