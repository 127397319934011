import {BillingLineType} from '~/shared/consts/checkoutConsts';

export const HIDDEN_BILLING_LINES_WITH_PERCENT_COUPON = [
  BillingLineType.DiscountCoupon,
  BillingLineType.DeliveryDiscount,
];

export const BILLING_LINES_WITHOUT_DISCOUNTS_DATA = [
  ...HIDDEN_BILLING_LINES_WITH_PERCENT_COUPON,
  BillingLineType.TotalToCharge,
  // Delivery fee discounts data has a complex calculation logic and should be excluded from regular calculation
  BillingLineType.DeliveryFee,
];
