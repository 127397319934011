import {useEffect, useState} from 'react';

function useFormAutoFocus(onFocus?: (element: HTMLElement) => void) {
  const [node, formRef] = useState<HTMLFormElement>();

  useEffect(() => {
    if (!node) {
      return;
    }

    const {elements} = node;
    if (elements && elements.length > 0) {
      const nodeElements = Array.from(elements) as HTMLElement[];
      const firstFocusableElement = nodeElements.find(element => {
        return typeof element.focus === 'function' && (element.tagName === 'INPUT' || element.tagName === 'SELECT');
      });
      if (firstFocusableElement) {
        firstFocusableElement.focus();
        if (onFocus) {
          onFocus(node);
        }
      }
    }
  }, [node, onFocus]);

  return formRef;
}

export default useFormAutoFocus;
