import {useState, useEffect} from 'react';

import GoogleMap from 'google-map-react';

import {googleApisKey} from '~/shared/config';

import {getLocalizationService} from '../services/localisationService';

const useGoogleMapScript = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const {currentLanguageKey} = getLocalizationService();

  useEffect(() => {
    const loadGoogleMap = async () => {
      await GoogleMap.googleMapLoader({
        key: googleApisKey,
        language: currentLanguageKey,
        region: 'il',
        libraries: ['places'],
      });

      setIsLoaded(true);
    };

    loadGoogleMap();
  }, [currentLanguageKey]);

  return isLoaded;
};

export default useGoogleMapScript;
