import {useState, memo} from 'react';

// @ts-expect-error config needs to be rewritten as ts
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment-timezone';

import {getLocalizationService} from '~/shared/services/localisationService';
import DiagonalHeaderView from '~/shared/components/DiagonalHeaderView';
import {attachModal} from '~/shared/components/ReduxModal';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {MoneyCardActivationFormType} from '~/shared/consts/checkoutConsts';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {pushRoute, refreshPage} from '~/shared/router';
import {getLegacySiteUrl} from '~/shared/services/urlService';
import ErrorBox from '~/shared/components/ErrorBox';
import CreditCardNumberInput from '~/shared/components/CreditCardNumberInput';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {selectMoneycardActivationRequiredType} from '~/shared/store/selectors';
import apiService from '~/shared/services/apiService';
import {body13Bold} from '~/shared/theme/typography';
import {PopupModalBase} from '~/shared/components/Modals';
import {setCurrentModal, setMoneyCardActivationStatus} from '~/shared/store/actions';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';

const Content = styled.div`
  background-color: ${({theme}) => theme.activeMoneyCardModal.background};
  height: 50vh;
  margin-top: -25px;
  padding: 0 10px;
  ${media.minTablet`
    height: auto;
    margin-top: 30px;
    padding: 0;      
  `}
`;
const Text = styled.div`
  ${flipOnLTR`
    text-align: right;
  `}
  ${media.minTablet`
    padding: 15px 120px;      
  `}
`;

const Actions = styled.div`
  margin-bottom: ${({type}) => (type === MoneyCardActivationFormType.Maccabi ? '-20px' : '50px')};
  padding: 0 5px 20px 5px;
  ${media.minTablet`
    padding: 0px 120px 0 110px;
    display: flex;
  `}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 3vh 0;
  ${media.minTablet`
    ${flipOnLTR`
      &:last-child {
        margin-right: 20px;
      }   
    `}     
  `}
`;

const ErrorWrapper = styled.div`
  padding: 0 5px;
  ${media.minTablet`
    padding: 10px 120px 0 110px;
    display: flex;
  `}
`;

const Button = styled.button`
  flex: 1;
  height: 48px;
  ${body13Bold};
  border: 1px solid;
  background-color: ${({theme}) => theme.actionButton.enabled.backgroundColor};
  border-color: ${({theme}) => theme.actionButton.enabled.backgroundColor};
  color: ${({theme}) => theme.actionButton.enabled.color};
  width: 100%;
  ${({theme, invert}) =>
    invert &&
    `
    background-color: transparent;
    border-color: ${theme.actionButton.invert.borderColor};
    color: ${theme.actionButton.invert.color};
  `}
`;

const DefaultActivation = ({
  cardNumber,
  setCardNumber,
  type,
  onSnooze,
  onActive,
  submitError,
  setSubmitError,
  cardInputError,
  setCardInputError,
}) => {
  const {t} = getLocalizationService();

  return (
    <Content>
      <Text id="modal-title">{t('to_active_new_card_enter_16_digs_of_your_card')}</Text>
      <CreditCardNumberInput
        setSubmitError={setSubmitError}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
        cardInputError={cardInputError}
        setCardInputError={setCardInputError}
      />
      {submitError.show && (
        <ErrorWrapper>
          <ErrorBox withArrow={false}>{submitError.errorMsg}</ErrorBox>
        </ErrorWrapper>
      )}
      <Actions type={type}>
        <ButtonWrapper>
          <Button invert onClick={onSnooze}>
            {type === MoneyCardActivationFormType.Maccabi ? t('did_not_get_my_card_yet') : t('remind_me_later')}
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button onClick={onActive}>{t('activate_card')}</Button>
        </ButtonWrapper>
      </Actions>
      {type === MoneyCardActivationFormType.Maccabi && (
        <Text>
          <div>
            *
            {t(
              'did_not_get_your_card_yet_do_not_worry_you_can_order_a_pickUp_Using_the_card_in_a_restaurant_is_enabled_only_after_activation',
            )}
          </div>
          <div>*{t('your_credit_card_will_sync_to_TenBis_card_automatically_from_the_moment_of_activation')}</div>
        </Text>
      )}
    </Content>
  );
};

const activationMap = {
  Default: {
    snoozeTime: 24,
    Component: DefaultActivation,
  },
  Maccabi: {
    snoozeTime: 1,
    Component: DefaultActivation,
  },
};

const ActivateMoneycard = ({closeModal}) => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const moneycardActivationRequiredType = useSelector(selectMoneycardActivationRequiredType);

  const [cardNumber, setCardNumber] = useState([]);
  const [submitError, setSubmitError] = useState({show: false, errorMsg: ''});
  const [cardInputError, setCardInputError] = useState(false);

  const currentActivation = activationMap[moneycardActivationRequiredType || MoneyCardActivationFormType.Default];
  const {Component} = currentActivation;

  const onSnooze = () => {
    const snoozeTo = moment().add(currentActivation.snoozeTime, 'h').format();
    window.localStorage.setItem(LocalStorageKeys.MONEYCARD_ACTIVATION_SNOOZE_TIME, JSON.stringify(snoozeTo));
    refreshPage();
    closeModal();
  };

  const onActive = async () => {
    setSubmitError({show: false, errorMsg: ''});

    const cardNumberError = cardNumber.some(numberSection => {
      return numberSection.length < 4;
    });

    if (cardNumber.length === 0 || cardNumberError) {
      setCardInputError(true);
      setSubmitError({show: true, errorMsg: t('invalid_moneycard_number')});
      return;
    }

    const card = cardNumber.join('');

    try {
      const {data} = await apiService.activateMoneycard({
        fullMoneycardNumber: card,
        type: moneycardActivationRequiredType,
      });

      await dispatch(setMoneyCardActivationStatus(false));
      dispatch(
        setCurrentModal('successModal', {
          text: 'card_activated',
          title: 'thank_you',
          btnText: 'ok',
          onSuccess: () => {
            if (data === null || data === '') {
              ManagerProvider.login();
              return;
            }
            pushRoute(getLegacySiteUrl(data), {hard: true});
          },
        }),
      );
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, onActive);
        return;
      }
      
      setSubmitError({show: true, errorMsg: error?.message});
    }
  };

  return (
    <PopupModalBase>
      <DiagonalHeaderView title={t('activate_new_card')} titleId="modal-title">
        <Component
          {...{
            cardNumber,
            setCardNumber,
            type: moneycardActivationRequiredType,
            onSnooze,
            onActive,
            cardInputError,
            setSubmitError,
            setCardInputError,
            submitError,
          }}
        />
      </DiagonalHeaderView>
    </PopupModalBase>
  );
};

const EnhancedActivateMoneycard = memo(ActivateMoneycard);
EnhancedActivateMoneycard.hideCloseButton = true;

attachModal('activateMoneycard', EnhancedActivateMoneycard);
