import styled from 'styled-components';

import Checkbox from '~/shared/components/Checkbox';
import Input from '~/common/components/Input';
import {flexSpace} from '~/shared/theme/FlexLayout';

const Root = styled.div`
  ${flexSpace};
`;

const OtlRuleLine = ({ruleName, inputType, value: formValueObject, onChange}) => {
  return (
    <Root>
      <Checkbox
        value={formValueObject.isActive}
        onValueChange={checked => onChange({...formValueObject, isActive: checked})}
        label={ruleName}
      />
      <Input
        inputType={inputType}
        disabled={!formValueObject.isActive}
        onChange={e => onChange({...formValueObject, value: e.target.value})}
        value={formValueObject.isActive ? formValueObject.value : ''}
        fontSize={12}
        height={30}
      />
    </Root>
  );
};

export default OtlRuleLine;
