export const checkTimePast = ({storedDate, days}: {storedDate: string | null; days: number}) => {
  const currentDate = Date.now();
  const timeToCheck = 24 * 1000 * 60 * 60 * days;

  if (!storedDate) {
    return true;
  }
  const delta = Number(storedDate) - currentDate;

  return delta > timeToCheck;
};
