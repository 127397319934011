import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';

import {CheckoutPayment, Payment} from '../store/models';

const createPaymentMethodTypePredicate =
  (methodType: EnumValueType<typeof PaymentMethodTypes>) =>
    ({paymentMethod, assigned}: Payment) =>
      paymentMethod === methodType && !assigned;

export const constructCheckoutPayments = (
  availablePayments: Payment[] = [],
  currentCheckoutPayments: CheckoutPayment[],
  checkoutPaymentExtraProps: Omit<CheckoutPayment, keyof Payment | 'isDisabled'>,
) => {
  const defaultAvailablePayments =
    currentCheckoutPayments?.length > 0
      ? availablePayments.map(payment => {
        const paymentExistsInCheckout = currentCheckoutPayments.find(
          checkoutPayment => checkoutPayment.cardId === payment.cardId && !checkoutPayment.isDisabled,
        );
        return {...payment, assigned: !!paymentExistsInCheckout};
      })
      : availablePayments;

  const assignedPayment = defaultAvailablePayments.filter(({assigned}) => assigned);

  const firstCreditCard = defaultAvailablePayments
    .filter(createPaymentMethodTypePredicate(PaymentMethodTypes.CREDIT_CARD))
    .sort((a, b) => b.cardId - a.cardId)[0];
  const payPalPayment = defaultAvailablePayments.find(createPaymentMethodTypePredicate(PaymentMethodTypes.PAYPAL));
  // When switching delivery rule type between Asap and Pooled, cash option will be excluded from the current available
  // payments as there is no cash in pool orders, the cash payment should be selected from getAvailablePayments response
  const cashPayment = availablePayments.find(createPaymentMethodTypePredicate(PaymentMethodTypes.CASH));
  const hasAssignedCash = assignedPayment.some(payment => payment.paymentMethod === PaymentMethodTypes.CASH);

  const additionalCards = [firstCreditCard, payPalPayment]
    .filter(isDefinedPayment)
    .filter(p => !wasPaymentRemovedByUser(p, currentCheckoutPayments as Payment[]));

  if (cashPayment && !hasAssignedCash) {
    additionalCards.push(cashPayment);
  }

  return [
    ...assignedPayment.map(p => ({...p, isDisabled: false})),
    ...additionalCards.map(p => ({...p, isDisabled: true})),
  ].map<CheckoutPayment>(i => ({...i, ...checkoutPaymentExtraProps}));
};

function isDefinedPayment(p?: Payment): p is Payment {
  return !!p;
}

function wasPaymentRemovedByUser(p: Payment, currentCheckoutPayments: Payment[]) {
  return (
    !!currentCheckoutPayments.length &&
    !currentCheckoutPayments.find(cp => p.paymentMethod === cp.paymentMethod && p.cardId === cp.cardId)
  );
}
