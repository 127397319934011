import {Action, Dispatch} from 'redux';

import actions from '~/shared/store/actions';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import apiService from '~/shared/services/apiService';
import {createLogger} from '~/shared/logging';
import {splitOnFirstSpace} from '~/shared/utils/general';
import {
  LoginWithFacebook,
  LoginWithPassword,
  SignUpCredentials,
} from '~/shared/store/storeModules/restrictedSharedActions';
import {i18nHelpersProps} from '~/shared/services/localisationService';

const logger = createLogger('FacebookLoginOrSignUpHelper');

export const onForgotPassword = ({dispatch, email}: {dispatch: Dispatch<Action>; email: string}) => {
  dispatch(actions.resetPassword({emailAddress: email}));
  dispatch(
    actions.setCurrentModal('successModal', {
      title: 'reset_password',
      text: 'an_email_to_complete_setting_new_password_process_was_sent_to_you',
      btnText: 'ok',
    }),
  );
};

type SubmitType = LoginWithFacebook & LoginWithPassword;

interface onSubmitPasswordProps extends SubmitType {
  onSubmit: (args: SubmitType) => void;
}

export const onSubmitPassword = async ({
  email,
  facebookUserAccessToken,
  facebookUserId,
  password,
  onSubmit,
}: onSubmitPasswordProps) => {
  onSubmit({email, password, facebookUserAccessToken, facebookUserId});
};

type SubmitSignUpVerificationType = {
  authenticationCode?: string;
  setResErrors: (value: string | null) => void;
  signUpDetails: SignUpCredentials & {authenticationCode: string; authenticationToken: string; fullName: string};
  closeModal: () => void;
  dispatch: Dispatch<Action>;
};

export const onSubmitSignUpVerification = ({
  authenticationCode,
  setResErrors,
  signUpDetails,
  closeModal,
  dispatch,
}: SubmitSignUpVerificationType) => {
  if (!authenticationCode) {
    logger.error('onSubmitSignUpVerification didnt receives an authenticationCode');
    return;
  }

  setResErrors(null);
  dispatch(actions.clearUserWithLoaded());

  const [firstName, lastName] = splitOnFirstSpace(signUpDetails.fullName);

  ManagerProvider.registerUser({
    ...signUpDetails,
    firstName,
    lastName,
    authenticationCode,
  })
    .then(userData => {
      if (userData) {
        closeModal();
      }
    })
    .catch(e => {
      setResErrors(e.register?.message || e.message || 'activation_code_entry_is_wrong_please_try_again');
    });
};

interface submitVerificationProps {
  email: string;
  facebookUserAccessToken: string;
  facebookUserId: string;
  authenticationToken: string;
  authenticationCode: string;
}
interface onSubmitVerificationProps extends submitVerificationProps {
  onSubmit: (args: submitVerificationProps) => void;
}

export const onSubmitVerification = ({
  onSubmit,
  authenticationCode,
  email,
  facebookUserAccessToken,
  facebookUserId,
  authenticationToken,
}: onSubmitVerificationProps) => {
  onSubmit({email, facebookUserAccessToken, facebookUserId, authenticationCode, authenticationToken});
};

type SignUpSubmitType = {
  entries: SignUpCredentials;
  setSignUpDetails: (value: SignUpCredentials & {authenticationToken: string}) => void;
  setIsVerificationStep: (value: boolean) => void;
  setResErrors: (value: string[] | null) => void;
};

export const onSignUpSubmit = async ({
  entries,
  setSignUpDetails,
  setIsVerificationStep,
  setResErrors,
}: SignUpSubmitType) => {
  setResErrors(null);
  try {
    const {data} = await apiService.getActivationTokenAndSendActivationCodeToUser({
      cellPhone: entries.cellPhone,
      email: entries.email,
    });

    setSignUpDetails({authenticationToken: data as string, ...entries});
    setIsVerificationStep(true);
  } catch (e: any) {
    setResErrors(e?.message || 'something_went_wrong');
  }
};

type ToStepVerificationModeType = {
  email: string;
  setEmailEntry: (value: string) => void;
  setIsPasswordStep: (value: boolean) => void;
  setVerificationDetails: (value: {
    authenticationToken: string;
    isPhoneMethod: boolean;
    lastFourPhoneDigits: string;
  }) => void;
  setIsVerificationStep: (value: boolean) => void;
  setResErrors: (value: string[]) => void;
  t: i18nHelpersProps['t'];
};
export const toStepVerificationMode = async ({
  email,
  setEmailEntry,
  setIsPasswordStep,
  setVerificationDetails,
  setIsVerificationStep,
  setResErrors,
  t,
}: ToStepVerificationModeType) => {
  setEmailEntry(email);

  try {
    const {data}: Record<string, any> = await apiService.getUserAuthenticationDataAndSendAuthenticationCodeToUser({
      email,
    });
    const isPasswordScreen = data.authenticationMethod === 'Password';
    if (isPasswordScreen) {
      setIsPasswordStep(true);
      return;
    }

    const isPhoneMethod = data.codeAuthenticationData.sendingMethod === 'Phone';
    setVerificationDetails({
      authenticationToken: data.codeAuthenticationData.authenticationToken,
      isPhoneMethod,
      lastFourPhoneDigits: data.codeAuthenticationData.lastFourPhoneDigits,
    });

    setIsVerificationStep(true);
  } catch (e: any) {
    const errorType = 'Error while fetching getUserAuthenticationDataAndSendAuthenticationCodeToUser';
    logger.error(errorType, {email, e, fingerprint: errorType});
    setResErrors(e?.message || t('something_went_wrong'));
  }
};
