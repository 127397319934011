import {memo} from 'react';

import {Form} from 'react-final-form';

import FormField from '~/shared/components/FormField';
import {
  FieldWrapper,
  FormWrapper,
  LoginFormFieldsWrapper,
  HeaderText,
  SubmitButton,
  StyledErrorText,
} from '~/shared/components/loginStyled';

import FacebookSection from './FacebookSection';

const LoginForm = ({
  onSubmitEmail,
  openFacebook,
  loginText,
  isFB,
  backgroundColor,
  requireCaptcha,
  initialValues,
  setFocusElementRef,
  resErrors,
  setResErrors,
  t,
}) => (
  <>
    <Form
      onSubmit={onSubmitEmail}
      initialValues={initialValues}
      validateOnBlur
      render={({handleSubmit}) => (
        <FormWrapper isLogin onSubmit={handleSubmit}>
          <HeaderText id="modal-title">{t('login_form_header')}</HeaderText>
          <LoginFormFieldsWrapper>
            <FieldWrapper>
              <FormField
                {...{
                  name: 'email',
                  placeholder: t('email_address'),
                  type: 'email',
                  required: true,
                  inputProps: {
                    autoComplete: 'email',
                    onKeyUp: () => {
                      if (!resErrors) {
                        return;
                      }

                      setResErrors(null);
                    },
                  },
                  className: 'white-border-on-contrast',
                }}
              />
            </FieldWrapper>
            {resErrors && <StyledErrorText>{resErrors}</StyledErrorText>}
          </LoginFormFieldsWrapper>
          <SubmitButton
            ref={setFocusElementRef}
            requireCaptcha={requireCaptcha}
            data-test="login-submit"
            type="submit"
          >
            {loginText || t('login')}
          </SubmitButton>
        </FormWrapper>
      )}
    />
    {
      !isFB && (
        <FacebookSection {...{
          t,
          backgroundColor,
          onClick: openFacebook,
          btnText: t('quick_register_with_facebook'),
          btnAriaLabel: t('quick_register_with_facebook_aria_label'),
        }}
        />
      )
    }
  </>
);

export default memo(LoginForm);
