import {useSelector} from 'react-redux';

import BillingLinesView, {IBillingLinesView} from '~/shared/components/BillingLines';
import {selectCurrentModalName} from '~/shared/store/selectors';

const BillingLines = (props: Omit<IBillingLinesView, 'currentModalName'>) => {
  const currentModalName = useSelector(selectCurrentModalName);
  return <BillingLinesView {...props} currentModalName={currentModalName} />;
};

export default BillingLines;
