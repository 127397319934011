import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';

const BadgeText = styled.span`
  height: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  color: ${({theme}) => theme.colors.green};
  background: ${({theme}) => theme.colors.supportiveFeedback};
  padding: 2px 4px;
  border-radius: 4px;
`;

const FreeShippingBadge = () => {
  const {t} = getLocalizationService();
  return (
    <BadgeText>{t('free_delivery')}</BadgeText>
  );
};

export default FreeShippingBadge;
