import {useCallback, useState} from 'react';

import {values} from 'lodash';
import {FORM_ERROR} from 'final-form';
import {Form} from 'react-final-form';
import styled from 'styled-components';

import {createLogger} from '~/shared/logging';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {LinkMoneycardUserIdentifiers} from '~/shared/consts/checkoutConsts';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {ActionButton} from '~/shared/components/Button';
import FormField from '~/shared/components/FormField';
import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import {getLocalizationService} from '~/shared/services/localisationService';
import apiService from '~/shared/services/apiService';
import {SubPageTitle} from '~/shared/components/styled';
import {flexColumn, flexCenterVertically} from '~/shared/theme/FlexLayout';
import {body12Bold, body13Bold} from '~/shared/theme/typography';
import {RippleLoader} from '~/shared/components/Loaders';
import usePollingMoneyCard from '~/shared/hooks/usePollingMoneyCard';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';

const Root = styled.div`
  padding: 10px 20px;
  ${media.minMobile`
    padding: 24px 20px 40px 20px;
  `}
`;

const Note = styled.div`
  line-height: 1.2;
`;

const Bold = styled.div`
  ${body13Bold};
`;

const Instruction = styled(Bold)`
  margin-top: 30px;
`;

const InputFieldContainer = styled.div`
  ${flexColumn};
  ${media.minMobile`
    ${flexCenterVertically};
    flex-direction: row;  
  `}
`;

const Button = styled(ActionButton)`
  height: 50px;
  width: 100%;
  border-radius: 0;
  ${body12Bold};
  box-shadow: none;
  margin: 35px 0 0 0;
  ${media.minMobile`
    margin-top: 100px;
  `}
`;

const Or = styled.div`
  margin-bottom: 5px;
  ${media.minMobile`
    margin: 0px 10px 30px 10px;
  `}
`;

const CenterError = styled.div.attrs({
  role: 'alert',
})`
  color: ${({theme}) => theme.checkout.availablePayments.formError};
  text-align: center;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  ${flipOnLTR`
    right: 32px;
  `};
`;

const validate = ({cellPhone, email}) => {
  if (!cellPhone && !email) return {[FORM_ERROR]: 'must_fill_at_list_one_of_the_fields'};
  return null;
};

const logger = createLogger('LinkMoneycardSection');

const LinkMoneycardSection = ({backToAvailablePaymentSection, onSuccess}) => {
  const {t} = getLocalizationService();
  const [runPollingService, setRunPollingService] = useState(false);

  const submit = useCallback(
    async ({userIdentifier, email, cellPhone}) => {
      try {
        const {data} = await apiService.createLinkMoneycardToUserRequest({
          userIdentifier,
          identifierValue: email || cellPhone,
        });

        const requestId = data.requestToken;
        const requestsIds = JSON.parse(localStorage.getItem(LocalStorageKeys.PENDING_LINK_PAYMENT_REQUEST_IDS)) || [];
        localStorage.setItem(
          LocalStorageKeys.PENDING_LINK_PAYMENT_REQUEST_IDS,
          JSON.stringify([...requestsIds, requestId]),
        );

        setRunPollingService(true);
        onSuccess();
      } catch (error) {
        if (is401Error(error)) {
          await handleRefreshToken(error, submit, {userIdentifier, email, cellPhone});
          return;
        }
        setRunPollingService(false);
        logger.error('error while trying to createLinkMoneycardToUserRequest', error);
        return {
          [FORM_ERROR]: error?.message || error?.statusText || t('unexpected_error'),
        };
      }
    }, [onSuccess, t],
  );

  const onSubmitLinkPayment = useCallback(
    async ({email, cellPhone}) => submit({
      userIdentifier: email ? LinkMoneycardUserIdentifiers.EMAIL : LinkMoneycardUserIdentifiers.PHONE,
      email,
      cellPhone,
    }),
    [submit],
  );

  usePollingMoneyCard(runPollingService);

  return (
    <LayoutWithHeader
      onClick={backToAvailablePaymentSection}
      titleKey="link_money_card_title"
      showBackArrow
      titleComponent={SubPageTitle}
    >
      <Root>
        <Note>
          <Bold>{t('notice')}</Bold>
          <div>
            {t(
              'you_can_give_or_request_billing_permissions_to_do_so_you_need_to_back_and_make_changes_in_billing_permissions_from',
            )}
          </div>
          <div>{t('main_menu_to_tenBis_card_to_billing_permissions')}</div>
        </Note>

        <Form
          onSubmit={onSubmitLinkPayment}
          validate={validate}
          render={({handleSubmit, errors, touched, submitError, submitting}) => (
            <>
              <Instruction>{t('send_billing_request_to')}</Instruction>

              <InputFieldContainer>
                <FormField
                  firstInput
                  name="email"
                  label={t('email_address')}
                  type="email"
                  shouldShowError
                  placeholder={t('email')}
                />

                <Or>{t('or')}</Or>

                <FormField
                  name="cellPhone"
                  label={t('phone_number')}
                  type="phone"
                  shouldShowError
                  placeholder={t('phone_number')}
                />
              </InputFieldContainer>

              {values(touched).some(bool => bool) && errors && errors[FORM_ERROR] && (
                <CenterError>{t(errors[FORM_ERROR])}</CenterError>
              )}

              {submitError && <CenterError>{submitError}</CenterError>}

              <Button onClick={handleSubmit} full disabled={submitting}>
                {t('send_request')}
                {submitting && (
                  <LoaderContainer>
                    <RippleLoader size={28} />
                  </LoaderContainer>
                )}
              </Button>
            </>
          )}
        />
      </Root>
    </LayoutWithHeader>
  );
};

export default LinkMoneycardSection;
