import {useEffect} from 'react';

import {LoginOrRegister} from '~/signin';
import {attachModal} from '~/shared/components/ReduxModal';
import {eventNames, on} from '~/shared/events';

const LoginOrRegisterModal = ({scrollModalToBottom, closeModal, args = {}}) => {
  const {mode, onSuccess} = args;

  useEffect(() => {
    const offLogin = on(eventNames.login, closeModal);
    return () => {
      offLogin();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginOrRegister
      scrollModalToBottom={scrollModalToBottom}
      closeModal={closeModal}
      mode={mode}
      onSuccess={onSuccess}
    />
  );
};

LoginOrRegisterModal.hideCloseButton = true;
export default attachModal('loginOrRegister', LoginOrRegisterModal);
