import {DEFAULT_CULTURE, DEFAULT_LANGUAGE_KEY, EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {AppState} from '~/shared/store/configStore';
import {selectIsMaxLargeMobile, selectShoppingCart} from '~/shared/store/selectors';

import {getLocalizationService} from '../localisationService';

import {ApiServiceConfigParams} from './apiServiceConfig';

export const getShoppingCartGuidParams = (state: AppState) => {
  const shoppingCartGuid = selectShoppingCart(state)?.shoppingCartGuid;
  return shoppingCartGuid ? {shoppingCartGuid} : {shoppingCartGuid: undefined};
};

export const getLocaleParams = (useDefaultLocale: boolean) => {
  if (useDefaultLocale) {
    return {culture: DEFAULT_CULTURE, uiCulture: DEFAULT_LANGUAGE_KEY};
  }
  const {culture: currentCulture, currentLanguageKey} = getLocalizationService();
  return {culture: currentCulture, uiCulture: currentLanguageKey};
};

export const getIsMobileDeviceParams = ({sendIsMobileDevice}: ApiServiceConfigParams, state: AppState) => {
  return sendIsMobileDevice
    ? {
        isMobileDevice: selectIsMaxLargeMobile(state),
      }
    : EMPTY_OBJECT;
};

export const getTimestampParams = ({noGetTimeStampParam, method}: ApiServiceConfigParams) => {
  return method === 'get' && !noGetTimeStampParam
    ? {
        timestamp: new Date().getTime(),
      }
    : EMPTY_OBJECT;
};
