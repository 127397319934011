import {addressKeyFromParts} from '~/shared/utils/address';
import {ApiError} from '~/shared/services/apiErrorService';

import * as sharedActions from '../restrictedSharedActions';
import {makeReducer, makeAsyncReducer, composeReducers, StateAsyncProperty, GenericAction} from '../../redux-toolbelt';
import {User} from '../../models';

import {
  clearUserWithLoaded,
  getUserAddresses,
  insertAddress,
  removeAddress,
  setMoneyCardActivationStatus,
} from './userActions';

export type UserState = StateAsyncProperty<
  User & {
    moneycardActivationRequired: boolean;
  },
  ApiError & {data: User}
>;

const userDefaultState: UserState = {loading: false, data: undefined, loaded: false};

export default composeReducers<UserState>(
  makeReducer<UserState>(
    {
      [clearUserWithLoaded.TYPE]: () => ({...userDefaultState, loaded: true}),
      [getUserAddresses.success.TYPE]: (
        currentState,
        {payload: addresses}: GenericAction<NonNullable<UserState['data']>['addresses']>,
      ) => {
        const newAddresses = addresses?.map(address => ({
          ...address,
          addressKey: addressKeyFromParts(address),
        }));

        return {
          ...currentState,
          loading: false,
          data: currentState.data && {
            ...currentState.data,
            addresses: newAddresses || [],
          },
        };
      },
      [sharedActions.updateAddress.TYPE]: (currentState, {payload}: ReturnType<typeof sharedActions.updateAddress>) => {
        const {address} = payload || {};
        if (!address || !currentState) {
          return currentState;
        }

        return {
          ...currentState,
          loading: false,
          data: currentState.data && {
            ...currentState.data,
            addresses: currentState.data?.addresses.map(oldAddress =>
              (oldAddress.addressId === address.addressId ? address : oldAddress),
            ),
          },
        };
      },
      [insertAddress.success.TYPE]: (currentState, {payload: newAddress}) => {
        if (!newAddress || !currentState?.data) {
          return currentState;
        }

        return {
          ...currentState,
          loading: false,
          data: {
            ...currentState.data,
            addresses: currentState.data ? [...currentState.data.addresses, newAddress] : [newAddress],
          },
        };
      },
      [removeAddress.TYPE]: (currentState, {payload: addressId}) => {
        if (!addressId) {
          return currentState;
        }

        const updatedAddresses = currentState.data?.addresses.filter(address => address.addressId !== addressId);

        return {
          ...currentState,
          loading: false,
          data: currentState.data && {
            ...currentState.data,
            addresses: updatedAddresses || [],
          },
        };
      },
      [setMoneyCardActivationStatus.TYPE]: (
        currentState,
        {payload: moneycardActivationRequired}: ReturnType<typeof setMoneyCardActivationStatus>,
      ) => {
        return {
          ...currentState,
          loading: false,
          data: currentState.data && {
            ...currentState.data,
            moneycardActivationRequired: !!moneycardActivationRequired,
          },
        };
      },
    },
    {defaultState: userDefaultState},
  ),
  makeAsyncReducer<UserState>(sharedActions.login, {shouldDestroyData: false}),
  makeAsyncReducer<UserState>(sharedActions.registerUser, {shouldDestroyData: false}),
);
