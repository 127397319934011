import {memo, useState, useEffect, useCallback, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {trackEvent} from '~/shared/services/analytics';
import {createLogger} from '~/shared/logging';
import apiService from '~/shared/services/apiService';
import {getLocalizationService} from '~/shared/services/localisationService';
import actions from '~/shared/store/actions';
import {selectUserData} from '~/shared/store/selectors';
import {CentralLoading} from '~/shared/components/Loaders';
import {LoginBody, BodyWrapper} from '~/shared/components/loginStyled';
import {FormNamesEnum} from '~/shared/services/analytics/analyticsModels';

import LoginVerificationForm from './LoginVerificationForm';
import PasswordForm from './PasswordForm';
import LoginForm from './LoginForm';

const logger = createLogger('Login');

const SCREEN_TYPES = {
  email: 'EMAIL_STEP',
  password: 'PASSWORD_STEP',
  verification: 'VERIFICATION_STEP',
};

const LOGIN_COMPONENTS = {
  [SCREEN_TYPES.email]: LoginForm,
  [SCREEN_TYPES.verification]: LoginVerificationForm,
  [SCREEN_TYPES.password]: PasswordForm,
};

const getAnalyticNameByScreenType = ({currentLoginScreen, verificationDetails}) => {
  const loginScreenAnalyticsNames = {
    [SCREEN_TYPES.email]: '2FA - Enter Email',
    [SCREEN_TYPES.password]: '2FA - B2B FTO',
    [SCREEN_TYPES.verification]: verificationDetails?.isPhoneMethod ? '2FA - SMS' : '2FA - Email',
  };

  return loginScreenAnalyticsNames[currentLoginScreen];
};

const Login = ({
  backgroundColor,
  onBackButtonClick,
  pageView,
  scrollModalToBottom,
  onSubmit,
  initialLoginEmail,
  isStrongLogin,
  ...props
}) => {
  const [currentLoginScreen, setCurrentLoginScreen] = useState(SCREEN_TYPES.email);
  const [verificationDetails, setVerificationDetails] = useState();
  const [entreyEmail, setEntreyEmail] = useState();
  const [resErrors, setResErrors] = useState();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const isStrongLoginAutoSubmitting = useMemo(() => {
    return isStrongLogin && !!userData?.email && currentLoginScreen === SCREEN_TYPES.email;
  }, [currentLoginScreen, isStrongLogin, userData?.email]);

  const PresentedComponent = LOGIN_COMPONENTS[currentLoginScreen];

  useEffect(() => {
    setResErrors(null);
    if (!isStrongLogin) {
      dispatch(actions.clearUserWithLoaded());
    }
  }, [currentLoginScreen, dispatch, isStrongLogin]);

  const {t} = getLocalizationService();
  const linkType = getAnalyticNameByScreenType({currentLoginScreen, verificationDetails});

  const onSubmitEmail = useCallback(
    async ({email, shouldSendTrackEvent = true}) => {
      setResErrors(null);
      if (!email) {
        logger.error('onSubmitEmail received empty email', email);
        return;
      }

      setEntreyEmail(email);
      if (shouldSendTrackEvent) {
        // preventing re-sending auth code to send login track.
        trackEvent('hasClickedLogin', {linkType});
      }

      try {
        const {data} = await apiService.getUserAuthenticationDataAndSendAuthenticationCodeToUser({email});

        const isPasswordScreen = data.authenticationMethod === 'Password';
        if (isPasswordScreen) {
          setCurrentLoginScreen(SCREEN_TYPES.password);
          return;
        }

        const isPhoneMethod = data.codeAuthenticationData.sendingMethod === 'Phone';
        setVerificationDetails({
          authenticationToken: data.codeAuthenticationData.authenticationToken,
          isPhoneMethod,
          lastFourPhoneDigits: data.codeAuthenticationData.lastFourPhoneDigits,
        });

        setCurrentLoginScreen(SCREEN_TYPES.verification);
      } catch (error) {
        const formErrorType = error?.message || t('something_went_wrong');
        trackEvent('hasFormError', {
          formType: '2FA Login Form',
          formErrorType,
          linkType,
        });
        trackEvent('formErrorMessageView', {
          formName: FormNamesEnum.LOGIN,
          errorType: formErrorType,
        });
        const errorType = 'Error while fetching getUserAuthenticationDataAndSendAuthenticationCodeToUser';
        logger.error(errorType, {email, e: error, fingerprint: errorType});
        setResErrors(formErrorType);
      }
    },
    [linkType, t],
  );

  useEffect(() => {
    if (isStrongLoginAutoSubmitting) {
      onSubmitEmail({email: userData.email, shouldSendTrackEvent: false});
    }
  }, [isStrongLoginAutoSubmitting, onSubmitEmail, userData?.email]);

  const onSubmitVerification = async entries => {
    const {authenticationCode} = entries;
    if (!authenticationCode) {
      logger.error('onSubmitVerification didnt receive an authenticationCode');
      return;
    }

    trackEvent('hasClickedLogin', {linkType});
    setResErrors(null);
    if (!isStrongLogin) {
      dispatch(actions.clearUserWithLoaded());
    }
    onSubmit({email: entreyEmail, authenticationToken: verificationDetails.authenticationToken, ...entries}, linkType);
  };

  const onSubmitLoginWithEmailAndPassword = async ({password, ...rest}) => {
    trackEvent('hasClickedLogin', {linkType});
    onSubmit({email: entreyEmail, password, ...rest}, linkType);
  };

  const onForgotPassword = () => {
    trackEvent('hasClickedForgotPassword', {linkType});
    dispatch(actions.resetPassword({emailAddress: entreyEmail}));
    dispatch(
      actions.setCurrentModal('successModal', {
        title: 'reset_password',
        text: 'an_email_to_complete_setting_new_password_process_was_sent_to_you',
        btnText: 'ok',
      }),
    );
  };

  useEffect(() => {
    trackEvent('hasViewedLoginStep', {linkType});
  }, [linkType]);

  useEffect(() => {
    if (scrollModalToBottom) {
      window.addEventListener('resize', scrollModalToBottom);
    }

    return () => {
      if (scrollModalToBottom) {
        window.removeEventListener('resize', scrollModalToBottom);
      }
    };
  }, [scrollModalToBottom]);

  return (
    <>
      {isStrongLoginAutoSubmitting && !resErrors && <CentralLoading />}
      <BodyWrapper
        id="login_tab_controls"
        aria-labelledby="login_tab_button"
        role="tabpanel"
        pageView={pageView}
        backgroundColor={backgroundColor}
      >
        <LoginBody>
          <PresentedComponent
            {...{
              onBackButtonClick,
              backgroundColor,
              onSubmitEmail,
              verificationDetails,
              onSubmitVerification,
              entreyEmail,
              resErrors,
              setResErrors,
              onForgotPassword,
              onBackClick: () => setCurrentLoginScreen(SCREEN_TYPES.email),
              onSubmitLoginWithEmailAndPassword,
              initialValues: {email: initialLoginEmail},
              t,
              pageView,
              isStrongLogin,
              ...props,
            }}
          />
        </LoginBody>
      </BodyWrapper>
    </>
  );
};

export default memo(Login);
