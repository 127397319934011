import {findIndex, noop} from 'lodash';

import {createLogger} from '~/shared/logging';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {trackEvent} from '~/shared/services/analytics';
import {getLocalizationService} from '~/shared/services/localisationService';
import store from '~/shared/store';
import actions from '~/shared/store/actions';
import {selectUserData, selectShouldOpenModal} from '~/shared/store/selectors';
import {pushRoute} from '~/shared/router';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';

const logger = createLogger('Reorder');

export const fetchReordersIfNeeded = async ({addressId}) => {
  const userData = selectUserData(store.getState());
  if (!addressId || !userData) {
    return;
  }
  try {
    await store.dispatchThunk(actions.fetchOrdersHistoryIfNeeded(addressId));
  } catch (err) {
    await handleRefreshToken(err, fetchReordersIfNeeded, {addressId});
    // eslint-disable-next-line no-console
    console.error('error while trying to fetch and set Orders History', err);
  }
};

export const handleOnReorderClick = async ({orderId, restaurantId, reorderItems, linkType}) => {
  const {t} = getLocalizationService();
  const orderPositionInList = findIndex(reorderItems, {orderId}) + 1;

  try {
    trackEvent('hasClickedReorder', {
      orderHistoryTransactionID: orderId,
      restaurantID: restaurantId,
      linkType: linkType || 'reorder dropdown',
      orderPositionInList,
    });

    await ManagerProvider.reorder(orderId);
    store.dispatch(actions.setIsReorder(true));

    await ManagerProvider.chooseAndSetBestDiscountCouponValueInOrder({includeUserCoupons: false});
  } catch (e) {
    logger.error('ManagerProvider.reorder failed', {e, orderId});
    store.dispatch(
      actions.setCurrentModal('errorModal', {
        title: t('no_option_to_reorder'),
        text: t('items_in_the_order_are_not_available'),
      }),
    );
  }
};

export const handleOnPreviewClick = async ({orderId, restaurantId, orderPositionInList, callback = noop}) => {
  try {
    const state = store.getState();
    const shouldOpenModal = selectShouldOpenModal(state);

    trackEvent('hasClickedViewOrder', {
      orderHistoryTransactionID: orderId,
      restaurantID: restaurantId,
      orderPositionInList,
    });

    if (!shouldOpenModal) {
      pushRoute(`/order-summary/${orderId}`);
      return;
    }
    await store.dispatchThunk(actions.fetchOrderSummaryIfNeeded({orderId}));
    store.dispatch(actions.setCurrentModal('order_summary_modal'));
    callback?.();
  } catch (e) {
    if (is401Error(e)) {
      await handleRefreshToken(e, handleOnPreviewClick, {orderId, restaurantId, orderPositionInList, callback});
      return;
    }
    
    throw e?.message;
  }
};
