import {useEffect} from 'react';

export default function useDocumentEventHandler(
  eventName,
  callback,
) {
  useEffect(() => {
    const keyEventsHandler = event => {
      callback(event);
    };
    document.addEventListener(eventName, keyEventsHandler, false);
    return () => {
      document.removeEventListener(eventName, keyEventsHandler, false);
    };
  }, [callback, eventName]);
}
