import {useState, useEffect, useCallback} from 'react';

import {useSelector} from 'react-redux';

import {refreshPage} from '~/shared/router';
import {selectCurrentModalName} from '~/shared/store/selectors';
import {MINUTE} from '~/shared/consts/commonConsts';

import useOnInactivity from './useOnInactivity';

const REFRESH_ON_MAIN_PAGES_INACTIVITY_MS = 10 * MINUTE;

export default function useRefreshOnInactivity(inactiveMs = REFRESH_ON_MAIN_PAGES_INACTIVITY_MS) {
  const [pendingRefresh, setPendingRefresh] = useState(false);
  const currentModal = useSelector(selectCurrentModalName);

  useEffect(() => {
    if (pendingRefresh && !currentModal) {
      refreshPage();
      setPendingRefresh(false);
    }
  }, [pendingRefresh, currentModal]);

  const onInactivity = useCallback(() => setPendingRefresh(true), []);

  useOnInactivity(inactiveMs, onInactivity);
}
