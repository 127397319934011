import styled from 'styled-components';

import {flexCenter} from '~/shared/theme/FlexLayout';
import Button from '~/shared/components/Button';
import {flipOnLTR} from '~/shared/theme/utils';
import {body12Normal, body13Bold, body14Bold} from '~/shared/theme/typography';
import facbookIcon from '~/assets/images/icons/facebook.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

const LineText = styled.div`
  overflow: hidden;
  text-align: center;
  ${body13Bold};
  margin: 18px 0;
  color: ${({theme}) => theme.colors.secondary};

  &::before,
  &::after {
    background-color: ${({theme}) => theme.colors.secondary};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &::before {
    right: 20px;
    margin-left: -50%;
  }

  &::after {
    left: 20px;
    margin-right: -50%;
  }
`;

const FacebookLogin = styled(Button).attrs(() => ({
  tabIndex: 0,
}))`
  cursor: pointer;
  width: 100%;
  color: ${({theme}) => theme.facebookButton.text};
  font-weight: normal;
  background-color: ${({theme}) => theme.facebookButton.background};
  border: 1px solid ${({theme}) => theme.colors.gray500};
  ${flexCenter};
  min-height: 50px;

  &:hover {
    background-color: ${({theme}) => theme.actionButton.enabled.hoverBg};
  }
`;

const FacebookLabel = styled.p`
  color: ${({theme}) => theme.facebookButton.text};
  ${body14Bold};
  line-height: 20px;
  ${flipOnLTR`
    margin-left: 8px;
  `}
`;

const FacebookIcon = styled(ImageWithAlt)`
  width: 20px;
`;

const NeverDetails = styled.div`
  margin-top: 15px;
  ${body12Normal};
  line-height: 1em;
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
`;

const FacebookSection = ({t, onClick, btnText, btnAriaLabel, backgroundColor}) => {
  const facebookAdditionAriaLabel = t('we_will_never_publish_any_information_without_your_approval');
  return (
    <>
      <LineText backgroundColor={backgroundColor}>{t('or')}</LineText>
      <FacebookLogin onClick={onClick} aria-label={`${btnAriaLabel} ${facebookAdditionAriaLabel}`}>
        <FacebookLabel>{btnText}</FacebookLabel>
        <FacebookIcon src={facbookIcon} alt={t('facebook')} />
      </FacebookLogin>
      <NeverDetails>{facebookAdditionAriaLabel}</NeverDetails>
    </>
  );
};

export default FacebookSection;
