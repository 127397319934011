import {memo, useCallback, useRef, useState} from 'react';

import {useDispatch} from 'react-redux';

import {FastCheckoutSteps} from '~/shared/consts/checkoutConsts';
import AddCreditCard from '~/shared/components/AddCreditCard';
import {useAutoFocus} from '~/shared/hooks';
import {trackEvent} from '~/shared/services/analytics';
import {getLinkMoneycardToUserRequests} from '~/shared/store/actions';

import LinkMoneycardSection from './LinkMoneycardSection';
import AvailablePaymentsSection from './AvailablePaymentsSection';

const modesSections = {
  [FastCheckoutSteps.AVAILABLE_PAYMENTS]: ({onClose, addBillingPermission, addCreditCard}) => (
    <AvailablePaymentsSection
      {...{
        onClose,
        addBillingPermission,
        addCreditCard,
      }}
    />
  ),
  [FastCheckoutSteps.LINK_MONEY_CARD]: ({onLinkSuccess, backToAvailablePaymentSection}) => (
    <LinkMoneycardSection {...{onSuccess: onLinkSuccess, backToAvailablePaymentSection}} />
  ),
  [FastCheckoutSteps.ADD_CREDIT_CARD]: ({backToAvailablePaymentSection, onClose}) => (
    <AddCreditCard {...{onBackLinkClick: backToAvailablePaymentSection, onSuccess: onClose}} />
  ),
};

const AvailablePayments = ({stepMode, onClose}) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(stepMode || FastCheckoutSteps.AVAILABLE_PAYMENTS);

  const addCreditCard = useCallback(() => {
    setCurrentStep(FastCheckoutSteps.ADD_CREDIT_CARD);
    trackEvent('hasClickedAddCreditCard', {linkType: 'Payments Modal'});
  }, []);

  const addBillingPermission = useCallback(() => {
    trackEvent('hasClickedAddPaymentOption');
    setCurrentStep(FastCheckoutSteps.LINK_MONEY_CARD);
  }, []);

  const backToAvailablePaymentSection = useCallback(() => setCurrentStep(FastCheckoutSteps.AVAILABLE_PAYMENTS), []);

  const onLinkSuccess = useCallback(async () => {
    await dispatch(getLinkMoneycardToUserRequests());
    backToAvailablePaymentSection();
  }, [backToAvailablePaymentSection, dispatch]);

  const stepComponent = modesSections[currentStep]({
    onClose,
    backToAvailablePaymentSection,
    onLinkSuccess,
    addBillingPermission,
    addCreditCard,
  });

  const wrapperRef = useRef();
  useAutoFocus({
    domEl: wrapperRef.current,
    triggerCondition: stepMode,
  });

  return <div ref={wrapperRef}>{stepComponent}</div>;
};

export default memo(AvailablePayments);
