import {useSelector} from 'react-redux';
import styled, {keyframes} from 'styled-components';

import ClearSearch from '~/assets/images/svgIconsAsText/clear_search.svg?react';
import Button from '~/shared/components/Button';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {useIsMaxLargeMobile, useIsMinTablet} from '~/shared/hooks/deviceInfo';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectUserData} from '~/shared/store/selectors';
import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';

import ActionMenuLinks from './ActionMenuLinks';

const ClickOutsideWithoutExtraProps = ({isOpen: _ignore, ...rest}) => <ClickOutsideDiv {...rest} />;

const ActionMenuOverlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  right: 0;
  top: 0;
  bottom: -100vh;
  z-index: 1050;
`;

const widthTransition = keyframes`
  from {
    width: 0;
  }

  to {
    width: 300px;
  }
`;

const Root = styled.div`
  ${flipOnLTR`
    left: 0;
  `};
  border-radius: 0;
  ${flexCenterVertically};
  justify-content: flex-start;
  flex-flow: column nowrap;
  background-color: ${({theme}) => theme.colors.surface};
  z-index: 1100;
  animation: ${widthTransition} 0.3s linear 1;
  width: 300px;
  position: fixed;
  height: 100vh;
  box-shadow: ${({theme}) => theme.shadows.shadow2};
  top: 0;
  ${media.minTablet`
    padding: 0;
    animation: none;
    position: absolute;
    height: auto;
    top: 36px;
  `};
`;

const CloseButtonWrapper = styled.div`
  height: 40px;
  padding: 8px;
  width: 100%;
  ${flexCenterVertically};
  justify-content: flex-start;
`;

const CloseSvg = styled(ClearSearch)`
  width: 24px;
  display: flex;
`;

const CloseButton = styled(Button)`
  display: flex;
`;

const ScrollContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 85vh;
  @media only screen and (orientation: landscape) {
    padding-bottom: 30px;
  }

  ${media.minLargeMobile`
    height: 100%;
    @media only screen and (orientation: landscape) {
      padding-bottom: 0px;
    }
  `};
`;

const ActionMenuContent = ({actionMenuLinks, toggleMenuOpen}) => {
  const {t} = getLocalizationService();
  const user = useSelector(selectUserData);
  const isMinTablet = useIsMinTablet();
  const isMaxLargeMobile = useIsMaxLargeMobile();

  const handleOnClick = link => {
    if (link.toggleMenu) {
      toggleMenuOpen();
    }
    link.onClick({user});
  };

  return (
    <>
      {!isMinTablet && <ActionMenuOverlay />}
      <ClickOutsideWithoutExtraProps id="header-action-menu-container" onClickOutside={toggleMenuOpen}>
        <Root>
          {isMaxLargeMobile && (
            <CloseButtonWrapper>
              <CloseButton onClick={toggleMenuOpen}>
                <CloseSvg alt={t('close')} />
              </CloseButton>
            </CloseButtonWrapper>
          )}
          <ScrollContainer>
            <ActionMenuLinks links={actionMenuLinks} onClick={handleOnClick} toggleMenuOpen={toggleMenuOpen} />
          </ScrollContainer>
        </Root>
      </ClickOutsideWithoutExtraProps>
    </>
  );
};

export default ActionMenuContent;
