import {memo, useEffect, useState, useCallback} from 'react';

import {isEmpty} from 'lodash';

import {CentralLoading} from '~/shared/components/Loaders';
import AddCreditCard from '~/shared/components/AddCreditCard';
import apiService from '~/shared/services/apiService';
import {trackEvent} from '~/shared/services/analytics';
import {is401Error, handleRefreshToken} from '~/shared/services/auth';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';

import OtlRuleSettings from './OtlRuleSettings';

const OTL_MODES = {
  ADD_CREDIT_CARD: 'addCreditCard',
  RULES_SETTINGS: 'ruleSettings',
};

const otlStepModes = {
  ruleSettings: OtlRuleSettings,
  addCreditCard: AddCreditCard,
};

const OtlSectionsContainer = ({onClose, onOtlSuccess, orderFailure}) => {
  const [otlMode, setOtlMode] = useState(OTL_MODES.RULES_SETTINGS);
  const [hasVisitedOtlRules, setHasVisitedOtlRules] = useState(false);
  const [otlData, setOtlData] = useState({});
  const [selectedCreditCard, setSelectedCreditCard] = useState(null);

  const onAddCreditCardSuccess = async () => {
    try {
      const {data} = await apiService.getMoneycardOtlRuleManagement({moneycardId: orderFailure.cardId});
      const sortedCards = data?.userCreditcardList?.sort((a, b) => b.creditcardId - a.creditcardId);
      setSelectedCreditCard(sortedCards && sortedCards[0]?.creditcardId);
      setHasVisitedOtlRules(true);
      setOtlMode(OTL_MODES.RULES_SETTINGS);
      setOtlData(data);
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, onAddCreditCardSuccess);
      }
    }
  };

  const addCreditCard = useCallback(() => {
    setOtlMode(OTL_MODES.ADD_CREDIT_CARD);
    trackEvent('hasClickedAddCreditCard', {linkType: 'Payments Modal'});
  }, [setOtlMode]);

  const onBackLinkClick = useCallback(() => {
    switch (otlMode) {
      case OTL_MODES.ADD_CREDIT_CARD:
        if (hasVisitedOtlRules) {
          setOtlMode(OTL_MODES.RULES_SETTINGS);
        } else {
          if (onClose) {
            onClose();
          }
        }
        break;
      case OTL_MODES.RULES_SETTINGS:
        if (onClose) {
          onClose();
        }
        break;
      default:
        break;
    }
  }, [otlMode, hasVisitedOtlRules, onClose]);

  const ModeComponent = otlStepModes[otlMode];
  const onSuccess = otlMode === OTL_MODES.RULES_SETTINGS ? onOtlSuccess : onAddCreditCardSuccess;

  useEffect(() => {
    (async () => {
      const getMoneycardOtlRuleManagementData = async () => {
        try {
          const {data} = await apiService.getMoneycardOtlRuleManagement({moneycardId: orderFailure.cardId});
          return data;
        } catch (error) {
          if (is401Error(error)) {
            return handleRefreshToken(error, getMoneycardOtlRuleManagementData);
          }

          return null;
        }
      };

      const moneycardOtlRuleManagementData = await getMoneycardOtlRuleManagementData();

      if (moneycardOtlRuleManagementData) {
        let mode = OTL_MODES.ADD_CREDIT_CARD;
        if (!isEmpty(moneycardOtlRuleManagementData.userCreditcardList)) {
          mode = OTL_MODES.RULES_SETTINGS;
          setHasVisitedOtlRules(true);
        }
        setOtlMode(mode);
        setOtlData(moneycardOtlRuleManagementData);

        const {otlRuleData, userCreditcardList} = moneycardOtlRuleManagementData;
        const sortedCards = userCreditcardList?.sort((a, b) => b.creditcardId - a.creditcardId);
        const creditCard =
          otlRuleData?.isActive && otlRuleData?.creditcardId
            ? otlRuleData?.creditcardId
            : sortedCards && sortedCards[0]?.creditcardId;

        setSelectedCreditCard(creditCard);
        return;
      }

      navigateToDefaultStartPage();
    })();
  }, [orderFailure.cardId]);

  if (isEmpty(otlData)) {
    return <CentralLoading />;
  }
  return (
    <ModeComponent
      otlData={otlData}
      addCreditCard={addCreditCard}
      selectedCreditCard={selectedCreditCard}
      setSelectedCreditCard={setSelectedCreditCard}
      onClose={onClose}
      onSuccess={onSuccess}
      onBackLinkClick={onBackLinkClick}
    />
  );
};
export default memo(OtlSectionsContainer);
