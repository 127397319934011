import {Fragment, useEffect} from 'react';

import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';

import {createLogger} from '~/shared/logging';
import {selectUserData, selectOrderError} from '~/shared/store/selectors';
import {pushRoute, getCurrentLocation} from '~/shared/router';
import {routeNames} from '~/shared/routes';
import actions from '~/shared/store/actions';
import {flexColumn} from '~/shared/theme/FlexLayout';

import OtlSectionsContainer from './OtlSectionsContainer';

const ModalRoot = styled.div`
  background-color: ${({theme}) => theme.loginModal.backgroundColor};
  border-radius: 2px;
  overflow: hidden;
  ${flexColumn}
`;

const logger = createLogger('OtlPageComponent');

const pushRouteToCheckout = () => {
  pushRoute('/checkout');
};

const handleOnClose = (dispatch, isModal, closeModal, returnToModal) => () => {
  if (!isModal) {
    pushRouteToCheckout();
  } else {
    const {routeName} = getCurrentLocation();
    if (routeName === routeNames.checkout) {
      closeModal();
    } else {
      if (returnToModal) {
        dispatch(actions.setCurrentModal(returnToModal));
      }
    }
  }
};

const OtlPageComponent = ({closeModal, args}) => {
  const orderError = useSelector(selectOrderError);

  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  const isModal = !!closeModal;

  useEffect(() => {
    if (!userData) {
      if (isModal) {
        closeModal();
        logger.error('Modal closed, no userData');
      } else {
        pushRoute(`/signin?returnUrl=${encodeURIComponent('/otl')}`);
        logger.warn('Redirecting to login and onSuccess should redirect back to the otl page');
      }
    }
  }, [userData, closeModal, isModal]);

  if (!userData) {
    return null;
  }

  const RootElement = closeModal ? ModalRoot : Fragment;

  return (
    <RootElement>
      <OtlSectionsContainer
        orderFailure={{cardId: orderError?.data.submitOrderFailurePaymentId}}
        onClose={handleOnClose(dispatch, isModal, closeModal, args?.returnToModal)}
        onOtlSuccess={handleOnClose(dispatch, isModal, closeModal, args?.returnToModal)}
      />
    </RootElement>
  );
};

export default OtlPageComponent;
