import {
  Context,
  memo,
  MemoExoticComponent,
  Provider,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styled from 'styled-components';
import {isEmpty} from 'lodash';

import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';
import {SkeletonLoader} from '~/shared/components/Loaders';
import {getLocalizationService} from '~/shared/services/localisationService';
import {getMenuOrDishPagePath} from '~/shared/services/urlService';
import {screenReaderNotificatorCss} from '~/shared/components/styled';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import ShoppingCartHeader from '~/shared/components/ShoppingCart/components/ShoppingCartHeader';
import {pushRoute} from '~/shared/router';

import AgeRestrictionDisclaimer from '../../AgeRestrictionDisclaimer';
import {IShoppingCartContext, ShoppingCartContext} from '../context';

import DishList from './DishList';
import ProceedToPaymentButton from './ProceedToPaymentButton';
import Billings from './Billings';
import EmptyShoppingCart from './EmptyShoppingCart';
import OpenCheckoutButton, {OPEN_CHECKOUT_BUTTON_HEIGHT} from './OpenCheckoutButton';
import RestaurantHeader from './RestaurantHeader';
import ShoppingCartSkeleton from './ShoppingCartSkeleton';

const ScreenReaderNotificator = styled.h2`
  ${screenReaderNotificatorCss}
`;

const Root = styled.div`
  display: block;
  position: sticky;
  align-self: flex-start;
  z-index: 10;
  width: 100%;
`;

const Container = styled.div<{disableZIndex?: boolean}>`
  position: relative;
  ${flexColumn};
  bottom: 0;
  width: 100%;
  ${media.minLargeTablet`
    max-height: ${({theme}) =>
    `calc(100vh - ${theme.subHeader.height + theme.menu.categories.height.desktop + 60}px)`};
  `}
  ${({disableZIndex}) => !disableZIndex && 'z-index: 3;'}
  user-select: none;
  border-radius: 2px;
  color: ${({theme}) => theme.shoppingCart.textColor};
`;

const Content = styled.div<{isShoppingCartPage?: boolean}>`
  background-color: ${({theme}) => theme.colors.surface};
  ${({isShoppingCartPage}) => isShoppingCartPage && `margin-bottom: ${OPEN_CHECKOUT_BUTTON_HEIGHT}px;`}
`;

const ShadowLine = styled.div<{isContrastActive: boolean}>`
  box-shadow: ${({theme}) => theme.shadows.shadow2};
  ${({isContrastActive, theme}) => isContrastActive && `border:1px solid ${theme.colors.gray900}`};
  height: 1px;
`;

const ModifiedDishList = styled(DishList)<{isCheckout?: boolean}>`
  ${({isCheckout}) => isCheckout && media.minLargeMobile`
    padding-left: 32px;
    padding-right: 32px;
  `}
`;

const PageContentWrapper = styled.div`
  height: 100%;
`;

export type ShoppingCartProps = Partial<{
  showProceedButton: boolean;
  shouldOpenCheckout: boolean;
  showAsMobileButton: boolean;
  showRestaurantHeader: boolean;
  showTotalOrder: boolean;
  showContainerBoxShadow: boolean;
  className: string;
  showTitle: boolean;
  loaderComponent: React.FC;
  isCheckoutComponent: boolean;
  isShoppingCartPage?: boolean;
}>;

const ShoppingCart = ({
  showProceedButton,
  shouldOpenCheckout,
  showRestaurantHeader,
  showAsMobileButton,
  showTitle = false,
  loaderComponent,
  className,
  showTotalOrder,
  isCheckoutComponent: parentIsCheckoutComponent = false,
  isShoppingCartPage,
}: ShoppingCartProps) => {
  const {t} = getLocalizationService();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isFutureOrderTimesNotSelected, setIsFutureOrderTimesNotSelected] = useState(false);

  const {
    currentRestaurant,
    dishes,
    userData,
    billingLines,
    deliveryMethod,
    currentCoupon,
    isDeliveringToCurrentAddress,
    shouldOpenModal,
    isContrastActive,
    futureOrderAvailableDatesAndTimes,
    isFutureOrderEnabled,
    dishesWithSubs,
    isShoppingCartHasAgeRestrictionDishOrSub,
    isAgeConfirmed,
    setCurrentModal,
    setActionMadeFrom,
    onCheckoutOpen,
    removeQueries,
    permits,
    isReorder,
    hideAsapPooledToggler,
    shoppingCartDeliveryType,
    isMinLargeMobile,
    openOrderTypeMenuWithError,
    isInitialOrder,
    showSkeletonLoader,
  } = useContext(ShoppingCartContext);

  const [showFutureOrderValidationError, setShowFutureOrderValidationError] = useState(false);

  const showFutureOrder =
    // temporary until we implement OrderType
    !currentRestaurant?.poolOrder &&
    deliveryMethod === DeliveryMethods.DELIVERY &&
    // If the current ShoppingCart instance is a part of the Checkout component,
    // should not present FutureOrders
    !parentIsCheckoutComponent &&
    isFutureOrderEnabled &&
    Boolean(futureOrderAvailableDatesAndTimes.length);

  const currentRestaurantUrl = useMemo(
    () =>
      currentRestaurant &&
      getMenuOrDishPagePath({
        restaurantId: currentRestaurant.id,
        restaurantName: currentRestaurant.name,
      }),
    [currentRestaurant],
  );

  const isDishesEmpty = useMemo(() => isEmpty(dishes), [dishes]);
  const shouldShowAgeConfirm = useMemo(() => !isAgeConfirmed && isShoppingCartHasAgeRestrictionDishOrSub, [isAgeConfirmed, isShoppingCartHasAgeRestrictionDishOrSub]);

  useEffect(() => {
    if (!isFutureOrderTimesNotSelected) {
      setShowFutureOrderValidationError(false);
    }
  }, [isFutureOrderTimesNotSelected]);

  const onPayment = useCallback(() => {
    if (
      isInitialOrder &&
      deliveryMethod !== DeliveryMethods.PICKUP &&
      !isMinLargeMobile
    ) {
      if (isShoppingCartPage && currentRestaurantUrl) {
        // onPayment can be invoked from shopping cart page (serving mw only).
        pushRoute(currentRestaurantUrl);
      }
      // supporting order type menu future / sdfo error handling right before checkout proccess.
      openOrderTypeMenuWithError();
      return;
    }

    if (isMinLargeMobile && isFutureOrderTimesNotSelected && shoppingCartDeliveryType === SHOPPING_CART_DELIVERY_TYPE.FUTURE) {
      setShowFutureOrderValidationError(true);
      return;
    }

    onCheckoutOpen({
      setCurrentModal,
      billingLines,
      userData,
      restaurant: currentRestaurant,
      deliveryMethod,
      currentCoupon,
      shouldOpenModal,
      dishes,
      dishesWithSubs,
      isDeliveringToCurrentAddress: !!isDeliveringToCurrentAddress,
      shouldShowAgeConfirm,
      setActionMadeFrom,
      permits,
      isReorder,
      isShoppingCartPage,
    });
  }, [
    isInitialOrder,
    shoppingCartDeliveryType,
    isMinLargeMobile,
    isFutureOrderTimesNotSelected,
    onCheckoutOpen,
    setCurrentModal,
    billingLines,
    userData,
    currentRestaurant,
    deliveryMethod,
    currentCoupon,
    shouldOpenModal,
    dishes,
    dishesWithSubs,
    isDeliveringToCurrentAddress,
    shouldShowAgeConfirm,
    setActionMadeFrom,
    permits,
    isReorder,
    isShoppingCartPage,
    currentRestaurantUrl,
    openOrderTypeMenuWithError,
  ]);

  useEffect(() => {
    if (shouldOpenCheckout) {
      onPayment();
      removeQueries(['openCheckout']);
    }
  }, [shouldOpenCheckout, onPayment, removeQueries]);

  useEffect(() => {
    if (currentRestaurant?.deliveryRules?.length === 0 || deliveryMethod === DeliveryMethods.PICKUP) {
      setIsDisabled(false);
    }
  }, [currentRestaurant, deliveryMethod]);

  const showBillingLines = showTotalOrder && !isDishesEmpty;
  const showProceedButtonOnPayments = showProceedButton && !isDishesEmpty;

  if (showAsMobileButton) {
    if (isDishesEmpty) {
      return null;
    }
    return <OpenCheckoutButton onPayment={onPayment} />;
  }

  return (
    <Root className={className}>
      <ScreenReaderNotificator>{t('order_summary_and_checkout')}</ScreenReaderNotificator>
      <Container disableZIndex={isShoppingCartPage}>
        <SkeletonLoader shouldShowLoader={showSkeletonLoader} LoaderComponent={loaderComponent || ShoppingCartSkeleton}>
          {showRestaurantHeader && (
            <RestaurantHeader restaurantUrl={currentRestaurantUrl} restaurantName={currentRestaurant?.name}/>
          )}
          <Content id="shopping-cart-content">
            {!hideAsapPooledToggler && <ShoppingCartHeader showFutureOrderValidationError={showFutureOrderValidationError} setIsFutureOrderTimesNotSelected={setIsFutureOrderTimesNotSelected} businessType={currentRestaurant?.businessType}/>}
            {isDishesEmpty && showProceedButton && <EmptyShoppingCart />}
            {showProceedButtonOnPayments && (
              <>
                <ProceedToPaymentButton disabled={isDisabled} onPayment={onPayment} />
                <ShadowLine isContrastActive={isContrastActive} />
              </>
            )}
            {isShoppingCartHasAgeRestrictionDishOrSub && <AgeRestrictionDisclaimer />}
            {!isDishesEmpty && (
              <ModifiedDishList
                showTitle={showTitle}
                isFutureOrderEnabled={showFutureOrder}
                isAgeRestricted={isShoppingCartHasAgeRestrictionDishOrSub}
                numberOfBillingLines={billingLines.length}
                isCheckout={parentIsCheckoutComponent}
              />
            )}

            {showBillingLines && <Billings />}

            {isShoppingCartPage && (
              <PageContentWrapper>
                <Billings />
                <OpenCheckoutButton onPayment={onPayment} />
              </PageContentWrapper>
            )}
          </Content>
        </SkeletonLoader>
      </Container>
    </Root>
  );
};

const PureShoppingCart = memo(ShoppingCart) as MemoExoticComponent<(props: ShoppingCartProps) => JSX.Element | null> & {
  Provider: Provider<IShoppingCartContext>;
  Context: Context<IShoppingCartContext>;
};

PureShoppingCart.Provider = ShoppingCartContext.Provider;
PureShoppingCart.Context = ShoppingCartContext;

export default PureShoppingCart;
