import {baseTheme} from '~/shared/theme';
import {getPooledGroupTimesInfo} from '~/shared/utils/pooledGroupTimesInfo';
import {RestaurantFromSearch, RestaurantList} from '~/shared/store/models';
import {Carousel} from '~/shared/store/models/Carousel';
import RestaurantSortOptions from '~/shared/store/selectors/selectorUtils/restaurantSortOptions';
import {RestaurantsFiltersSortOptionsTypes} from '~/shared/consts/restaurantConsts';

import {selectRestaurantsMainListData} from '..';
import store from '../..';

type CollectionItem = {
  restaurantItem: RestaurantFromSearch;
  key: string;
  groupId: string;
  itemIndexInGroup: number;
};

const makeCarouselGroups = (
  collectionsList: Carousel[] | undefined,
  filteredRes: RestaurantList | Record<string, never>,
  currentSort: RestaurantsFiltersSortOptionsTypes,
  isMinDesktop: boolean,
) => {
  if (!collectionsList) {
    return [];
  }

  const sorter = RestaurantSortOptions.find(({id}) => id === currentSort);

  return collectionsList.map((collection, carouselIndex) => {
    const restaurantsByCollectionOrder = collection.resIds.reduce<CollectionItem[]>((collectionItems, resId) => {
      const restaurant = filteredRes.restaurantsList.find(({restaurantId}) => restaurantId === resId);

      if (!restaurant) {
        return collectionItems;
      }

      return [...collectionItems, {
        restaurantItem: {...restaurant, orderTypeViewTags: collection.orderTypeViewTags},
        key: `${collection.id}_${restaurant.restaurantId}`,
        groupId: collection.name.toLowerCase(),
        itemIndexInGroup: collectionItems.length,
      }];
    }, []);

    if (sorter) {
      restaurantsByCollectionOrder.sort((a, b) => sorter.compare(a.restaurantItem, b.restaurantItem));
    }

    return {
      carouselIndex,
      id: collection.id,
      title: collection.title,
      orderTypeViewTags: collection.orderTypeViewTags,
      subtitle: collection.miniFeedDesc,
      iconUrl: collection.iconUrl,
      groupId: collection.name.toLowerCase(),
      isCarousel: true,
      key: `${collection.id}_carousel`,
      height: isMinDesktop ? baseTheme.carouselHeight.desktop : baseTheme.carouselHeight.mobile,
      items: restaurantsByCollectionOrder,
    };
  });
};

export default makeCarouselGroups;

// why its here :|
// TODO move it to somewhere more relevant.
export const getPooledRestaurantsTimeData = () => {
  const resListData = selectRestaurantsMainListData(store.getState());
  if (!Object.keys(resListData)?.length) {
    return null;
  }

  const {poolTimeStart, poolTimeEnd, poolTimeDelivery} = resListData;
  return getPooledGroupTimesInfo(poolTimeStart, poolTimeEnd, poolTimeDelivery);
};
