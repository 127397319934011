import {memo, useState} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import {validatePhoneNumber} from '~/shared/utils/general';
import {flipOnLTR} from '~/shared/theme/utils';
import {body13Normal} from '~/shared/theme/typography';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {getLocalizationService} from '~/shared/services/localisationService';
import {createLogger} from '~/shared/logging';
import actions from '~/shared/store/actions';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';

import {Section} from './parts';
import {defaultPaddingCss} from './orderSummaryStyle';

const logger = createLogger('ShareOrder');

const Button = styled.button`
  z-index: 1;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: no-drop;
  }

  &:active {
    opacity: 0.5;
  }
`;

const ShareBtn = styled(Button)`
  height: 40px;
  width: 70%;
  ${flexCenter};
  background-color: ${({theme, disabled}) => disabled || theme.orderSuccess.shareOrder.button.active.backgroundColor};
  ${body13Normal};
  color: ${({theme}) => theme.colors.secondaryText};
  z-index: 1;
  transition: opacity 0.25s;
  overflow: hidden;

  &:hover {
    background-color: ${({theme, disabled}) => (disabled ? 'inherit' : theme.actionButton.enabled.hoverBg)};
  }

  &:disabled {
    color: ${({theme}) => theme.orderSuccess.shareOrder.button.disabled.textColor};
    background-color: ${({theme}) => theme.orderSuccess.shareOrder.button.disabled.backgroundColor};
    cursor: no-drop;
  }
`;

const PhoneNumber = styled.input`
  line-height: 1.45;
  vertical-align: middle;
  position: relative;
  width: 100%;
  height: 40px;
  color: ${({theme, isValidationDisplayed}) =>
    (!isValidationDisplayed ? theme.orderSuccess.shareOrder.header.textColor : theme.colors.error)};
  background-color: ${({theme}) => theme.orderSuccess.shareOrder.phone.backgroundColor};
  border: solid 1px;
  padding: 5px 10px;
  border-color: ${({theme, isValidationDisplayed}) =>
    (!isValidationDisplayed ? theme.orderSuccess.shareOrder.phone.borderColor : theme.colors.error)};
  ${body13Normal};
  ${flipOnLTR`
    text-align: right;
    margin-left: 26px;
  `};
  border-radius: 2px;

  &::placeholder {
    color: ${({theme}) => theme.colors.gray300};
  }
`;

const ShareOrderDiv = styled(Section)`
  color: ${({theme}) => theme.orderSuccess.shareOrder.header.textColor};
  ${defaultPaddingCss};
`;

const ShareOrderHead = styled.div`
  color: ${({theme}) => theme.orderSuccess.shareOrder.header.textColor};
`;

const ShareOrderContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 13px;
`;

const ErrorItem = styled.div`
  display: block;
  background-color: ${({theme}) => theme.colors.surface};
  color: ${({theme}) => theme.colors.error};
  margin: 5px 0;
`;

const SuccessItem = styled.div`
  display: block;
  color: ${({theme}) => theme.orderSuccess.shareOrder.success.textColor};
  margin: 5px 0;
`;

const ShareOrderSection = ({shareOrderAction, onChange, phone, isValidationDisplayed}) => {
  const {t} = getLocalizationService();

  return (
    <ShareOrderContainer>
      <PhoneNumber
        placeholder={t('cell_phone')}
        onChange={onChange}
        value={phone}
        isValidationDisplayed={isValidationDisplayed}
      />
      <ShareBtn onClick={shareOrderAction} disabled={!phone || phone.length === 0}>
        <div>{t('share_order')}</div>
      </ShareBtn>
    </ShareOrderContainer>
  );
};
const ErrorSection = ({phone, apiError, isValidationDisplayed}) => {
  const {t} = getLocalizationService();
  return (
    <div>
      {isValidationDisplayed && (
        <ErrorItem>{validatePhoneNumber(phone, t, 'please_enter_a_valid_phone_number')}</ErrorItem>
      )}
      <ErrorItem>{apiError}</ErrorItem>
    </div>
  );
};

const ShareOrder = ({orderId, className}) => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();

  const [phone, setPhone] = useState('');
  const [apiError, setApiError] = useState(null);
  const [isSucceeded, setIsSucceeded] = useState(false);
  const [isValidationDisplayed, setIsValidationDisplayed] = useState(false);

  const sendShareOrderLink = props => dispatch(actions.sendShareOrderLink(props));

  const shareOrderAction = async () => {
    try {
      setApiError(null);
      setIsSucceeded(false);
      setIsValidationDisplayed(true);
      if (!validatePhoneNumber(phone, t, 'please_enter_a_valid_phone_number')) {
        await sendShareOrderLink({orderId, cellphone: phone});
        setIsValidationDisplayed(false);
        setIsSucceeded(true);
        setPhone('');
      }
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, shareOrderAction);
        return;
      }
      logger.error('share order - sendOrderAction', {error});
      setApiError(error);
    }
  };

  const onChange = e => {
    setPhone(e.target.value);
    setApiError(null);
    setIsValidationDisplayed(false);
    setIsSucceeded(false);
  };
  return (
    <ShareOrderDiv className={className}>
      <ShareOrderHead>{t('you_can_invite_friends_to_join_your_order')}</ShareOrderHead>

      <ShareOrderSection
        onChange={onChange}
        phone={phone}
        shareOrderAction={shareOrderAction}
        isValidationDisplayed={isValidationDisplayed}
      />

      <ErrorSection phone={phone} apiError={apiError} isValidationDisplayed={isValidationDisplayed} />

      {isSucceeded && <SuccessItem>{t('share_order_success')}</SuccessItem>}
    </ShareOrderDiv>
  );
};

export default memo(ShareOrder);
