import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {AppState} from '~/shared/store/configStore';
import {selectUserDevice} from '~/shared/store/selectors';

enum AppTypes {
  WEB = 'web',
  MOBILE_WEB = 'mobileWeb',
}

export const getAppType = (state: AppState) => {
  const {isMinLargeTablet} = selectUserDevice(state) || EMPTY_OBJECT;
  return {'x-app-type': isMinLargeTablet ? AppTypes.WEB : AppTypes.MOBILE_WEB};
};
