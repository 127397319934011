import {useRef, useEffect} from 'react';

import moment from 'moment-timezone';

export default function useOnInactivity(inactiveMs, cb) {
  const leaveTabTime = useRef();

  useEffect(() => {
    const onVisibilityChange = () => {
      const landedOnTab = document.visibilityState === 'visible';
      const leftTab = !landedOnTab;
      if (leftTab) {
        leaveTabTime.current = moment();
      } else if (landedOnTab) {
        const shouldCb = leaveTabTime.current && moment().diff(leaveTabTime.current, 'milliseconds') > inactiveMs;
        if (shouldCb) {
          cb();
        }
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [inactiveMs, cb]);
}
