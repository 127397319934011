export enum PaymentMethodTypes {
  CREDIT = 'Credit',
  CASH = 'Cash',
  MONEY_CARD = 'Moneycard',
  CREDIT_CARD = 'Creditcard',
  PAYPAL = 'PayPal',
  UNKNOWN = 'unknown',
}

export type PaymentMethod = EnumValueType<typeof PaymentMethodTypes>;
