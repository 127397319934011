import {api} from '~/shared/services/bffService';
import store from '~/shared/store';
import {selectCurrentAddress} from '~/shared/store/selectors';

import {makeThunkAsyncActionCreator, makeActionCreator} from '../../redux-toolbelt';

import {MarketingBannerState} from './marketingBannersReducer';

export const getMarketingBanners = makeThunkAsyncActionCreator<never, MarketingBannerState['data']>(
  'getMarketingBanners',
  async () => {
    const state = store.getState();
    const currentAddress = selectCurrentAddress(state);
    try {
      const result = await api.fetchMarketingBanners({
        longitude: currentAddress?.longitude,
        latitude: currentAddress?.latitude,
        cityId: currentAddress?.cityId,
      });

      if (result?.marketingBanners) {
        return result?.marketingBanners;
      }
    } catch (error: any) {
      if (error?.message) {
        throw new Error(error.message);
      }
      throw new Error('failed to run getMarketingBanners');
    }
  },
);

export const clearMarketingBanners = makeActionCreator<'clearMarketingBanners', never>('clearMarketingBanners');
