import {createLogger} from '~/shared/logging';

const logger = createLogger('Mutex');

class Mutex {

  static CALLBACK_SEPARATION = 1000;

  static acquire({key, name, errOnQueue}, callback) {
    if (!key) {
      throw new Error('Mutex: must supply mutex key parameter');
    }

    if (!Mutex._queue) {
      Mutex._queue = {};
      Mutex._pending = {};
    }

    if (!Mutex._queue[key]) {
      Mutex._queue[key] = [];
      Mutex._pending[key] = false;
    }

    Mutex._queue[key].push({callback, name});
    if (process.env.NODE_ENV !== 'production') {
      if (errOnQueue && Mutex._queue[key].length > 1) {
        logger.warn([
          'Mutex has more than one of the following events in queue. Take a look at:',
          'https://tenbis.visualstudio.com/TenBis/_wiki/wikis/TenBis.wiki/172/Mutex-a-temporary-solution-to-a-tealium-endless-loop-error',
        ].join('\n'), {key, queue: [...Mutex._queue[key]]});
      }
    }

    if (!Mutex._pending[key]) {
      Mutex._dispatchNext(key);
    }
  }

  static _dispatchNext(key) {
    if (Mutex._queue[key].length > 0 && !Mutex._pending[key]) {
      Mutex._pending[key] = true;
      const nextResolve = Mutex._queue[key].shift();
      setTimeout(() => {
        nextResolve.callback();
        Mutex._pending[key] = false;
        Mutex._dispatchNext(key);
      }, Mutex.CALLBACK_SEPARATION);
    }
  }
}

export default Mutex;
