import {RestaurantBusinessTypeName, RestaurantFromSearch} from '~/shared/store/models';
import {CollectionOrderTypeViewTags} from '~/shared/store/models/Carousel';

export enum TagsTypes {
  RATING = 'rating',
  DELIVERY_TIME = 'delivery_time',
  DELIVERY_FEE = 'delivery_fee',
  DISTANCE = 'distance',
  MINIMUM_ORDER = 'minimum_order',
  KOSHER = 'kosher',
  IS_NEW = 'new',
  ENVIRONMENT_FRIENDLY = 'environment_friendly',
  POOLED_ORDER = 'pooled_order',
  ADDRESS = 'address',
}

export type TagType = EnumValueType<typeof TagsTypes>;

export type RestaurantPropsType = RestaurantFromSearch & {
  deliveryMethod?: string;
  promotedTag?: boolean;
  distanceNumeric?: number;
};

export type RestaurantItemTagsProps = {
  isPooledOrderRestaurant: RestaurantFromSearch['isPooledOrderRestaurant'];
  restaurantAddress: RestaurantFromSearch['restaurantAddress'];
  numOfReviews: RestaurantFromSearch['numOfReviews'];
  estimatedNumberOfOrdersInPoolOrder: RestaurantFromSearch['estimatedNumberOfOrdersInPoolOrder'];
  reviewsRankDecimal: RestaurantFromSearch['reviewsRankDecimal'];
  distance: string;
  distanceNumeric: RestaurantPropsType['distanceNumeric'];
  deliveryMethod: RestaurantPropsType['deliveryMethod'];
  deliveryFee: RestaurantFromSearch['deliveryFee'];
  deliveryTimeInMinutes: RestaurantFromSearch['deliveryTimeInMinutes'];
  minimumPriceForOrder: RestaurantFromSearch['minimumPriceForOrder'];
  isKosher: RestaurantFromSearch['isKosher'];
  tags: RestaurantFromSearch['tags'];
  promotedTag: RestaurantPropsType['promotedTag'];
  isEnvironmentFriendly: RestaurantFromSearch['isEnvironmentFriendly'];
  isClosedWithFutureOrder: boolean;
  deliveryPriceBeforeCompanyDiscount: RestaurantFromSearch['deliveryPriceBeforeCompanyDiscount'];
  pooledOrderSum: RestaurantFromSearch['pooledOrderSum'];
  groupId: string;
  minimumForPooledOrder: number;
  isOnlyAsap: boolean;
  isOnlyPooled: boolean;
  isOpenNow: boolean;
  isSDFO: boolean;
  orderTypeViewTags: CollectionOrderTypeViewTags;
  isFutureOrderAvailable?: boolean;
  isVoucherRestaurant?: boolean;
  isAsapAvailable?: boolean;
  hasActivePoolRule: boolean;
  isScoober: boolean;
  businessTypeName: RestaurantBusinessTypeName;
};

export type RenderingType = Partial<
  Record<
    TagType,
    {
      renderPlaceholder: boolean | ((tagProps: RestaurantItemTagsProps) => boolean);
      condition: (tagProps: RestaurantItemTagsProps) => boolean;
    }
  >
>;

export type OrderType =
  | TagType[]
  | ((tagProps: RestaurantItemTagsProps, isMinOrderBeforeDeliveryFee: boolean) => TagType[]);

export type RowsType = TagsRowsDefinition | ((tagsProps: RestaurantItemTagsProps, tags: TagsArray) => Result);

type TagsRowsDefinition = {
  firstRow: {start: number; end: number};
  secondRow: {start: number; end: number};
};

type Result = {firstRowTags?: TagType[]; secondRowTags?: TagType[]};

export type TagsConfiguration = {
  rendering: RenderingType;
  order: OrderType;
  rows: RowsType;
  isMinOrderBeforeDeliveryFee: boolean;
};

export type TagsArray = {tag: TagType; isPlaceholder: boolean}[];

const shouldRenderPlaceholder = (
  renderPlaceholder: boolean | ((tagProps: RestaurantItemTagsProps) => boolean),
  tagsProps: RestaurantItemTagsProps,
) => (typeof renderPlaceholder === 'function' ? renderPlaceholder(tagsProps) : renderPlaceholder);

// returns a list of tag elements sorted by the order of items in order array
export const getTagsComponentsToDisplay = (
  tagsProps: RestaurantItemTagsProps,
  rendering: RenderingType,
  order: TagType[],
): TagsArray => {
  const result = order
    .map(tag => {
      // need to verify that the tag element should be returned
      if (rendering[tag]?.condition(tagsProps)) {
        return {
          tag,
          isPlaceholder: false,
        };
      }

      if (shouldRenderPlaceholder(rendering[tag]?.renderPlaceholder || false, tagsProps)) {
        return {
          tag: '',
          isPlaceholder: true,
        };
      }

      return undefined;
    })
    .filter(item => item !== undefined) as TagsArray;

  return result;
};

export const formatTags = (tags: TagsArray): TagType[] => tags.map(({tag}) => tag);

const getTagsRows = (tags: TagsArray, rowsDefinition: TagsRowsDefinition): Result => {
  const firstRowTags = tags.slice(rowsDefinition.firstRow.start, rowsDefinition.firstRow.end);
  const secondRowTags = tags.slice(rowsDefinition.secondRow.start, rowsDefinition.secondRow.end);

  return {firstRowTags: formatTags(firstRowTags), secondRowTags: formatTags(secondRowTags)};
};

export const getTagsConfiguration = (
  tagsProps: RestaurantItemTagsProps,
  {rendering, order, rows, isMinOrderBeforeDeliveryFee}: TagsConfiguration,
) => {
  const tagsOrder = typeof order === 'function' ? order(tagsProps, isMinOrderBeforeDeliveryFee) : order;
  const tagsToDisplay = getTagsComponentsToDisplay(tagsProps, rendering, tagsOrder);
  if (typeof rows === 'function') {
    return rows(tagsProps, tagsToDisplay);
  }
  return getTagsRows(tagsToDisplay, rows);
};
