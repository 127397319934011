import {useEffect} from 'react';

import {debounce} from 'lodash';

import {shoppingCartGuidExpirationMinutes} from '~/shared/config';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {getParsedSiteCookies} from '~/shared/utils/cookies';
import actions, {restrictedSharedActions} from '~/shared/store/actions';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {selectCurrentModal, selectIsShoppingCartGuidExpired, selectUserData} from '~/shared/store/selectors';
import {isTimestampExpired} from '~/shared/utils/time';
import {getCurrentLocation, removeQueries, refreshPage} from '~/shared/router';
import {trackInitialScroll} from '~/shared/services/initialScrollTracking';
import {routeNames} from '~/shared/routes';
import {eventNames, raise, on, once} from '~/shared/events';
import {trackEvent} from '~/shared/services/analytics';
import store from '~/shared/store';
import {createLogger} from '~/shared/logging';
import {openModalOrPageFromQuery} from '~/shared/services/navigation';
import {useIsMounted} from '~/shared/hooks';
import {detectUsingTabForNavigation} from '~/shared/usingKeyboard/keyboardHelper';
import {getDefaultAddress} from '~/shared/services/addressHelper';
import {handleRefreshToken, is401Error} from '~/shared/services/auth';
import {api} from '~/shared/services/bffService';

import OrderManager from '../OrderManager/OrderManager';
import {CHANGE_RESTAURANT_ON_INIT_ROUTES} from '../ManagerProvider/ManagerProviderHelper';

import {setInitialA11yValues, invokeUserRequiredActionIfNeeded} from './initManagerUtils';

const TransactionTypes = {WEB: 'Web'};
const OPEN_ADD_RESTAURANT_REVIEW_MODAL_DELAY = 2000;

const logger = createLogger('InitManager');

const isInitializedPromise = new Promise(resolve => once(eventNames.initialized, resolve));

let isInitialized = false;

const AVOID_INIT_SEARCH_RESTAURANTS_FOR_ROUTES = [
  routeNames.info, // avoiding redundant calls.
  routeNames.home, // avoiding redundant calls.
  routeNames.restaurants, // Restaurant's useNavigateToPage is executing reSearchRestaurants if needed.
  routeNames.unsubscribe, // avoiding redundant calls.
  routeNames.city, // avoiding redundant calls.
  routeNames.menu, // no need since we use bff.getRestaurant
  routeNames.confirmAge,
  routeNames.checkout,
  routeNames.gfoOptOut,
];

once(eventNames.initialized, () => {
  isInitialized = true;
});

export function getIsInitializedPromise() {
  return isInitializedPromise;
}

export function getIsInitialized() {
  return isInitialized;
}

export function runWhenInitialized(cb) {
  if (getIsInitialized()) {
    cb();
  } else {
    getIsInitializedPromise().then(cb);
  }
}

export function useOnPageInit(onInitialized) {
  const isMounted = useIsMounted();

  useEffect(() => {
    let onUnmount = null;

    runWhenInitialized(() => {
      if (!isMounted()) {
        return null;
      }

      onUnmount = onInitialized();
    });

    return () => {
      const isUnMountPromise = !!onUnmount?.then;
      if (isUnMountPromise) {
        onUnmount.then(um => {
          if (um) {
            um();
          }
        });
      } else if (onUnmount) {
        onUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function invalidateExpiredShoppingCartCookie({shoppingCartGuidTimestampFromCookies, shoppingCartGuidFromCookies}) {
  const isShoppingCartGuidExpired = isTimestampExpired(
    shoppingCartGuidTimestampFromCookies,
    shoppingCartGuidExpirationMinutes,
  );
  store.dispatch(
    restrictedSharedActions.setShoppingCartGuid({
      shoppingCartGuid: isShoppingCartGuidExpired ? null : shoppingCartGuidFromCookies,
      shoppingCartGuidTimestamp: isShoppingCartGuidExpired ? null : shoppingCartGuidTimestampFromCookies,
    }),
  );
}

async function login() {
  try {
    await ManagerProvider.login(null, {reInitializeState: false, isAutomaticLogin: true});
  } catch (err) {
    if (is401Error(err)) {
      await handleRefreshToken(err, initApp);
      return;
    }

    logger.warn("User wasn't able to login.", err);
    store.dispatch(actions.clearUserWithLoaded());
  }
}

const handleClickedRefreshOnRefreshPopup = () => {
  if (localStorage.hasClickedRefreshOnRefreshPopup === 'true') {
    trackEvent('hasClickedRefreshOnRefreshPopup');
    delete localStorage.hasClickedRefreshOnRefreshPopup;
  }
};

const setCurrentWindowWidth = () => {
  store.dispatch(actions.setCurrentWindowWidth());
};

const setCurrentWindowWidthDebounced = debounce(setCurrentWindowWidth, 300);

async function handleTransactionReview() {
  const state = store.getState();
  const userData = selectUserData(state);

  const {routeName, query} = getCurrentLocation();
  const {transactionId: transactionIdFromQuery, resId} = query;

  if (!userData || selectCurrentModal(state) || routeName === routeNames.updateUserDetails) {
    return;
  }
  let transaction = null;

  if (transactionIdFromQuery && resId) {
    removeQueries(['transactionId', 'resId']);
    try {
      const reviewValidationResult = await store.dispatch(
        actions.validateInsertReview({
          transactionId: transactionIdFromQuery,
          transactionType: TransactionTypes.WEB,
          restaurantId: resId,
        }),
      );
      const {errors, success} = reviewValidationResult;
      if (!success) {
        logger.error('Restaurant review request validation failed', JSON.stringify(errors));
        await store.dispatch(actions.setCurrentModal('restaurant_review_error_modal'));
        return;
      }
      transaction = {
        transactionId: transactionIdFromQuery,
        transactionType: TransactionTypes.WEB,
        restaurantId: resId,
      };
    } catch (e) {
      if (is401Error(e)) {
        await handleRefreshToken(e, handleTransactionReview);
        return;
      }
      
      logger.error('Restaurant review request validation failed', JSON.stringify(e?.errors));
      await store.dispatch(actions.setCurrentModal('restaurant_review_error_modal'));
      return;
    }
  } else {
    try {
      transaction = await store.dispatch(actions.getLastTransactionWithoutReview());
    } catch (e) {
      if (is401Error(e)) {
        await handleRefreshToken(e, actions.getLastTransactionWithoutReview);
      }
    }
  }

  const {transactionType, transactionId, ...restaurant} = transaction || {};
  if (!transactionId || transactionType !== TransactionTypes.WEB) {
    return;
  }
  const hasSeenReviewTransactionId = window.localStorage.getItem(LocalStorageKeys.HAS_SEEN_REVIEW_TRANSACTION_ID);
  if (!transactionIdFromQuery && hasSeenReviewTransactionId === transactionId) {
    return;
  }

  if (restaurant) {
    const restaurantData = await api.fetchRestaurant({id: restaurant.restaurantId});

    setTimeout(() => {
      store.dispatch(actions.setCurrentModal('addRestaurantReview', {restaurant: restaurantData, transactionId}));
    }, OPEN_ADD_RESTAURANT_REVIEW_MODAL_DELAY);
  }
}

const onVisibiltyChange = () => {
  if (!document.hidden) {
    const isShoppingCartGuidExpired = selectIsShoppingCartGuidExpired(store.getState());
    if (isShoppingCartGuidExpired) {
      refreshPage();
    }
  }
};

export async function initApp() {
  try {
    const siteCookies = getParsedSiteCookies();
    const {routeName} = getCurrentLocation();

    const {
      contextCookie: {shoppingCartGuid: shoppingCartGuidFromCookies},
      nextContextCookie: {shoppingCartGuidTimestamp: shoppingCartGuidTimestampFromCookies},
    } = siteCookies;

    setCurrentWindowWidth();

    setInitialA11yValues(store);

    invalidateExpiredShoppingCartCookie({shoppingCartGuidTimestampFromCookies, shoppingCartGuidFromCookies});

    await login();
      
    // this fixes missing setAddressInOrder,
    // that was called with searchRestaurants.
    // set default address (if there's any) at init, and then change if needed.
    const defaultAddress = getDefaultAddress();
    if (defaultAddress) {
      // FIXME: - OrderManager should not be accessed directly
      OrderManager.setAddressInOrder(defaultAddress);
    }

    await ManagerProvider.changeAddress({address: getDefaultAddress(), searchRestaurants: false});

    const dataInOrder = await ManagerProvider.getOrderData();
    
    const shouldChangeRestaurant = CHANGE_RESTAURANT_ON_INIT_ROUTES.includes(routeName);

    // TODO: should revisit AVOID_INIT_SEARCH_RESTAURANTS_FOR_ROUTES & CHANGE_RESTAURANT_ON_INIT_ROUTES
    //       separate completely between searchRestaurants and changeRestaurant
    if (!AVOID_INIT_SEARCH_RESTAURANTS_FOR_ROUTES.includes(routeName)) {
      await ManagerProvider.reSearchRestaurants({
        force: shouldChangeRestaurant,
        currentRestaurantId: shouldChangeRestaurant ? dataInOrder.restaurant?.restaurantId : undefined,
      });
    } else if (shouldChangeRestaurant && !!dataInOrder?.restaurant?.restaurantId) {
      // fix current restaurant loading on refresh confirm age page and checkout.
      await ManagerProvider.changeRestaurant({restaurantId: dataInOrder.restaurant?.restaurantId});
    }

    await ManagerProvider.isIdle();

    detectUsingTabForNavigation();

    window.addEventListener('resize', setCurrentWindowWidthDebounced);
    window.addEventListener('visibilitychange', onVisibiltyChange);

    trackInitialScroll({store});

    (function handleFontKerling() {
      const isApplePlatformVendor = store.getState().userDevice.platformVendor === 'Apple';
      if (isApplePlatformVendor) {
        document.body.classList.add('remove-font-kerling');
      }
    })();

    openModalOrPageFromQuery();

    handleClickedRefreshOnRefreshPopup();

    handleTransactionReview();

    on([eventNames.initialized, eventNames.login, eventNames.locationChanged], () => {
      setTimeout(() => {
        invokeUserRequiredActionIfNeeded({store});
      }, 100);
    });

    raise(eventNames.initialized);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(new Error('init manager init error'), {error});
  }
}
