import {memo} from 'react';

import styled from 'styled-components';

import {RestaurantFromGet} from '~/shared/store/models';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {body12Bold, body14Normal} from '~/shared/theme/typography';
import {PrimaryActionButton} from '~/shared/components/Button';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flipOnLTR} from '~/shared/theme/utils';

const Root = styled(ClickOutsideDiv)<{className?: string}>`
  position: absolute;
  ${flipOnLTR`left: 10px;`}
  width: calc(100% - 22px);
  z-index: 2;
  background-color: ${({theme}) => theme.colors.surface};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  box-shadow: ${({theme}) => theme.shadows.shadow1};
`;
  
const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 240px;
`;

const TimeRow = styled(PrimaryActionButton)<{isSelected?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: 100%;
  background-color: ${({theme, isSelected}) => (isSelected ? theme.colors.gray900 : theme.colors.surface)};
  margin: 0;

  &:hover {
    background-color: ${({theme}) => theme.colors.gray900};
    border: none;
  }
`;

const FreeDeliveryLabel = styled.div`
  ${body12Bold}
  padding: 2px 4px;
  border-radius: 4px;
  color: ${({theme}) => theme.colors.green};
  background-color: ${({theme}) => theme.colors.supportiveFeedback};
  ${flipOnLTR`
    margin-right: 4px;
  `}
`;

const Time = styled.div`
  line-height: 24px;
  color: ${({theme}) => theme.colors.text2};
  ${body14Normal}
`;

interface TimeDropdownProps {
  futureOrderAvailableDatesAndTimes: MutatedFutureOrderAvailableDatesAndTimes[];
  deliveryRules?: RestaurantFromGet['deliveryRules'];
  currentTimeKey: string;
  deliveryTimeRange: string;
  onClick: (times: MutatedFutureOrderAvailableDatesAndTimes['times'][number], newDeliverFee?: number) => void;
  onClickOutside: () => void;
  className?: string;
}

const TimeDropdown = ({
  className,
  futureOrderAvailableDatesAndTimes,
  deliveryRules,
  currentTimeKey,
  deliveryTimeRange,
  onClick,
  onClickOutside,
}: TimeDropdownProps) => {
  const {t} = getLocalizationService();

  const handleClickOutside = (e: Event) => {
    if (
      e.target instanceof Element &&
      (e.target.classList.contains('toggle_sdfo_dropdown') || (e.target?.nodeName === 'path' && e.target?.parentElement?.classList?.contains('toggle_sdfo_dropdown')))
    ) {
      return;
    }
    onClickOutside();
  };

  return (
    <Root onClickOutside={handleClickOutside} className={className}>
      <Content>
        {futureOrderAvailableDatesAndTimes.map(({times}) => {
          return times.map(time => {
            const {timeKeyLabel, ruleId, timeKey, timeRange} = time;
            const rule = deliveryRules?.find(currentDeliveryRule => currentDeliveryRule.id === ruleId);
            const isAsap = isCurrentTimeKeyAsap(timeKey);
            return (
              <TimeRow
                onClick={() => onClick({
                  timeKey,
                  timeKeyLabel: isAsap ? deliveryTimeRange : timeKeyLabel,
                  timeRange,
                  ruleId,
                }, rule?.deliveryFee)}
                key={timeKey}
                isSelected={currentTimeKey && currentTimeKey === timeKey}
              >
                <Time>{isAsap ? deliveryTimeRange : timeKeyLabel}</Time>
                {!rule?.deliveryFee && <FreeDeliveryLabel>{t('free_delivery')}</FreeDeliveryLabel>}
              </TimeRow>
            );
          });
        })}
      </Content>
    </Root>
  );
};

export default memo(TimeDropdown);
