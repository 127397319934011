import {useRef, useCallback, useEffect, memo} from 'react';

import styled from 'styled-components';
import {memoize} from 'lodash';
import {Form} from 'react-final-form';

import _FormField from '~/shared/components/FormField';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {LongButton} from '~/shared/components/Modals/InfoModals';
import {getLocalizationService} from '~/shared/services/localisationService';
import apiService from '~/shared/services/apiService';
import {FieldWrapper, FormWrapper, FormContentRoot} from '~/shared/components/loginStyled';
import AgreeToTermsSection from '~/shared/components/AgreeToTermsSection';
import {body14Normal} from '~/shared/theme/typography';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {useAutoFocus} from '~/shared/hooks';
import {onTermsOfUseClick, onPrivacyPolicyClick} from '~/common/utils/agreeToTermsUtils';

const FieldsContainer = styled.div`
  ${flexColumn};

  ${/* sc-selector */ FieldWrapper}:first-child {
    margin-top: 0;
  }

  ${media.minMobile`
    ${FieldWrapper} {
      width: 49%;
      ${flipOnLTR`
        margin-left: 2%;
      `};
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(2n) {
        ${flipOnLTR`
          margin-left: 0;
        `};
      }
    }
  `};
`;

const Row = styled.div`
  display: flex;
`;

const SubmitButton = styled(LongButton)`
  border-radius: 0;
`;

const HeaderText = styled.div`
  ${body14Normal};
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
  margin-bottom: 20px;
`;

const FormField = styled(_FormField).attrs({
  className: 'white-border-on-contrast',
})`
  ${({addTrailingMargin, theme}) =>
    addTrailingMargin &&
    `
    ${flipOnLTR({theme})`
      margin-left: 20px;
    `};
  `}
`;

const SignUpForm = ({
  initialValues = {fullName: '', email: '', cellPhone: '', agreeToTerms: false, wantPromotion: true},
  onSubmit,
  sendActivationCodeError,
  existingEmailAction,
}) => {
  const {t} = getLocalizationService();

  const formWrapperRef = useRef();
  const focusElementRef = useRef();
  const emailInUse = useRef();

  const checkFreeEmail = memoize(async emailAddress => {
    try {
      const response = await apiService.isEmailFree({emailAddress});
      return response?.data;
    } catch (error) {
      return false;
    }
  });

  const validateEmail = useCallback(
    async emailAddress => {
      if (!emailAddress) return;

      const isFree = await checkFreeEmail(emailAddress);

      if (!isFree) {
        emailInUse.current = true;
        return 'you_already_registered_to_10bis_in_the_past';
      }
      emailInUse.current = false;
    },
    [checkFreeEmail],
  );

  useEffect(() => {
    if (initialValues?.email) {
      validateEmail(initialValues.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.email]);

  useAutoFocus({
    domEl: focusElementRef?.current,
  });

  const validateFullName = useCallback(
    fullName => {
      if (!fullName.trim().includes(' ')) {
        return t('full_name_must_include_first_and_last_name');
      }
    },
    [t],
  );

  const validateNewUser = ({agreeToTerms}) => {
    const errors = {};

    if (!agreeToTerms) {
      errors.agreeToTerms = 'accepting_the_terms_is_required';
    }

    return errors;
  };

  const onSubmitSplitFirstAndLastName = useCallback(
    formValues => {
      const {fullName, ...restOfTheFormValues} = formValues;

      return onSubmit({
        fullName,
        ...restOfTheFormValues,
      });
    },
    [onSubmit],
  );

  return (
    <div ref={formWrapperRef}>
      <Form
        onSubmit={onSubmitSplitFirstAndLastName}
        validate={validateNewUser}
        initialValues={initialValues}
        validateOnBlur
        render={({handleSubmit, errors, touched}) => {
          return (
            <FormWrapper onSubmit={handleSubmit} noValidate>
              <HeaderText id="modal-title">{t('signup_header')}</HeaderText>
              <FormContentRoot>
                <FieldsContainer>
                  <Row>
                    <FormField
                      {...{
                        name: 'fullName',
                        placeholder: t('full_name'),
                        type: 'text',
                        validator: validateFullName,
                        required: true,
                      }}
                    />
                  </Row>
                  <Row>
                    <FormField
                      {...{
                        name: 'email',
                        placeholder: t('email_address'),
                        type: 'email',
                        validator: validateEmail,
                        required: true,
                        inputProps: {
                          actionButton: emailInUse.current &&
                            existingEmailAction &&
                            touched.email && {
                              onClick: existingEmailAction,
                              text: t('login'),
                            },
                        },
                      }}
                    />
                  </Row>
                  <Row>
                    <FormField
                      {...{
                        name: 'cellPhone',
                        placeholder: t('cell_phone'),
                        type: 'phone',
                        required: true,
                      }}
                    />
                  </Row>
                </FieldsContainer>

                <AgreeToTermsSection
                  onTermsOfUseClick={onTermsOfUseClick}
                  onPrivacyPolicyClick={onPrivacyPolicyClick}
                  errorText={
                    ((touched.agreeToTerms && errors.agreeToTerms) || sendActivationCodeError) &&
                    t(errors.agreeToTerms || sendActivationCodeError)
                  }
                />

                <SubmitButton ref={focusElementRef} type="submit">
                  {t('to_confirmation')}
                </SubmitButton>
              </FormContentRoot>
            </FormWrapper>
          );
        }}
      />
    </div>
  );
};

export default memo(SignUpForm);
