// @ts-expect-error config needs to be rewritten as ts
import {legacySiteUrl} from '~/shared/config';
import {RouteNameDictionary, SupportedLanguageType} from '~/shared/consts/commonConsts';
import {selectUserData, selectCurrentModal} from '~/shared/store/selectors';
import {getSlug} from '~/shared/utils/urls';
import {createLogger} from '~/shared/logging';
import store from '~/shared/store';
import {getLocalizationService} from '~/shared/services/localisationService';

import {Cuisine, Dish} from '../store/models';
import actions from '../store/actions';

import {City} from './citiesHelpers';

const logger = createLogger('urlService');

export const replaceLegacyUrlToken = (url?: string) => url && url.replace('<legacyToken>', legacySiteUrl);

export const getLegacySiteUrl = (relativeUrl: string) => {
  if (!relativeUrl) {
    return legacySiteUrl;
  }
  return legacySiteUrl + (relativeUrl?.startsWith('/') ? relativeUrl.substr(1) : relativeUrl);
};

export function getMenuOrDishPagePath({
  restaurantId,
  restaurantName,
  restaurantSlug,
  dish,
  query,
}: GetMenuOrDishPagePathPayload) {
  // eslint-disable-next-line prefer-rest-params
  const argsForLogger = arguments[0];
  const {dishId, shoppingCartDishId} = dish || {};

  if (!restaurantId) {
    logger.error('no restaurantId was provided to getMenuOrDishPagePath.', argsForLogger);
    return;
  }

  if (!restaurantSlug && !restaurantName && restaurantName !== false) {
    logger.errorDialog(
      [
        'no restaurantName or restaurantSlug was provided to getMenuOrDishPagePath.',
        'if you want to use an incomplete url without restaurantName, pass "restaurantName = false"',
      ].join('\n'),
      argsForLogger,
    );
  }

  return [
    '/restaurants/menu/delivery',
    restaurantId,
    restaurantSlug || (restaurantName && getSlug(restaurantName)),
    dishId && `dish/${dishId}/${shoppingCartDishId || ''}`,
    query && `?${query}`,
  ]
    .filter(Boolean)
    .join('/');
}

export const modalsQuery: ModalQueries = {
  dishId: {
    getRouteOnMobile: (routeParams: Record<string, any>) => {
      if (!routeParams) {
        logger.error('no routeParams was provided to {modalsQuery.dishId.getRouteOnMobile}.');
        return;
      }
      const {restaurantId, restaurantSlug, dishId, shoppingCartDishId} = routeParams;
      const dishPagePath = getMenuOrDishPagePath({
        restaurantId,
        restaurantSlug,
        dish: {
          dishId,
          shoppingCartDishId,
        },
      });
      return dishPagePath;
    },
    getActionOnDesktop: (query: Record<string, any>) => {
      const state = store.getState();
      const modal = selectCurrentModal(state);

      if (!query || modal) {
        return {};
      }

      const {dishId, shoppingCartDishId} = query;
      return {
        action: 'setCurrentModal',
        params: ['dish_modal', {dishId, shoppingCartDishId, modalOptions: {width: '780px'}}],
      };
    },
  },
  showCheckout: {
    getRouteOnMobile: () => {
      return '/checkout';
    },
    getActionOnDesktop: () => {
      return {
        action: 'setCurrentModal',
        params: ['checkout_modal'],
      };
    },
  },
  openLogOnModal: {
    getRouteOnMobile: () => {
      return '/signin';
    },
    getActionOnDesktop: () => {
      return {
        action: 'setCurrentModal',
        params: ['loginOrRegister', {mode: 'login'}],
      };
    },
  },
  forgotPasswordTokenExpired: {
    getRouteOnMobile: () => {
      logger.warn('Todo: implement forgotPasswordTokenExpired action navigation on mobile');
      return undefined;
    },
    getActionOnDesktop: () => {
      const state = store.getState();
      const user = selectUserData(state);
      if (user) {
        return {};
      }

      return {
        action: 'setCurrentModal',
        params: ['forgotPasswordTokenExpiredModal'],
      };
    },
  },
};

export const getCityPageUrl = (payload: {
  city: City;
  cuisine?: Cuisine;
  languageKey?: SupportedLanguageType['key'];
}) => {
  const {city, cuisine, languageKey} = payload;
  if (!city) {
    logger.error('No city parameter was passed to getCityPageUrl');
  }
  const {currentLanguageKey} = getLocalizationService();
  const cityPageLanguageKey = languageKey || (currentLanguageKey as SupportedLanguageType['key']);

  const routeBase = RouteNameDictionary.cityPage[cityPageLanguageKey];
  const citySlug = getSlug(city.cityName[cityPageLanguageKey]);

  let route = `/${routeBase}/${citySlug}`;

  if (cuisine) {
    const cuisineSlug = getSlug(cuisine.names[cityPageLanguageKey]);
    route += `/${cuisineSlug}`;
  }
  return route;
};

type ModalQueries = {
  [K in 'dishId' | 'showCheckout' | 'openLogOnModal' | 'forgotPasswordTokenExpired']: ModalQuery;
};

interface ModalQuery {
  getActionOnDesktop: (query: Record<string, any>) =>
    | {
        action: keyof typeof actions;
        params: (string | Record<string, any>)[];
      }
    | Record<string, undefined>;
  getRouteOnMobile: (routeParams: Record<string, any>) => string | undefined;
}

interface GetMenuOrDishPagePathPayload {
  restaurantId?: number;
  restaurantName?: string | boolean;
  restaurantSlug?: string;
  dish?: Partial<Pick<Dish, 'dishId' | 'shoppingCartDishId'>>;
  query?: string;
}
