import {useEffect} from 'react';

// import {createLogger} from '~/shared/logging';
import {getFocusableElements} from '~/shared/utils/general';

// const logger = createLogger('useAutoFocus');

function useAutoFocus({domEl, searchForFocusableChildren = true, triggerCondition = true}) {
  useEffect(() => {
    if (!domEl || !triggerCondition) {
      return;
    }

    const cancelSearchFocusableElements = getFocusableElements({domEl, searchForFocusableChildren}, focusableElements => {
      focusableElements[0].focus();
    });

    return () => {
      cancelSearchFocusableElements();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domEl, triggerCondition]);
}

export default useAutoFocus;
