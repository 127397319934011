import {round} from 'lodash';

import {BillingLineType} from '~/shared/consts/checkoutConsts';
import {IShoppingCartBillingLine} from '~/shared/store/models/ShoppingCart/IShoppingCart';

export const calculateTotalSavedAmount = (billingLines?: IShoppingCartBillingLine[]) => {
  const totalSaved =
    billingLines?.reduce((currentSaved, {type, amount}) => {
      if (type === BillingLineType.DiscountCoupon || type === BillingLineType.DeliveryDiscount) {
        return currentSaved + Math.abs(amount);
      }

      return currentSaved;
    }, 0) || 0;
  return round(totalSaved, 2);
};
