import {memo} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {media} from '~/shared/theme/utils';
import {getLocalizationService} from '~/shared/services/localisationService';
import {Link} from '~/shared/router';
import useOpenSignIn from '~/shared/hooks/useOpenSignIn';
import {selectUserData} from '~/shared/store/selectors';
import {body14Normal, body16Normal, body18Normal} from '~/shared/theme/typography';
import {flexCenter, flexColumn} from '~/shared/theme/FlexLayout';

const Root = styled.div`
  ${flexColumn};
  ${flexCenter};
  ${body16Normal};
  height: auto;
  line-height: 18px;
  ${media.minMobile`
    ${body18Normal};
    line-height: 27px;
  `};
`;

const UserText = styled.div`
  color: ${({color}) => color};
  ${body14Normal};
  line-height: 14px;
  ${media.minMobile`
    ${body16Normal};
    line-height: 16px;
  `};
`;

const LoginLink = styled(Link)`
  color: ${({theme}) => theme.colors.primaryText};
  ${body14Normal};
  cursor: ${({onClick}) => (onClick ? 'pointer' : 'inherit')};
  line-height: 14px;
  ${media.minMobile`
    ${body16Normal};
    line-height: 16px;
  `};
`;

const UserStatus = () => {
  const {t} = getLocalizationService();
  const userData = useSelector(selectUserData);
  const {openLogin} = useOpenSignIn();

  return (
    <Root>
      {userData ? (
        <UserText>{t('hi_xxx', {name: userData.firstName})}</UserText>
      ) : (
        <LoginLink onClick={openLogin}>{t('login')}</LoginLink>
      )}
    </Root>
  );
};

export default memo(UserStatus);
