/* eslint-disable no-throw-literal */
import {EMPTY_OBJECT} from '../consts/commonConsts';

export type ApiError = {
  status: number;
  stack: string;
  message: string;
  url: string;
  data?: any;
  errors?: unknown[];
};

export const createApiError = (error: Record<string, any>) => {
  if (error?.response) {
    const {status, config, data} = error?.response || EMPTY_OBJECT;

    throw {
      status,
      stack: error?.stack || '',
      message: data?.description || error?.toJSON()?.message || 'http request failed',
      url: config?.url,
    };
  }

  const {data, config} = error || EMPTY_OBJECT;
  const errorData = data?.errors?.[0];

  throw {
    message: errorData?.errorDesc || 'http request failed',
    url: config?.url,
    status: errorData?.errorCode,
    stack: errorData?.stack || '',
    data: data?.data,
    errorData: data?.errors,
    error,
  };
};

export function isApiError(err: unknown): err is ApiError {
  return !!err && typeof err === 'object' &&
    'stack' in err &&
    'status' in err &&
    'message' in err;
}
