import {createSelector, createStructuredSelector} from 'reselect';
import {chunk, countBy, filter, flatMap, isEmpty, pick, pickBy} from 'lodash';
import moment from 'moment-timezone';

import {
  DELIVERY_RULES,
  DeliveryMethods,
  RestaurantsFiltersSortOptionsTypes,
  RestaurantsGroupTypes,
} from '~/shared/consts/restaurantConsts';
import {addressKeyFromParts, addressPartsFromKey, areAddressesEqual} from '~/shared/utils/address';
import {GroupSubtitle} from '~/shared/services/listTitle';
import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {isDishAgeRestricted} from '~/shared/utils/ageRestriction';
import {getRestaurantStatus, RESTAURANT_STATUS_MAP} from '~/shared/utils/restaurants/restaurantStatus';
import {isDeliveryRuleModel} from '~/shared/utils/restaurant';
import {
  CARDS_HEIGHT,
  CARDS_TOP_SPACING,
  DESCRIPTION_BOX_BOTTOM_PADDING,
  DESCRIPTION_MAX_LETTERS_IN_ONE_LINE,
  ITEMS_IN_ROW,
  REORDER_DEFAULT_WRAPPER_HEIGHT,
  REORDER_TABLET_BOTTOM_PADDING,
  REORDER_TABLET_CARD_BOTTOM_MARGIN,
  REORDER_TABLET_TITLE_HEIGHT,
  ROW_DESCRIPTION_HEIGHT,
  ROW_EMPTY_DESCRIPTION_HEIGHT,
  ROW_MARGIN_TOP_DEFAULT,
  ROW_MARGIN_TOP_GROCERIES,
  ROW_TITLE_HEIGHT,
  TITLE_BOTTOM_PADDINGS,
  TITLE_TOP_PADDINGS,
} from '~/shared/consts/dishListMarkup';
import getAvailableDeliveryRules from '~/shared/utils/restaurants/deliveryOptions/getAvailableDeliveryRules';
import {
  ActiveRulesData,
  EMPTY_RULES_DATA,
  getCurrentRestaurantActiveRules,
} from '~/shared/utils/restaurants/getActiveDeliveryRules';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';
import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';
import {getGroupItemRootHeight} from '~/shared/components/RestaurantList/RestaurantVirtualizedList/GroupItem';
import {BillingLineWithDiscountsData} from '~/shared/store/models/ShoppingCart/IShoppingCart';
import {BillingLineType} from '~/shared/consts/checkoutConsts';
import {getDishPriceWithDiscount} from '~/shared/utils/priceDiscountCalculator';
import {BILLING_LINES_WITHOUT_DISCOUNTS_DATA} from '~/shared/consts/discounts';

import {
  arrangeGroupItems,
  RESTAURANTS_ROW_HEIGHT,
  sortRestaurantsList,
  SPACE_BETWEEN_ROWS,
} from '../../utils/restaurantListUtils';
import * as modulesSelectors from '../storeModules/selectors.index';
import {
  selectCollectionsList,
  selectCurrentAddressKey,
  selectCurrentDeliveryMethod,
  selectCurrentOrderDateAndTime,
  selectCurrentRestaurant,
  selectCurrentRestaurantShoppingCartDeliveryType,
  selectInitialOrderData,
  selectIsRestaurantFromGetReady,
  selectOrderDeliveryRule,
  selectRestaurantsSortBy,
  selectShoppingCartBillingLines,
  selectUserAddresses,
} from '../storeModules/selectors.index';
import {
  Address,
  AddressFromServer,
  CompanyAddress,
  CouponAssignmentType,
  Cuisine,
  Dish,
  isRemoteAddress,
  LocalAddress,
  MenuCategory,
  RestaurantBusinessTypeName,
  RestaurantFromSearch,
  ShoppingCartDish,
} from '../models';
import {Carousel} from '../models/Carousel';

import filterRestaurantList from './selectorUtils/filterRestaurantList';
import groupedRestaurantsSelector from './selectorUtils/groupedRestaurantsSelector';
import {filterSortOptionItems} from './selectorUtils/filterSortOptionItems';
import makeCarouselGroups from './selectorUtils/carouselGroups';

const filterRestaurantsForCuisines = (...[data, uiFilters, deliveryMethod]: Parameters<typeof filterRestaurantList>) =>
  filterRestaurantList(data, {...uiFilters, cuisines: undefined}, deliveryMethod);

function filteredRestaurantGroupsSelector(groups: RestaurantsGroup[]): FilteredRestaurantGroup[] {
  return groups.reduce<FilteredRestaurantGroup[]>((result, group) => {
    const {groupId, subTitle} = group;

    const groupItems = group.items.map<FilteredRestaurantsGroupItem>(groupedObject => ({
      ...groupedObject,
      item: {
        ...groupedObject.item,
        isFastDelivery: groupedObject.groupId !== RestaurantsGroupTypes.POOLED && groupedObject.item.deliveryFee === 0,
      },
    }));

    const count = groupItems.length;

    if (!count) {
      return result;
    }

    result.push({
      isGroup: true,
      count,
      groupId,
      key: groupId,
      subTitleKey: subTitle?.textKey,
      subTitleArgs: subTitle?.textArgs,
    });
    return result.concat(groupItems);
  }, []);
}

type FilteredRestaurantGroup = FilteredRestaurantsGroupSeparator | FilteredRestaurantsGroupItem;
type FilteredRestaurantsGroupItem = RestaurantsGroupItem & {
  item: RestaurantFromSearch & {isFastDelivery: boolean};
  isGroup?: boolean;
};

interface FilteredRestaurantsGroupSeparator {
  isGroup: true;
  count: number;
  groupId: RestaurantsGroupTypes;
  key: string;
  subTitleKey?: GroupSubtitle['textKey'];
  subTitleArgs?: GroupSubtitle['textArgs'];
}

export const hiddenFiltersOnPickup = [
  RestaurantsFiltersSortOptionsTypes.SPECIAL,
  RestaurantsFiltersSortOptionsTypes.MINIMUMORDER,
  RestaurantsFiltersSortOptionsTypes.DELIVERYFEE,
  RestaurantsFiltersSortOptionsTypes.DISTANCE,
  RestaurantsFiltersSortOptionsTypes.DELIVERYTIME,
];

export const selectFilterSortOptionItems = createSelector(
  modulesSelectors.selectCurrentDeliveryMethod,
  filterSortOptionItems,
);

export const selectSortOptionAccordingToDeliveryMethod = createSelector(
  selectFilterSortOptionItems,
  modulesSelectors.selectRestaurantsSortBy,
  modulesSelectors.selectCurrentDeliveryMethod,
  (filteredSortOptionItems, sortBy, deliveryMethod) => {
    if (filteredSortOptionItems.includes(sortBy)) {
      return sortBy;
    }
    if (deliveryMethod === DeliveryMethods.PICKUP) {
      return RestaurantsFiltersSortOptionsTypes.SPECIALPICKUP;
    }
    return RestaurantsFiltersSortOptionsTypes.SPECIAL;
  },
);

const makeGroup = (groupId: RestaurantsGroupTypes, items: RestaurantFromSearch[], subTitle?: GroupSubtitle) => ({
  groupId,
  items: items.map(item => ({key: `${groupId}_${item.restaurantId}`, groupId, item})),
  subTitle,
});
export type RestaurantsGroup = ReturnType<typeof makeGroup>;
type RestaurantsGroupItem = RestaurantsGroup['items'][number];

export const selectRemoteAddresses = createSelector(modulesSelectors.selectUserAddresses, remoteAddresses => {
  return remoteAddresses.map(remoteAddress => {
    if (remoteAddress.addressKey) {
      return remoteAddress;
    }

    return {
      ...remoteAddress,
      addressKey: addressKeyFromParts(remoteAddress),
    };
  });
});

export const selectLocalAddress = createSelector(
  modulesSelectors.selectUserData,
  modulesSelectors.selectLocalAddressRoot,
  (user, localAddress) => {
    if (!localAddress) return;
    return {...localAddress, phone01: user?.cellphone};
  },
);
export const selectAllAddresses = createSelector(
  selectRemoteAddresses,
  selectLocalAddress,
  (remoteAddresses, localAddress) => filter([...remoteAddresses, localAddress]) as Array<Address | LocalAddress>,
);

export const selectCurrentAddress = createSelector(
  modulesSelectors.selectCurrentAddressKey,
  selectAllAddresses,
  (currentAddressKey, addresses) => addresses.find(address => address?.addressKey === currentAddressKey),
);

export const selectIsComanyAddressAndRestaurantWithoutVoucher = createSelector(
  selectCurrentAddress,
  selectCurrentRestaurant,
  (currentAddress, currentRestaurant) => (currentAddress as Address)?.isCompanyAddress && !currentRestaurant?.isVoucherEnabled,
);

export const selectIsRestaurantWithoutVoucher = createSelector(selectCurrentRestaurant, currentRestaurant => !currentRestaurant?.isVoucherEnabled);

const selectIsPooledOrderActive = createSelector(
  modulesSelectors.selectRestaurantsMainListData,
  mainData => {
    if (!mainData.restaurantsList) {
      return false;
    }
    const openPooledOrderRestaurants = mainData.restaurantsList.filter(
      ({hasActivePoolRule, isOpenNow}) => hasActivePoolRule && isOpenNow,
    );
    return openPooledOrderRestaurants && openPooledOrderRestaurants.length > 0;
  },
);

const selectMainFilteredRestaurantsData = createSelector(
  modulesSelectors.selectRestaurantsMainListData,
  modulesSelectors.selectFilters,
  modulesSelectors.selectCurrentDeliveryMethod,
  selectIsComanyAddressAndRestaurantWithoutVoucher,
  filterRestaurantList,
);

export const selectDefaultSortOption = createSelector(modulesSelectors.selectCurrentDeliveryMethod, deliveryMethod => {
  if (deliveryMethod === DeliveryMethods.DELIVERY) {
    return RestaurantsFiltersSortOptionsTypes.SPECIAL;
  }
  if (deliveryMethod === DeliveryMethods.PICKUP) {
    return RestaurantsFiltersSortOptionsTypes.SPECIALPICKUP;
  }

  return RestaurantsFiltersSortOptionsTypes.SPECIAL;
});

const selectIsOpenRestaurantsGroupSortingEnabled = createSelector(
  selectDefaultSortOption,
  modulesSelectors.selectRestaurantsSortBy,
  modulesSelectors.selectCurrentCollectionName,
  (defaultSortBy, currentSortBy, currentCollectionName) => {
    return defaultSortBy === currentSortBy && !currentCollectionName;
  },
);

const selectGroupedRestaurants = createSelector(
  selectMainFilteredRestaurantsData,
  modulesSelectors.selectCurrentDeliveryMethod,
  selectSortOptionAccordingToDeliveryMethod,
  selectIsPooledOrderActive,
  selectIsOpenRestaurantsGroupSortingEnabled,
  groupedRestaurantsSelector,
);

const selectGroupedRestaurantsForCuisines = createSelector(
  createSelector(
    modulesSelectors.selectRestaurantsMainListData,
    modulesSelectors.selectFilters,
    modulesSelectors.selectCurrentDeliveryMethod,
    filterRestaurantsForCuisines,
  ),
  modulesSelectors.selectCurrentDeliveryMethod,
  selectSortOptionAccordingToDeliveryMethod,
  selectIsPooledOrderActive,
  selectIsOpenRestaurantsGroupSortingEnabled,
  groupedRestaurantsSelector,
);

const selectCityGroupedRestaurantsForCuisines = createSelector(
  createSelector(
    modulesSelectors.selectRestaurantsCityListData,
    modulesSelectors.selectFilters,
    modulesSelectors.selectCurrentDeliveryMethod,
    filterRestaurantsForCuisines,
  ),
  modulesSelectors.selectCurrentDeliveryMethod,
  selectSortOptionAccordingToDeliveryMethod,
  selectIsPooledOrderActive,
  selectIsOpenRestaurantsGroupSortingEnabled,
  groupedRestaurantsSelector,
);

export const selectCityRestaurantsCuisinesCounts = createSelector(
  selectCityGroupedRestaurantsForCuisines,
  modulesSelectors.selectCuisinesData,
  (groupedRestaurants, cuisines) => {
    const filteredRestaurants = filteredRestaurantGroupsSelector(groupedRestaurants);

    const restaurantsWithoutCuisine = flatMap<FilteredRestaurantGroup, FilteredRestaurantsGroupItem['item']>(
      filteredRestaurants,
      item => ('isGroup' in item ? [] : item.item),
    );

    const allTypes = restaurantsWithoutCuisine.reduce((result, {restaurantCuisineKeysList}) => {
      return result.concat(restaurantCuisineKeysList.map(cuisineName => cuisineName.toLowerCase()) || []);
    }, [] as string[]);

    const typesCounts = countBy(allTypes);

    const cuisinesWithCount = cuisines.map(indexedCuisine => ({
      ...indexedCuisine,
      count: typesCounts[indexedCuisine.id] || 0,
    }));

    return cuisinesWithCount;
  },
);

const selectFilteredCityRestaurantsData = createSelector(
  modulesSelectors.selectRestaurantsCityListData,
  modulesSelectors.selectFilters,
  modulesSelectors.selectCurrentDeliveryMethod,
  selectIsPooledOrderActive,
  filterRestaurantList,
);

const selectGroupedCityRestaurants = createSelector(
  selectFilteredCityRestaurantsData,
  modulesSelectors.selectCurrentDeliveryMethod,
  selectSortOptionAccordingToDeliveryMethod,
  selectIsPooledOrderActive,
  selectIsOpenRestaurantsGroupSortingEnabled,
  groupedRestaurantsSelector,
);

export const selectFilteredRestaurants = createSelector(
  selectGroupedRestaurants,
  createStructuredSelector({
    cuisines: modulesSelectors.selectUiCuisinesFilter,
  }),
  filteredRestaurantGroupsSelector,
);

const selectFilteredCityRestaurants = createSelector(
  selectGroupedCityRestaurants,
  createStructuredSelector({
    cuisines: modulesSelectors.selectUiCuisinesFilter,
  }),
  filteredRestaurantGroupsSelector,
);
function filteredRestaurantListItemSelector(
  items: FilteredRestaurantGroup[],
  numberOfColumns: number,
) {
  return sortRestaurantsList({items, numberOfColumns});
}

export const selectFilteredCityRestaurantListItems = createSelector(
  selectFilteredCityRestaurants,
  modulesSelectors.selectVirtualizedListColumnsNumberByScreenSize,
  filteredRestaurantListItemSelector,
);

export const selectFilteredRestaurantListItems = createSelector(
  selectFilteredRestaurants,
  modulesSelectors.selectVirtualizedListColumnsNumberByScreenSize,
  filteredRestaurantListItemSelector,
);

export const selectRestaurantsGroup = createSelector(
  modulesSelectors.selectRestaurantsGroupListRestaurants,
  modulesSelectors.selectIsMaxMobile,
  modulesSelectors.selectUserDevice,
  (restaurants, isMaxMobile, userDevice) => {
    if (!restaurants) {
      return [];
    }

    const data = restaurants.map((restaurant, idx) => {
      const res = {
        groupId: RestaurantsGroupTypes.GROUP,
        height: RESTAURANTS_ROW_HEIGHT + SPACE_BETWEEN_ROWS,
        items: [
          {
            restaurantItem: {
              ...restaurant,
              isOpenNow: true, // for group, requirement is to not consider working hours
              isActive: true,
            },
            deliveryMethod: DeliveryMethods.DELIVERY, // for group page, we ALWAYS want to set the delivery method to "delivery"
          },
        ],
        key: `group_${idx}`,
      };

      return res;
    });

    if (isMaxMobile) {
      return data;
    }

    if (userDevice?.isMinDesktop) {
      return arrangeGroupItems({items: data, numberOfColumns: 3});
    }

    if (userDevice?.isMinLargeMobile) {
      return arrangeGroupItems({items: data, numberOfColumns: 2});
    }

    return arrangeGroupItems({items: data, numberOfColumns: 1});
  },
);

export const selectOrdersHistory = createSelector(
  modulesSelectors.selectCurrentDeliveryMethod,
  modulesSelectors.selectOrdersHistoryData,
  (deliveryMethod, data) => {
    if (deliveryMethod === DeliveryMethods.PICKUP) {
      return data?.pickupList || [];
    }
    if (deliveryMethod === DeliveryMethods.DELIVERY) {
      return data?.deliveryList || [];
    }
    return [];
  },
);

export const selectActiveFilters = createSelector(modulesSelectors.selectSelectedFilters, selectedFilters => {
  const {cuisines} = selectedFilters;
  const hasCuisines = Object.keys(cuisines || EMPTY_OBJECT).length;

  const activeFilters = {
    ...selectedFilters,
    cuisines: !hasCuisines ? false : cuisines,
  };

  return pickBy(activeFilters); // filter falsy
});

export const selectDishesAdditionalDetails = createSelector(
  modulesSelectors.selectShoppingCartDishes,
  modulesSelectors.selectCurrentRestaurantDishesByDishId,
  (dishesInShoppingCart, allDishesObject): (ShoppingCartDish & {totalPrice: number})[] | null => {
    if (isEmpty(dishesInShoppingCart) || isEmpty(allDishesObject)) {
      return allDishesObject ? [] : null;
    }

    return dishesInShoppingCart.map(dishInCart => {
      const dish = allDishesObject && allDishesObject[dishInCart.dishId];
      const additionalDetails = pick(dish, ['dishName', 'dishPrice', 'dishDescription', 'dishImageUrl']) as Pick<
        Dish,
        'dishName' | 'dishPrice' | 'dishDescription' | 'dishImageUrl'
      >;
      return {
        ...dishInCart,
        ...additionalDetails,
        totalPrice: additionalDetails.dishPrice * dishInCart.quantity,
      };
    });
  },
);

export const selectIsShoppingCartHasAgeRestrictionDishOrSub = createSelector(
  modulesSelectors.selectShoppingCartDishes,
  modulesSelectors.selectDishesWithSubs,
  (dishesInShoppingCart, allDishesObject): boolean => {
    return dishesInShoppingCart.some(dishInCart => {
      if (allDishesObject && allDishesObject[dishInCart.dishId]?.ageRestricted) {
        return true;
      }
      return isDishAgeRestricted(allDishesObject, dishInCart);
    });
  },
);

export const selectIsCurrentAddressIsDinningRoom = createSelector(selectCurrentAddress, currentAddress => {
  const isRemote = isRemoteAddress(currentAddress as Address);
  if (!isRemote) {
    return false;
  }
  const remoteAddress = currentAddress as AddressFromServer;

  return remoteAddress?.shiftId && Boolean(remoteAddress?.shiftId > 0);
});

export const orderToSubmitSelector = createSelector(
  state => state.shoppingCart.shoppingCartGuid,
  modulesSelectors.selectOrderDontWantCutlery,
  modulesSelectors.selectOrderRemarks,
  modulesSelectors.selectDinningRoomNoPackingRequired,
  modulesSelectors.selectCurrentRestaurant,
  (shoppingCartGuid, dontWantCutlery, orderRemarks, dinningRoomNoPackingRequired, currentRestaurant) => ({
    shoppingCartGuid,
    dontWantCutlery,
    orderRemarks,
    ...(currentRestaurant?.showPackingOption ? {noPackingRequired: dinningRoomNoPackingRequired} : {}),
  }),
);

export const isOrderLoadingSelector = createSelector(
  state => state.payments.availablePayments.loading,
  modulesSelectors.selectCurrentOrderLoading,
  modulesSelectors.selectIsShoppingCartDirty,
  modulesSelectors.selectAllCouponsLoading,
  (...loadings) => loadings.some(Boolean),
);

export const selectIsDeliveringToCurrentAddress = createSelector(
  modulesSelectors.selectCurrentRestaurant,
  // isVoucherEnabled is exceptional (check currentRestaurantSelectors:151)
  currentRestaurant =>
    currentRestaurant &&
    (currentRestaurant?.deliveryRules?.some(({isActiveNow}) => isActiveNow) ||
      (currentRestaurant?.availableFutureDatesAndTimes && currentRestaurant?.availableFutureDatesAndTimes?.length > 0)),
);

export const selectIsCurrentRestaurantInRestaurantsList = createSelector(
  modulesSelectors.selectRestaurantsMainListData,
  modulesSelectors.selectCurrentRestaurantId,
  (restaurantsData, currentRestaurantId) => {
    const stringCurrentRestaurantId = String(currentRestaurantId);
    return (
      currentRestaurantId &&
      restaurantsData?.restaurantsList?.some(restaurant => {
        return String(restaurant.restaurantId) === stringCurrentRestaurantId;
      })
    );
  },
);

export const selectDoesCheckoutHasAllNeededData = createSelector(
  modulesSelectors.selectUserData,
  modulesSelectors.selectCurrentRestaurant,
  (userData, currentRestaurant) => {
    if (!userData || !currentRestaurant?.name) {
      // persistant store pushing old restauratId when the data should be null.
      return false;
    }

    return true;
  },
);

export const createSelectorCompanyAddressById = createSelector(
  selectUserAddresses,
  addresses => (addressCompanyIdArg: CompanyAddress['companyAddressId']) =>
    addresses.find(({addressCompanyId}) => addressCompanyId === addressCompanyIdArg),
);

export const selectIsRestaurantFetchedForCurrentAddressKey = createSelector(
  selectCurrentAddress,
  modulesSelectors.selectLastFetchedInfo,
  (currentAddress, lastFetchedInfo) => {
    if (!currentAddress && !lastFetchedInfo) {
      return false;
    }

    if (!currentAddress && lastFetchedInfo && !lastFetchedInfo.fetchedForAddress) {
      return true;
    }

    return lastFetchedInfo?.fetchedForAddress && currentAddress ?
      areAddressesEqual(currentAddress, addressPartsFromKey(lastFetchedInfo.fetchedForAddress), true) :
      false;
  },
);

export const selectIsRestaurantTooFar = createSelector(
  selectCurrentAddress,
  selectCurrentRestaurant,
  selectCurrentDeliveryMethod,
  selectIsRestaurantFromGetReady,
  selectIsRestaurantFetchedForCurrentAddressKey,
  (currentAddress, restaurant, deliveryMethod, isRestaurantFromGetReady, isRestaurantFetchedForCurrentAddressKey) =>
    // The user has added an address
    !!currentAddress &&
    // The restaurant should not be undefined
    restaurant &&
    // The restaurant data should be updated for the current address
    isRestaurantFetchedForCurrentAddressKey &&
    // The restaurant from get should be ready
    isRestaurantFromGetReady &&
    // The method should be Delivery
    deliveryMethod === DeliveryMethods.DELIVERY &&
    // The delivery rules should be an empty list
    !restaurant.deliveryRules?.length &&
    // The restaurant shouldn't have any available future dates and times
    !restaurant.availableFutureDatesAndTimes?.length &&
    // The restaurant's tempClosedReason should be empty
    restaurant.tempClosedReason === '',
);

export const selectIsCurrentRestaurantActiveNow = createSelector(
  selectCurrentAddress,
  selectCurrentRestaurant,
  selectCurrentDeliveryMethod,
  selectIsRestaurantFromGetReady,
  selectIsRestaurantFetchedForCurrentAddressKey,
  (
    currentAddress,
    currentRestaurant,
    deliveryMethod,
    isRestaurantFromGetReady,
    isRestaurantFetchedForCurrentAddressKey,
  ) => {
    // If the restaurant is loaded and has a tempClosedReason, the restaurant is closed now in any case
    if (currentRestaurant && currentRestaurant.tempClosedReason !== '') {
      return false;
    }

    // By default, while the restaurant is being loaded, or the user has no address, it should be active
    if (!isRestaurantFromGetReady || !isRestaurantFetchedForCurrentAddressKey || !currentAddress) {
      return true;
    }

    // For the 'delivery' method i want to be sure that there are only active delivery rules or any available
    // future delivery times

    if (deliveryMethod === DeliveryMethods.DELIVERY) {
      return (
        currentRestaurant?.deliveryRules?.some(({isActiveNow}) => isActiveNow) ||
        (currentRestaurant?.availableFutureDatesAndTimes && currentRestaurant?.availableFutureDatesAndTimes?.length > 0)
      );
    }

    // For the 'pickup' method i want to be sure that there are only active pickup rule

    if (deliveryMethod === DeliveryMethods.PICKUP) {
      return currentRestaurant?.pickupRule?.isActiveNow;
    }

    // For other methods checking all rules and available future delivery times
    // If at least one is available - the restaurant is active now

    return (
      currentRestaurant?.pickupRule?.isActiveNow ||
      currentRestaurant?.deliveryRules?.some(({isActiveNow}) => isActiveNow) ||
      (currentRestaurant?.availableFutureDatesAndTimes && currentRestaurant?.availableFutureDatesAndTimes?.length > 0)
    );
  },
);

export const selectCurrentRestaurantOrderRule = createSelector(
  selectCurrentDeliveryMethod,
  selectCurrentRestaurant,
  selectIsCurrentRestaurantActiveNow,
  selectCurrentOrderDateAndTime,
  selectOrderDeliveryRule,
  (deliveryMethod, currentRestaurant, isCurrentRestaurantActiveNow, orderDateAndTime, orderDeliveryRule) => {
    if (!currentRestaurant) {
      return;
    }

    if (deliveryMethod === DeliveryMethods.PICKUP) {
      return currentRestaurant.pickupRule;
    }

    if (orderDeliveryRule === DELIVERY_RULES.POOL && !isEmpty(currentRestaurant.poolOrder)) {
      return currentRestaurant.deliveryRules.find(rule => rule.type === DELIVERY_RULES.POOL);
    }

    if (orderDateAndTime.orderTime) {
      // In SDFO we can get 2 rules, ASAP and Future with same id, it is necessary first to check which delivery rule
      // type is currently used
      return currentRestaurant.deliveryRules.find(rule => {
        const currentType = isCurrentTimeKeyAsap(orderDateAndTime.orderTime.timeKey) ? DELIVERY_RULES.ASAP : DELIVERY_RULES.FUTURE;
        return rule.id === orderDateAndTime.orderTime.ruleId && rule.type === currentType;
      });
    }

    return (
      currentRestaurant.deliveryRules?.find(rule => rule.isActiveNow && rule.type === DELIVERY_RULES.ASAP) ||
      currentRestaurant.deliveryRules?.[0]
    );
  },
);

export const selectIsAnyFilterSelected = createSelector(
  modulesSelectors.selectSelectedFilters,
  selectSortOptionAccordingToDeliveryMethod,
  selectDefaultSortOption,
  (restaurantFilters, sortBy, defaultSortBy) => {
    const {
      cuisines,
      isVegan,
      isGlutenFree,
      freeDelivery,
      isScoober,
      isEnvironmentFriendly,
      newRestaurants,
      isKosher,
      isNotKosher,
      discountCoupon,
      showStores,
    } = restaurantFilters;

    return Object.values(cuisines || {}).find(v => v) ||
    sortBy !== defaultSortBy ||
    isKosher ||
    isNotKosher ||
    isVegan ||
    isGlutenFree ||
    freeDelivery ||
    isScoober ||
    isEnvironmentFriendly ||
    newRestaurants ||
    discountCoupon ||
    showStores;
  },
);

export const selectCarouselsGroups = createSelector(
  selectCollectionsList,
  selectMainFilteredRestaurantsData,
  selectRestaurantsSortBy,
  modulesSelectors.selectIsMinDesktop,
  makeCarouselGroups,
);

export const selectCarouselGroupsForGroupViewPage = createSelector(
  selectCarouselsGroups,
  modulesSelectors.selectVirtualizedListColumnsNumberByScreenSize,
  modulesSelectors.selectIsMinLargeMobile,
  modulesSelectors.selectRestaurantsMainListData,
  (
    carouselGroups,
    numberOfColumns,
    isMinLargeMobile,
    restaurantsListData,
  ) => {
    return carouselGroups.reduce((chunks, group) => {
      const itemChunks = chunk(group.items, numberOfColumns);
  
      const {title, groupId, iconUrl, subtitle} = group;
  
      chunks[group.groupId] = [
        { // title chunck
          groupId,
          title,
          isGroup: true,
          subtitle,
          iconUrl,
          height: getGroupItemRootHeight({
            hasSubheader: Boolean(restaurantsListData.poolTimeStart),
            isCustomGroup: true,
            isMinLargeMobile,
          }),
        },
        ...itemChunks.map(item => ({
          ...group,
          items: item,
          height: RESTAURANTS_ROW_HEIGHT + SPACE_BETWEEN_ROWS + SPACE_BETWEEN_ROWS,
          isCarousel: false,
        })),
      ];
  
      return chunks;
    }, {} as Record<Carousel['name'], any>);
  },
);

// address key in redux changes before the new restaurants list is fetched from api
// this function saves previous address key and updates it only when the new list is fetched
const memoizeAddressKeyForTheCurrentRestaurantsList = () => {
  let savedKey: string | null = null;
  let shouldReturnOldKey = false;

  return (isResLoaded: boolean, isResLoading: boolean, addressKey: string) => {
    if (isResLoading) {
      shouldReturnOldKey = false;
    }

    if (isResLoaded && !isResLoading && !shouldReturnOldKey) {
      savedKey = addressKey;
      shouldReturnOldKey = true;
    }

    return savedKey || '';
  };
};

const getMemoizedKeyForTheCurrentRestaurantsList = memoizeAddressKeyForTheCurrentRestaurantsList();

export const selectAddressKeyForTheCurrentRestaurantsList = createSelector(
  modulesSelectors.selectRestaurantsMainListIsLoaded,
  modulesSelectors.selectRestaurantsMainListIsLoading,
  selectCurrentAddressKey,
  getMemoizedKeyForTheCurrentRestaurantsList,
);

const recalculateCuisinesCount = ({groupedRestaurants, cuisines, currentCollectionName, carouselsGroups}: {
  groupedRestaurants: ReturnType<typeof selectGroupedRestaurantsForCuisines>;
  cuisines: Cuisine[];
  currentCollectionName?: Carousel['name'] | null;
  carouselsGroups: ReturnType<typeof selectCarouselsGroups>;
}) => {
  if (!groupedRestaurants.length) {
    return [];
  }

  let restaurantsWithoutCuisine: RestaurantFromSearch[] = [];

  if (currentCollectionName) {
    restaurantsWithoutCuisine = carouselsGroups?.find(({groupId}) => String(groupId) === currentCollectionName)?.items
      .map(res => res.restaurantItem) as RestaurantFromSearch[] || [];
  } else {
    const filteredRestaurants = filteredRestaurantGroupsSelector(groupedRestaurants);

    restaurantsWithoutCuisine = flatMap<FilteredRestaurantGroup, FilteredRestaurantsGroupItem['item']>(
      filteredRestaurants,
      item => ('isGroup' in item ? [] : item.item),
    );
  }

  const allTypes = restaurantsWithoutCuisine.reduce((result, {restaurantCuisineKeysList}) => {
    return result.concat(restaurantCuisineKeysList.map(cuisineName => cuisineName.toLowerCase()) || []);
  }, [] as string[]);

  const typesCounts = countBy(allTypes);

  const cuisinesWithCount = cuisines.map(indexedCuisine => ({
    ...indexedCuisine,
    count: typesCounts[indexedCuisine.id] || 0,
  }));

  return cuisinesWithCount;
};

export const selectMainRestaurantsCuisinesCounts = createSelector(
  selectGroupedRestaurantsForCuisines,
  modulesSelectors.selectCurrentCollectionName,
  modulesSelectors.selectCuisinesData,
  selectCarouselsGroups,
  (
    groupedRestaurants,
    currentCollectionName,
    cuisines,
    carouselsGroups,
  ) => recalculateCuisinesCount({
    groupedRestaurants,
    cuisines,
    currentCollectionName,
    carouselsGroups,
  }),
);

export const selectMainRestaurantsAvailableCuisines = createSelector(
  selectMainRestaurantsCuisinesCounts,
  cuisines => cuisines.filter(({count}) => !!count),
);

const NOW = moment();

export const selectCheckoutErrors = createSelector(
  selectCurrentRestaurant,
  modulesSelectors.selectShoppingCartBillingLines,
  modulesSelectors.selectUserData,
  selectCurrentDeliveryMethod,
  modulesSelectors.selectCurrentCoupon,
  selectDishesAdditionalDetails,
  selectIsDeliveringToCurrentAddress,
  modulesSelectors.selectOrderPermit,
  selectIsCurrentRestaurantActiveNow,
  selectCurrentRestaurantOrderRule,
  (
    restaurant,
    billingLines,
    userData,
    deliveryMethod,
    currentCoupon,
    dishes,
    isDeliveringToCurrentAddress,
    permit,
    isCurrentRestaurantActiveNow,
    restaurantRule): {errorMessageCodes: string[]; showServiceRepresentativeLink?: boolean} | undefined | null => {
    const showServiceRepresentativeLink = Boolean(userData?.isCompanyUser && Boolean(restaurant?.deliveryRules?.length));

    if (!restaurant || !billingLines || !deliveryMethod || !dishes) {
      // eslint-disable-next-line no-console
      console.error('Unexpected Error - not all necessary arguments where passed checkForOrderErrors', {
        restaurant,
        billingLines,
        deliveryMethod,
        dishes,
      });
      return {
        errorMessageCodes: ['unexpected_error'],
        showServiceRepresentativeLink,
      };
    }
    const {tempClosedReason, pickupRule, deliveryRules} = restaurant;
    const isTimeLimitPermitted = permit?.timeLimit?.active && moment(permit?.timeLimit?.validTill).isSameOrAfter(NOW);

    const isRestaurantFutureOrderOnly = getRestaurantStatus(restaurant) === RESTAURANT_STATUS_MAP.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW;
    const isPickupAndResDosntSupportPickup = deliveryMethod === DeliveryMethods.PICKUP && !pickupRule?.isActiveNow;

    if (
      (tempClosedReason && !isTimeLimitPermitted) ||
      isPickupAndResDosntSupportPickup ||
    (!isCurrentRestaurantActiveNow &&
      deliveryRules?.length &&
      !isTimeLimitPermitted &&
      !isRestaurantFutureOrderOnly)
    ) {
      return {
        errorMessageCodes: ['the_restaurant_does_not_accept_orders_atm'],
        showServiceRepresentativeLink,
      };
    }

    if (!isDeliveringToCurrentAddress && !isTimeLimitPermitted && deliveryMethod === DeliveryMethods.DELIVERY) {
      return {
        errorMessageCodes: ['the_restaurant_does_not_deliver_to_this_address'],
        showServiceRepresentativeLink,
      };
    }

    if (isDeliveryRuleModel(restaurantRule) && restaurantRule?.type === DELIVERY_RULES.POOL) {
      return;
    }

    let calculatedTotalToCharge = billingLines.find(({type}) => type === 'TotalToCharge')?.amount || 0;
    calculatedTotalToCharge -= billingLines.find(({type}) => type === 'DeliveryCharge')?.amount || 0;
    calculatedTotalToCharge += Math.abs(billingLines?.find(({type}) => type === 'DeliveryDiscount')?.amount || 0);

    const minimumAmountShouldIncludeCoupon = currentCoupon?.benefitPayer !== 'TenBis';
    const discountCouponAmount = Math.abs(billingLines.find(({type}) => type === 'DiscountCoupon')?.amount || 0);

    calculatedTotalToCharge +=
    (!isEmpty(currentCoupon?.code) && !minimumAmountShouldIncludeCoupon && discountCouponAmount) || 0;

    const isMinimumLimitPermitted = permit?.minimumLimit?.active && moment(permit?.minimumLimit?.validTill).isSameOrAfter(NOW);

    const minimumOrder = restaurantRule?.minimumOrder || 0;

    if (calculatedTotalToCharge < minimumOrder && !isMinimumLimitPermitted && dishes.length > 0) {
      return {
        errorMessageCodes: ['your_order_is_not_over_the_required_minimum', 'delivery_fee_not_part_of_the_minimum_order'],
        showServiceRepresentativeLink,
      };
    }
    return null;
  });

export const selectNumerOfItemsInRow = createSelector(
  modulesSelectors.selectIsGroceriesStore,
  modulesSelectors.selectIsMinLargeMobile,
  modulesSelectors.selectIsMinLargeTablet,
  modulesSelectors.selectIsMinLargeDesktop,
  (isGroceriesStore, isMinLargeMobile, isMinLargeTablet, isMinLargeDesktop) => {
    const key = isGroceriesStore ? RestaurantBusinessTypeName.GroceryStore : 'default';

    if (isMinLargeDesktop) {
      return ITEMS_IN_ROW[key].isMinLargeDesktop;
    }

    if (isMinLargeTablet) {
      return ITEMS_IN_ROW[key].isMinLargeTablet;
    }

    if (isMinLargeMobile) {
      return ITEMS_IN_ROW[key].isMinLargeMobile;
    }

    return ITEMS_IN_ROW[key].default;
  },
);

export const selectReorderBoxHeight = createSelector(
  modulesSelectors.selectIsGroceriesStore,
  modulesSelectors.selectLast4ordersWithExistingDishes,
  modulesSelectors.selectIsMinTablet,
  modulesSelectors.selectIsMinLargeTablet,
  (isGroceriesStore, last4Orders, isMinTablet, isMinLargeTablet) => {
    const hasNoReorderOption = isGroceriesStore || !last4Orders?.length;
    if (hasNoReorderOption) {
      return 0;
    }

    const tabletHeight = REORDER_TABLET_TITLE_HEIGHT +
      ((CARDS_HEIGHT.default.default + REORDER_TABLET_CARD_BOTTOM_MARGIN) * last4Orders?.length || 0) +
      REORDER_TABLET_BOTTOM_PADDING;

    return isMinTablet && !isMinLargeTablet ? tabletHeight : REORDER_DEFAULT_WRAPPER_HEIGHT;
  });

const categoriesWithHeights = (data: MenuCategory[], numerOfItemsInRow: number, isGroceriesStore: boolean, isMinLargeDesktop: boolean, isMinLargeTablet: boolean, isMinLargeMobile: boolean) => data.map(category => {
  const businessType = isGroceriesStore ? RestaurantBusinessTypeName.GroceryStore : 'default';
  const breakPoint = (() => {
    if (isMinLargeDesktop) return 'isMinLargeDesktop';
    if (isMinLargeTablet) return 'isMinLargeTablet';
    if (isMinLargeMobile) return 'isMinLargeMobile';
    return 'default';
  })();

  const cardHeight = CARDS_HEIGHT[businessType][breakPoint];
  const cardTopSpacing = CARDS_TOP_SPACING[businessType][breakPoint];

  const rowMarginTop = isGroceriesStore ? ROW_MARGIN_TOP_GROCERIES : ROW_MARGIN_TOP_DEFAULT;

  const rowTitleHeight = ROW_TITLE_HEIGHT +
    TITLE_TOP_PADDINGS[businessType][breakPoint] +
    TITLE_BOTTOM_PADDINGS[businessType][breakPoint];

  const rowDescriptionHeight = (() => {
    // calculating dynamic height for groceries store description box
    if (category.categoryDesc) {
      const maxLines = breakPoint === 'default' ? 4 : 2;

      const estimatedLines = Math.ceil(category.categoryDesc.length / DESCRIPTION_MAX_LETTERS_IN_ONE_LINE[breakPoint]);

      const allowedLines = estimatedLines < maxLines ? estimatedLines : maxLines;

      return (ROW_DESCRIPTION_HEIGHT * allowedLines) +
        DESCRIPTION_BOX_BOTTOM_PADDING[businessType][breakPoint];
    }

    // default height for empty description
    return ROW_EMPTY_DESCRIPTION_HEIGHT;
  })();

  const cardHeightWithSpacing = cardHeight + cardTopSpacing;
  const rowsNumber = (() => {
    if (isGroceriesStore) return Math.ceil(category.dishList.length / numerOfItemsInRow);

    const dishesByImage = category.dishList.reduce<{withImage: number; withoutImage: number}>((acc, dish) => {
      if (dish.dishImageUrl) acc.withImage++;
      if (!dish.dishImageUrl) acc.withoutImage++;
      return acc;
    }, {withImage: 0, withoutImage: 0});
    return Math.ceil(dishesByImage.withImage / numerOfItemsInRow) +
      Math.ceil(dishesByImage.withoutImage / numerOfItemsInRow);
  })();

  const height = (cardHeightWithSpacing * rowsNumber) +
    rowTitleHeight +
    rowMarginTop +
    rowDescriptionHeight;

  return {
    ...category,
    height,
  };
});

export const selectFilteredCategoriesListWithHeight = createSelector(
  modulesSelectors.selectFilteredCategoriesListData,
  selectNumerOfItemsInRow,
  modulesSelectors.selectIsGroceriesStore,
  modulesSelectors.selectIsMinLargeDesktop,
  modulesSelectors.selectIsMinLargeTablet,
  modulesSelectors.selectIsMinLargeMobile,
  categoriesWithHeights,
);

export const selectCategoriesListWithHeight = createSelector(
  modulesSelectors.selectCategoriesListOfCurrentRestaurant,
  selectNumerOfItemsInRow,
  modulesSelectors.selectIsGroceriesStore,
  modulesSelectors.selectIsMinLargeDesktop,
  modulesSelectors.selectIsMinLargeTablet,
  modulesSelectors.selectIsMinLargeMobile,
  categoriesWithHeights,
);

export enum ShoppingCartPageValidation {
  VALID= 'VALID',
  NAV_TO_DEFAULT_START_PAGE = 'NAV_TO_DEFAULT_START_PAGE',
  NAV_TO_MENU_OR_DISH = 'NAV_TO_MENU_OR_DISH',
}

export const selectIsShoppingCartPageValid = createSelector(
  modulesSelectors.selectIsMinLargeMobile,
  modulesSelectors.selectCurrentRestaurant,
  modulesSelectors.selectShoppingCartDishes,
  (isMinLargeMobile, currentRestaurant, shoppingCartDished) => {
    if (!currentRestaurant) {
      return {
        restaurantId: 0,
        result: ShoppingCartPageValidation.NAV_TO_DEFAULT_START_PAGE,
      };
    }

    if (
      isMinLargeMobile ||
        (!shoppingCartDished.length)
    ) {
      return {
        restaurantId: currentRestaurant.id,
        result: ShoppingCartPageValidation.NAV_TO_MENU_OR_DISH,
      };
    }

    return {
      restaurantId: currentRestaurant.id,
      result: ShoppingCartPageValidation.VALID,
    };
  },
);

export const selectCurrentRestaurantActiveRules = createSelector(
  selectCurrentRestaurant,
  selectIsRestaurantFromGetReady,
  selectCurrentAddress,
  modulesSelectors.selectLastFetchedInfo,
  (currentRestaurant, isRestaurantFromGetReady, address, lastFetchedInfo): ActiveRulesData => {
    if (!currentRestaurant || !isRestaurantFromGetReady || (
      !!address &&
      !areAddressesEqual(address, addressPartsFromKey(lastFetchedInfo?.fetchedForAddress), true)
    )) {
      return EMPTY_RULES_DATA;
    }

    return getCurrentRestaurantActiveRules(currentRestaurant, address);
  },
);

export const selectCurrentRestaurantAvailableDeliveryRules = createSelector(
  selectCurrentRestaurantActiveRules,
  modulesSelectors.selectCurrentRestaurantShoppingCartDeliveryType,
  (activeRules, shoppingCartDeliveryType) => {
    if (!shoppingCartDeliveryType) {
      return activeRules;
    }

    return getAvailableDeliveryRules(activeRules, shoppingCartDeliveryType);
  },
);

export const selectIsInitialOrderForCurrentRes = createSelector(
  selectInitialOrderData,
  selectCurrentRestaurant,
  selectCurrentRestaurantActiveRules,
  selectCurrentRestaurantShoppingCartDeliveryType,
  (initialOrderData, currentRes, activeRules, shoppingCartDeliveryType) => {
    if (
      !initialOrderData ||
      !currentRes
    ) {
      return true;
    }

    if (
      activeRules.Asap ||
      activeRules.Pool ||
      !activeRules.Future ||
      (shoppingCartDeliveryType === SHOPPING_CART_DELIVERY_TYPE.SDFO && currentRes?.futureDeliveryDefaultTime)
    ) {
      return false;
    }

    return initialOrderData.restaurantId === currentRes.id ? initialOrderData.isInitialOrder : true;
  },
);

export const selectIsCuisineStripRenderAllowed = createSelector(
  modulesSelectors.selectIsMobileDevice,
  modulesSelectors.selectIsTabletDevice,
  modulesSelectors.selectIsMinLargeTablet,
  (isMobileDevice, isTabletDevice, isMinLargeTablet) => {
    return (isMobileDevice || isTabletDevice) && !isMinLargeTablet;
  },
);

const MINIMUM_CUISINES_TO_RENDER = 4;
const MINIMUM_RESTAURANTS_IN_SEARCH_TO_RENDER = 2;

export const selectIsCuisineQueryEnabled = createSelector(
  selectIsCuisineStripRenderAllowed,
  modulesSelectors.selectRestaurantsMainListIsReady,
  selectMainRestaurantsAvailableCuisines,
  modulesSelectors.selectRestaurantsMainList,
  modulesSelectors.selectRestaurantsGroceryStoresAvailable,
  (isCuisineStripRenderAllowed, isReady, cuisines, restaurants, groceryStoresAvailable) => {
    // debugger;
    if (!isCuisineStripRenderAllowed) {
      return false;
    }

    if (!isReady) {
      return true;
    }

    const cuisineTypesCount = cuisines.length + (groceryStoresAvailable ? 1 : 0);

    return restaurants.length >= MINIMUM_RESTAURANTS_IN_SEARCH_TO_RENDER &&
      cuisineTypesCount >= MINIMUM_CUISINES_TO_RENDER;
  },
);

export const selectIsShowStoresQueryEnabled = createSelector(
  selectIsCuisineStripRenderAllowed,
  modulesSelectors.selectRestaurantsMainListIsReady,
  modulesSelectors.selectRestaurantsMainList,
  modulesSelectors.selectRestaurantsGroceryStoresAvailable,
  (isCuisineStripRenderAllowed, isReady, restaurants, groceryStoresAvailable) => {
    if (!isCuisineStripRenderAllowed) {
      return false;
    }

    if (!isReady) {
      return true;
    }

    return restaurants.length >= MINIMUM_RESTAURANTS_IN_SEARCH_TO_RENDER && groceryStoresAvailable;
  },
);

export const selectShoppingCartBillingLinesWithDiscounts = createSelector(
  selectShoppingCartBillingLines,
  modulesSelectors.selectCurrentCoupon,
  (billingLines, coupon): BillingLineWithDiscountsData[] => {
    const deliveryDiscount = billingLines.find(({type}) => type === BillingLineType.DeliveryDiscount);

    return billingLines.reduce<BillingLineWithDiscountsData[]>((finalBillingLines, billingLine) => {
      if (coupon?.valueType !== 'Percent') {
        return [...finalBillingLines, {
          ...billingLine,
          discountsData: null,
        }];
      }

      const deliveryFeeDiscountsData = (() => {
        if (billingLine.type !== BillingLineType.DeliveryFee) {
          return null;
        }

        if (deliveryDiscount) {
          return {
            price: billingLine.amount,
            priceAfterDiscount: billingLine.amount + deliveryDiscount.amount,
          };
        }

        if (coupon?.assignmentType === CouponAssignmentType.TOTAL) {
          return getDishPriceWithDiscount(billingLine.amount, coupon);
        }
      })();

      const shouldSkipDiscountsData = BILLING_LINES_WITHOUT_DISCOUNTS_DATA.includes(billingLine.type);

      return [...finalBillingLines, {
        ...billingLine,
        discountsData: deliveryFeeDiscountsData || (!shouldSkipDiscountsData ?
          getDishPriceWithDiscount(billingLine.amount, coupon) :
          null),
      }];
    }, []);
  },
);
