const DEFAULT_START_TIME = '00:00';
const DEFAULT_END_TIME = '23:59';

const regexNumbersOnlyAtLeastOne = new RegExp(/^[0-9]+$/); // at least one number, numbers only

export const TIP_BUTTONS = [
  {amount: 10, isPercentage: false},
  {amount: 15, isPercentage: false},
  {amount: 10, isPercentage: true},
  {amount: 15, isPercentage: true},
];

export enum FastCheckoutSteps {
  LOADING = 'loading',
  LOGIN = 'login',
  SELECT_ADDRESS = 'selectAddress',
  ADD_CREDIT_CARD = 'addCreditCard',
  CHECKOUT = 'checkout',
  AVAILABLE_PAYMENTS = 'availablePayments',
  LINK_MONEY_CARD = 'linkMoneycard',
}

export enum DiscountCouponsValueTypes {
  Percent = '%',
  Amount = '₪',
}

export enum MoneyCardActivationFormType {
  Default = 'Default',
  Maccabi = 'Maccabi',
}

export enum AddCreditCardModes {
  ONE_LINE = 'oneLine',
  BIG = 'big',
}

export enum BillingLineType {
  Tip = 'Tip',
  SubTotal = 'SubTotal',
  DeliveryFee = 'DeliveryCharge',
  DiscountCoupon = 'DiscountCoupon',
  TotalToCharge = 'TotalToCharge',
  DeliveryDiscount = 'DeliveryDiscount',
}

export const NumericFormats = {
  hideZeroDecimals: '0.[00]',
  showZeroDecimals: '0.00',
};

export enum TipButtonValueTypes {
  AMOUNT = 'amount',
  PERCENT = 'percent',
}

export const PaymentsRemarksPatches: {
  default: PaymentRemarkPatchBase;
  companyIds: Record<number, PaymentRemarkPatch>;
  addressCompanyIds: Record<number, PaymentRemarkPatch>;
} = {
  default: {
    startTime: DEFAULT_START_TIME,
    endTime: DEFAULT_END_TIME,
  },
  companyIds: {
    6269: {
      // harel company
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
      inputValidationRegex: regexNumbersOnlyAtLeastOne,
      customErrorMessage: 'payments_remark_harel_custom_error',
    },
    28: {
      startTime: '18:00',
      endTime: DEFAULT_END_TIME,
    },
    2410: {
      startTime: '20:30',
      endTime: DEFAULT_END_TIME,
    },
    2517: {
      startTime: '20:30',
      endTime: DEFAULT_END_TIME,
    },
    2735: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    1697: {
      startTime: '08:00',
      endTime: DEFAULT_END_TIME,
    },
    3053: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    3135: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    3163: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    3813: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    3869: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    4013: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
      prefix: 'IL',
      maxLength: 8,
    },
    4203: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    6432: {
      startTime: '20:00',
      endTime: DEFAULT_END_TIME,
    },
  },
  addressCompanyIds: {
    5487: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    5235: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    5114: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
    3995: {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
    },
  },
};
export interface PaymentRemarkPatch extends PaymentRemarkPatchBase {
  inputValidationRegex?: RegExp;
  customErrorMessage?: string;
  customInputPlaceholder?: string;
  prefix?: string;
  maxLength?: number;
}
interface PaymentRemarkPatchBase {
  startTime: string;
  endTime: string;
}

export enum LinkMoneycardUserIdentifiers {
  EMAIL = 'Email',
  PHONE = 'CellPhone',
}

export enum PaymentRequestStatusTypes {
  REQUESTED = 'Requested',
  APPROVED = 'Approved',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
  EXPIRED = 'Expired',
}

export enum LinkMoneycardErrorCodes {
  LINK_MC_INVALID_USER = 'LinkMcToUser_InvalidUserId',
  LINK_MC_REQUEST_EXPIRED = 'LinkMcToUser_RequestExpired',
}
