/* eslint-disable max-len */
/* stylelint-disable max-line-length */
import {memo} from 'react';

import styled from 'styled-components';

import {media} from '~/shared/theme/media';
import {getLocalizationService} from '~/shared/services/localisationService';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR, rotateOnLTR} from '~/shared/theme/utils';
import scooberLogoIcon from '~/assets/images/scoober-logo-icon.svg';
import {body14Bold, body18Bold} from '~/shared/theme/typography';

const ScooberLogoIcon = styled(ImageWithAlt).attrs({noAlt: true})`
  height: 32px;
  width: 34px;
  margin-top: 3px;
`;

const ScooberLogoIconForMenuPage = styled(ImageWithAlt).attrs({noAlt: true})`
  ${media.minMobile`
    height: 35px;
    width: 35px;
  `}

  ${media.minTablet`
    height: 50px;
    width: 45px;
  `}
`;

const ScooberWrapper = styled.div.attrs({className: 'transparent-bg-on-contrast'})`
  position: absolute;
  top: 100px;
  text-align: center;
  z-index: 80;
  ${flipOnLTR`
    left: 20px;
  `}
`;

const ScooberWrapperForMenuPage = styled(ScooberWrapper)`
  top: 138px;
  ${media.minMobile`
    top: 138px;
  `}
  ${media.minLargeMobile`
    top: 268px;
  `}
  ${media.minTablet`
    top: 256px;
  `}
`;

const ScooberText = styled.div`
  ${body14Bold};
  color: ${({theme}) => theme.colors.primary};
`;

const ScooberTextForMenuPage = styled(ScooberText)`
  ${media.minMobile`
    ${body14Bold};
  `}
  ${media.minTablet`
    ${body18Bold};
  `}
`;

const ScooberLogo = styled.div`
  width: 90px;
  height: 30px;
  background: ${({theme}) => theme.colors.primaryText};
  clip-path: path(
    'M90 32H0C7.95178 31.624 12.5641 28.3286 16.0109 23.9854C17.6519 21.8422 19.0743 19.4435 20.5197 17.0146L20.6999 16.7087L20.953 16.2656C25.5883 8.1749 30.2658 0 44.6013 0L45 0.00201613L45.3987 0C59.7342 0 64.4117 8.1749 69.047 16.2656L69.3001 16.7092L69.4803 17.0146C70.9257 19.4435 72.3481 21.8417 73.9891 23.9849C77.4359 28.3286 82.0482 31.624 90 32Z'
  );
  ${rotateOnLTR}
`;

const ScooberLogoForMenuPage = styled(ScooberLogo)`
  ${media.minMobile`
    width: 90px;
    height: 30px;
    clip-path: path(
      'M90 32H0C7.95178 31.624 12.5641 28.3286 16.0109 23.9854C17.6519 21.8422 19.0743 19.4435 20.5197 17.0146L20.6999 16.7087L20.953 16.2656C25.5883 8.1749 30.2658 0 44.6013 0L45 0.00201613L45.3987 0C59.7342 0 64.4117 8.1749 69.047 16.2656L69.3001 16.7092L69.4803 17.0146C70.9257 19.4435 72.3481 21.8417 73.9891 23.9849C77.4359 28.3286 82.0482 31.624 90 32Z'
    );
  `}
  ${media.minLargeMobile`
    width: 90px;
    height: 30px;
    clip-path: path(
      'M90 32H0C7.95178 31.624 12.5641 28.3286 16.0109 23.9854C17.6519 21.8422 19.0743 19.4435 20.5197 17.0146L20.6999 16.7087L20.953 16.2656C25.5883 8.1749 30.2658 0 44.6013 0L45 0.00201613L45.3987 0C59.7342 0 64.4117 8.1749 69.047 16.2656L69.3001 16.7092L69.4803 17.0146C70.9257 19.4435 72.3481 21.8417 73.9891 23.9849C77.4359 28.3286 82.0482 31.624 90 32Z'
    );
  `}
  ${media.minTablet`
    width: 120px;
    height: 42px;
    clip-path: path(
      'M120 42.6667H0C10.6024 42.1653 16.7522 37.7715 21.3479 31.9805C23.5358 29.123 25.4324 25.9247 27.3596 22.6862L27.5999 22.2782L27.9373 21.6875C34.1177 10.8999 40.3544 0 59.4683 0L60 0.00268817L60.5317 0C79.6456 0 85.8823 10.8999 92.0627 21.6875L92.4001 22.2789L92.6404 22.6862C94.5676 25.9247 96.4642 29.1223 98.6521 31.9798C103.248 37.7715 109.398 42.1653 120 42.6667Z'
    );
  `}
`;

const DiagonalBadge = memo(() => {
  const {t} = getLocalizationService();
  return (
    <ScooberWrapperForMenuPage>
      <ScooberLogoForMenuPage>
        <ScooberLogoIconForMenuPage src={scooberLogoIcon} />
      </ScooberLogoForMenuPage>
      <ScooberTextForMenuPage>{t('scoober_filter_label')}</ScooberTextForMenuPage>
    </ScooberWrapperForMenuPage>
  );
});

const RestaurantCardBadge = memo(() => {
  const {t} = getLocalizationService();
  return (
    <ScooberWrapper>
      <ScooberLogo>
        <ScooberLogoIcon src={scooberLogoIcon} />
      </ScooberLogo>
      <ScooberText>{t('scoober_filter_label')}</ScooberText>
    </ScooberWrapper>
  );
});

const ScooberBadge = ({top}: {top?: boolean}) => (top ? <DiagonalBadge /> : <RestaurantCardBadge />);

export default memo(ScooberBadge);
