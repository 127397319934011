import {pick} from 'lodash';

import {PERSISTENT_STORE_LOCAL_STORAGE_KEY, LocalStorageKeys} from '~/shared/consts/localStorageConsts';

export const clearReleventStorageData = () => {
  const currentLocalStorageValue = JSON.parse(localStorage.getItem(PERSISTENT_STORE_LOCAL_STORAGE_KEY) as string);

  const a11yLocalStorage = pick(currentLocalStorageValue, 'a11y');
  const ActiveOrderDismissedBanners = JSON.parse(
    localStorage.getItem(LocalStorageKeys.DISMISSED_ACTIVE_ORDERS) as string,
  );
  localStorage.clear();

  localStorage.setItem(PERSISTENT_STORE_LOCAL_STORAGE_KEY, JSON.stringify(a11yLocalStorage));
  localStorage.setItem(LocalStorageKeys.DISMISSED_ACTIVE_ORDERS, JSON.stringify(ActiveOrderDismissedBanners));
};
