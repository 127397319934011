import {memo, useMemo} from 'react';

import styled from 'styled-components';

import {Padder} from '~/shared/components/styled';
import {media} from '~/shared/theme/utils';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import {flexColumn} from '~/shared/theme/FlexLayout';

import OtlRuleLimitations from './OtlRuleLimitations';
import OtlCreditCardList from './OtlCreditCardList';

const Root = styled.div`
  ${flexColumn};
  justify-content: space-between;
  margin-top: 10px;
  ${media.minMobile`
    margin-top: 0;
  `}
`;

const OtlRuleSettings = ({addCreditCard, selectedCreditCard, setSelectedCreditCard, onClose, otlData, onSuccess}) => {
  const {otlRuleData, userCreditcardList} = otlData;

  const creditCardList = useMemo(
    () =>
      userCreditcardList.map(({creditcardId, ownerName, creditcardSuffix, creditCardType}) => ({
        creditcardId,
        creditCardType,
        userName: ownerName,
        cardLastDigits: creditcardSuffix,
        paymentMethod: PaymentMethodTypes.CREDIT_CARD.toLowerCase(),
      })),
    [userCreditcardList],
  );

  return (
    <LayoutWithHeader onClick={onClose} titleKey="otl_assign_credit_card" showBackArrow>
      <Padder>
        <Root>
          <OtlCreditCardList {...{addCreditCard, creditCardList, selectedCreditCard, setSelectedCreditCard}} />
          <OtlRuleLimitations {...{otlRuleData, selectedCreditCard, onSuccess}} />
        </Root>
      </Padder>
    </LayoutWithHeader>
  );
};
export default memo(OtlRuleSettings);
