import {memo} from 'react';

import styled from 'styled-components';
import {Form} from 'react-final-form';
import {isNumber, toNumber} from 'lodash';

import {getLocalizationService} from '~/shared/services/localisationService';
import {flipOnLTR} from '~/shared/theme/utils';
import {LongButton} from '~/shared/components/Modals/InfoModals';
import FormField from '~/shared/components/FormField';
import Button from '~/shared/components/Button';
import {trackEvent} from '~/shared/services/analytics';
import {InputWrapper, ErrorText, FieldWrapper, ResendResError} from '~/shared/components/loginStyled';
import useCanResendCode from '~/shared/utils/useCanResendCode';
import {flexColumn} from '~/shared/theme/FlexLayout';

const PhoneVerificationRoot = styled.div`
  width: 100%;
  ${flexColumn};
  flex: 1 0 auto;
  ${flipOnLTR`
    text-align: right;
  `};
`;
const FormContentRoot = styled.div`
  ${flexColumn};
`;

const Title = styled.div`
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
`;

const PhoneNumber = styled.div`
  font-weight: 300;
  text-align: center;
`;

const AdditionButtons = styled.div`
  margin-top: 120px;
  ${flexColumn};
`;

const FormWrapper = styled.form``;

const StyledButton = styled(Button)`
  text-align: center;
  text-decoration: underline;
  font-size: 13px;
  line-height: 30px;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  cursor: pointer;

  &&& {
    ${({isDisabled, theme}) =>
    isDisabled &&
      `
      color: ${theme.colors.gray500};
      background: transparent;
    `}
  }
`;

const validate = fields => {
  const errors = {};

  if (
    !fields.authenticationCode ||
    fields.authenticationCode.length !== 5 ||
    !isNumber(toNumber(fields.authenticationCode))
  ) {
    errors.authenticationCode = 'activation_code_must_be_exact_5_digits';
  }
  return errors;
};

const PhoneVerificationSection = ({
  onSubmit,
  signUpCredentials,
  authenticationToken,
  onCancel,
  onSendActivationCode,
  activationCodeError: fatherActivationCodeError,
  sendActivationCodeError,
}) => {
  const {t} = getLocalizationService();
  const {isResendButtonDisabled, setIsResendButtonDisabled} = useCanResendCode();

  const onSubmitInner = ({authenticationCode}) => {
    onSubmit({
      ...signUpCredentials,
      authenticationToken,
      authenticationCode,
    });
  };

  const onResendActivationCodeClick = () => {
    if (isResendButtonDisabled) {
      return;
    }

    setIsResendButtonDisabled(true);
    onSendActivationCode(signUpCredentials);
  };

  function sendTrackOnSubmitClick() {
    trackEvent('hasClickedLogin', {linkType: 'Registration - SMS Verification'});
  }

  return (
    <PhoneVerificationRoot>
      <Title>{t('you_should_receive_a_message_with_a_code_to_the_number')}</Title>
      <PhoneNumber>{signUpCredentials.cellPhone}</PhoneNumber>
      <Form
        onSubmit={onSubmitInner}
        validate={validate}
        render={({handleSubmit, errors, pristine, active}) => {
          const activationCodeError = !pristine && !active && (errors.authenticationCode || fatherActivationCodeError);
          return (
            <FormWrapper onSubmit={handleSubmit}>
              <FormContentRoot>
                <FieldWrapper>
                  <InputWrapper>
                    <FormField
                      name="authenticationCode"
                      required
                      type="text"
                      shouldShowError={false}
                      placeholder={t('enter_your_verification_code')}
                    />
                    {activationCodeError && <ErrorText>{t(activationCodeError)}</ErrorText>}
                  </InputWrapper>
                </FieldWrapper>
                <LongButton darkMode type="submit" onClick={() => sendTrackOnSubmitClick()}>
                  {t('confirm')}
                </LongButton>
              </FormContentRoot>
            </FormWrapper>
          );
        }}
      />
      <AdditionButtons>
        <StyledButton onClick={onCancel}>{t('update_phone_number')}</StyledButton>
        <StyledButton onClick={onResendActivationCodeClick} isDisabled={isResendButtonDisabled}>
          {t('i_did_not_receive_send_again')}
        </StyledButton>
        {sendActivationCodeError && <ResendResError>{sendActivationCodeError}</ResendResError>}
      </AdditionButtons>
    </PhoneVerificationRoot>
  );
};

export default memo(PhoneVerificationSection);
