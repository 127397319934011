import {useState} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import Button from '~/shared/components/Button';
import {CloseButton} from '~/shared/components/Modals';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenterHorizontally, FlexCenterVertically} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {body14Bold, body16Bold, body18Bold} from '~/shared/theme/typography';
import {CloseButtonSVGIcon} from '~/shared/components/styled';
import {PageHeader} from '~/shared/components/header';
import {trackEvent} from '~/shared/services/analytics';
import {selectUserReportOrderDetails} from '~/shared/store/selectors';
import {media} from '~/shared/theme/media';
import {api} from '~/shared/services/bffService';
import {is401Error, handleRefreshToken} from '~/shared/services/auth';

const Wrapper = styled.div<{isFullSize?: boolean}>`
  text-align: center;
  ${({isFullSize, theme}) =>
    isFullSize &&
    `
      background-color: ${theme.colors.surface};
      width: 100vw;
      height: 100vh;
      padding-top: 72px;
    `}
`;

const DialogHeader = styled.div`
  padding: 12px 16px 12px 16px;
  box-shadow: ${({theme}) => theme.shadows.modalHeaderShadow};
  ${media.minLargeTablet`    
      padding: 12px 0 12px 0;
  `}
`;

const DialogHeaderText = styled.div`
  ${body18Bold};
  color: ${({theme}) => theme.colors.secondary};
  ${media.minLargeTablet`
    width: 100%;
    margin: 0 auto;
  `}
`;
const DialogContent = styled.div`
  ${body16Bold};
  line-height: 24px;
  margin: 24px auto 40px;
  padding: 0 16px;
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
  ${media.minLargeTablet`
    width: 360px;
    margin: 24px auto 40px;
  `}
`;

const ButtonsWrapper = styled(FlexCenterVertically)`
  padding: 0 16px;
  flex-direction: column;
  width: 100%;
  ${media.minLargeTablet`
    flex-direction: row;
    ${flexCenterHorizontally};
  `}
`;

const CommonButton = styled(Button)`
  ${body14Bold};
  ${flexCenterHorizontally};
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  height: 50px;
  border-radius: 1px;
  padding: 14px 10px;
  width: 100%;
  ${media.minLargeTablet`
    ${flexCenterHorizontally};
    flex-direction: row;
    flex: 1;
    min-width: 172px;
  `}
`;

const CancelButton = styled(CommonButton)`
  background-color: ${({theme}) => theme.colors.surface};
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  margin-bottom: 14px;
  ${media.minLargeTablet`
    margin-bottom: 0;
    ${flipOnLTR`
      margin-left: 14px;
    `}
  `}
`;

const ContinueButton = styled(CommonButton)`
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  ${media.minLargeTablet`
    ${props => props.smaller && 'max-width: 172px;'}  
  `}
`;

const CloseDialogButton = styled(CloseButton)`
  top: 10px;
  ${flipOnLTR`
    left: 8px; 
  `}
  ${media.minLargeTablet`
    ${flipOnLTR`
      left: 16px; 
    `}
  `}
`;

type CancelOrderProps = {
  isFullSize?: boolean;
  onApprove?: () => void;
  onCancel?: () => void;
};

const CancelOrderWarning = ({isFullSize, onApprove, onCancel}: CancelOrderProps) => {
  const {t} = getLocalizationService();
  const {id: orderId, resId: restaurantId} = useSelector(selectUserReportOrderDetails);
  const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [responseError, setResponseError] = useState<string>();

  const handleContinueClick = async () => {
    try {
      setIsAwaitingResponse(true);
      await api.cancelActiveOrderByUser({orderId: orderId as unknown as number});

      trackEvent('hasCancelledOrder', {
        linkType: 'user report',
        orderHistoryTransactionID: orderId,
        restaurantID: restaurantId,
      });

      setIsCancelled(true);
    } catch (error: any) {
      if (is401Error(error)) {
        await handleRefreshToken(error, handleContinueClick);
        return;
      }

      setResponseError(error?.message);
    } finally {
      setIsAwaitingResponse(false);
    }
  };

  return (
    <>
      {isFullSize && <PageHeader />}
      <Wrapper isFullSize={isFullSize}>
        <DialogHeader>
          <DialogHeaderText id="modal-title">
            {isCancelled ? t('active_order_cancelled') : t('please_note')}
          </DialogHeaderText>
          <CloseDialogButton onClick={isCancelled ? onApprove : onCancel}>
            <CloseButtonSVGIcon />
          </CloseDialogButton>
        </DialogHeader>
        <DialogContent>
          {isCancelled || responseError
            ? responseError?.length
              ? responseError
              : t('active_order_cancelled_message')
            : t('cancel_order_message')}
        </DialogContent>
        <ButtonsWrapper>
          {isCancelled || responseError ? (
            <ContinueButton smaller={isFullSize} onClick={onApprove}>
              {t('close')}
            </ContinueButton>
          ) : (
            <>
              <CancelButton onClick={onCancel}>{t('dont_cancel_active_order')}</CancelButton>
              <ContinueButton
                onClick={handleContinueClick}
                disabled={isAwaitingResponse}
              >
                {t('i_would_like_to_cancel')}
              </ContinueButton>
            </>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </>
  );
};

export default CancelOrderWarning;
