import {LocationType} from '~/shared/consts/addressConsts';
import {AddressPartsFromKey} from '~/shared/utils/address';

import IAddress from './IAddress';
import IClientAddress from './IClientAddress';
import LocalAddress from './LocalAddress';

export type Address = IClientAddress & AddressFromServer;

export type AddressFromServer = IAddress & {
  addressId: number;
  apartmentNumber: string;
  entrance: string;
  floor: string;
  comments: string;
  longitude: number;
  latitude: number;
  nameOnDoor: string;
  phone01: string;
  phone02: string;
  isCompanyAddress: boolean;
  addressCompanyId: number;
  locationType: LocationType;
  locationName: string;
  restaurantDeliversToAddress: boolean;
  cityName: string;
  shiftId?: number;
};

export const isRemoteAddress = (address: IClientAddress | AddressPartsFromKey | LocalAddress): address is Address =>
  address && 'addressId' in address;
