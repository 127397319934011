import {
  Address,
  AddressFromServer,
  CityAutoComplete,
  CompanyAddress,
  Coupon,
  CuisineTypes,
  DishAssignedUsers,
  LinkMoneycardToUserRequest,
  MenuCategories,
  Order,
  OrderData,
  OrderSuccessData,
  Payment,
  ReOrderResponse,
  RestaurantAbout,
  RestaurantCoupons,
  RestaurantGroupRestaurants,
  RestaurantReviews,
  RestaurantList,
  StreetAutoComplete,
  Transaction,
  User,
  UserOrdersHistory,
  UserOrdersHistoryForRestaurant,
  FacebookLogin,
  SearchedDishList,
  OrderSubmitError,
  TenBisAddressLocationFromServer,
  InsertTransferDebitResponse,
  UserVouchers,
} from '~/shared/store/models';

import {ApiError} from '../apiErrorService';

import {ApiResponse} from './index';

const apiServiceConfig: ApiServiceConfig = {
  getUserAuthenticationDataAndSendAuthenticationCodeToUser: {
    url: 'GetUserAuthenticationDataAndSendAuthenticationCodeToUser',
    method: 'post',
    isAuthRequired: true,
  },
  getUserV2: {
    url: 'GetUserV2',
    method: 'post',
    noGetTimeStampParam: true,
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },
  getUser: {
    url: 'GetUser',
    method: 'post',
    noGetTimeStampParam: true,
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },
  facebookLogin: {
    url: 'FacebookLogin',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  signOut: {url: 'SignOut', method: 'post', isAuthRequired: false},

  // This function expects hebrew variables so you should probably use ~/shared/services/getTenBisAddressLocation
  getTenBisAddressLocationHebrewOnly: {
    url: 'GetTenBisAddressLocation',
    method: 'get',
    noGetTimeStampParam: true,
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  insertPayPalUserToken: {
    url: 'InsertPayPalUserToken',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },
  generatePayPalClientToken: {
    url: 'GeneratePayPalClientToken',
    method: 'get',
    noGetTimeStampParam: true,
    isAuthRequired: false,
  },

  getInsertNewCreditCardIframeUrl: {
    url: 'GetInsertNewCreditCardIframeUrl',
    method: 'get',
    isAuthRequired: true,
    useShoppingCartGuid: true,
  },
  getInsertNewCreditCardIframeUrlV2: {
    url: 'GetInsertNewCreditCardIframeUrl_V2',
    method: 'get',
    isAuthRequired: true,
    useShoppingCartGuid: true,
  },

  userTransactionsReport: {
    url: 'UserTransactionsReport',
    method: 'post',
    isAuthRequired: true,
  },
  getBarcodeOrder: {url: 'GetOrderBarcode', method: 'get', isAuthRequired: true},
  setTransferDebitRequestStatus: {url: 'SetTransferDebitRequestStatus', method: 'post', isAuthRequired: true},
  insertTransferDebitRequest: {url: 'InsertTransferDebitRequest', method: 'post', isAuthRequired: true},

  registerUser: {url: 'RegisterUser', method: 'post', useShoppingCartGuid: true, isAuthRequired: true},
  updateUserDetails: {
    url: 'UpdateUserDetails',
    method: 'post',
    useShoppingCartGuid: true,
    convertParams: false,
    isAuthRequired: true,
  },
  updateUserPassword: {
    url: 'UpdateUserPassword',
    method: 'post',
    useShoppingCartGuid: false,
    isAuthRequired: false,
    convertParams: false,
  },
  activateMoneycard: {url: 'ActivateMoneycard', method: 'post', isAuthRequired: true},

  getActivationTokenAndSendActivationCodeToUser: {
    url: 'GetActivationTokenAndSendActivationCodeToUser',
    method: 'get',
    isAuthRequired: false,
  },

  resetPassword: {url: 'ResetPassword', method: 'post', isAuthRequired: true},

  cityNameAutoComplete: {url: 'CityNameAutoComplete', isAuthRequired: false, method: 'get'},
  streetNameAutoComplete: {
    url: 'StreetNameAutoComplete',
    isAuthRequired: false,
    method: 'get',
  },
  isEmailFree: {url: 'IsEmailFree', isAuthRequired: false, method: 'get'},

  getRestaurantsByGroupId: {
    url: 'getRestaurantsByGroupId',
    method: 'get',
    isAuthRequired: false,
  },

  getRestaurantDescriptionAndFeatures: {
    url: 'GetRestaurantDescriptionAndFeatures',
    isAuthRequired: false,
    method: 'get',
    useShoppingCartGuid: false,
  },

  getRestaurantReviews: {
    url: 'GetRestaurantReviews',
    method: 'get',
    useShoppingCartGuid: false,
    isAuthRequired: false,
  },
  validateInsertReview: {
    url: 'ValidateInsertReview',
    method: 'post',
    useShoppingCartGuid: false,
    isAuthRequired: true,
  },
  upsertRestaurantReview: {
    url: 'UpsertRestaurantReview',
    method: 'post',
    useShoppingCartGuid: false,
    isAuthRequired: true,
  },
  getLastTransactionWithoutReview: {
    url: 'GetLastTransactionWithoutReview',
    method: 'post',
    isAuthRequired: true,
  },
  searchRestaurants: {
    url: 'searchRestaurants_V2',
    method: 'get',
    useShoppingCartGuid: true,
    sendIsMobileDevice: true,
    isAuthRequired: true,
  },
  getRestaurantMenu: {url: 'GetRestaurantMenu', method: 'get', useShoppingCartGuid: true, isAuthRequired: true},
  getOrderData: {
    url: 'getOrderData',
    method: 'get',
    useShoppingCartGuid: true,
    isAuthRequired: false,
  },

  setRestaurantInOrder: {
    url: 'SetRestaurantInOrder',
    method: 'post',
    useShoppingCartGuid: true,
    sendIsMobileDevice: true,
    isAuthRequired: true,
  },
  createLinkMoneycardToUserRequest: {
    url: 'CreateLinkMoneycardToUserRequest',
    method: 'post',
    isAuthRequired: true,
  },
  getLinkMoneycardToUserRequests: {url: 'GetLinkMoneycardToUserRequests', method: 'post', isAuthRequired: true},

  setDeliveryMethodInOrder: {
    url: 'SetDeliveryMethodInOrder',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },
  setUserInOrder: {url: 'SetUserInOrder', method: 'post', useShoppingCartGuid: true, isAuthRequired: true},
  setAddressInOrder: {url: 'SetAddressInOrder', method: 'post', useShoppingCartGuid: true, isAuthRequired: true},
  setTipInOrder: {
    url: 'SetTipInOrder',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: false,
  },

  // chooses the max value from all set coupons, including the currently set coupon in order
  // uf a better than a menually set coupon is chosen, it disappears from the order, because it's only stored there.
  chooseAndSetBestDiscountCouponValueInOrder: {
    method: 'post',
    url: 'ChooseAndSetBestDiscountCouponValueInOrder',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },
  // when set, the coupon stays in order until replaced with this call or by ChooseAndSetBestDiscountCouponValueInOrder
  // manually set coupons, that are per session, are stored only in the shopping cart itself
  // coupons per users will be kept in available coupons
  setCouponInOrder: {url: 'SetCouponInOrder', method: 'post', useShoppingCartGuid: true, isAuthRequired: true},
  // full coupons info for a restaurant to be used in the coupons modal/page UI
  getRestaurantDiscounts: {url: 'GetRestaurantDiscountsData', method: 'get', isAuthRequired: true},
  // a statefull api based on the last restaurant set in order.
  // returns the relevant coupons for the user including the current restaurant's coupon,
  // and coupons appended to the user from the user details page.
  // manually added coupons from setCouponInOrder will be returned from this function
  // if they are per user and not per session
  getAvailableCouponsForOrder: {
    url: 'GetAvailableCouponsForOrder',
    method: 'get',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  submitOrder: {
    url: 'SubmitOrder',
    method: 'post',
    useShoppingCartGuid: true,
    sendIsMobileDevice: true,
    timeout: 60000,
    isAuthRequired: true,
  },

  getAddressDetails: {url: 'GetAddressViewModel', method: 'get', isAuthRequired: false},

  getDishAssignedUsers: {
    url: 'GetDishAssignedUsers',
    method: 'get',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  getCompanyAddresses: {url: 'GetCompanyAddresses', method: 'get', isAuthRequired: true},
  appendCompanyAddressToUser: {url: 'AppendCompanyAddressToUser', method: 'post', isAuthRequired: true},

  getUserAddresses: {url: 'GetUserAddresses', method: 'post', isAuthRequired: true},
  updateAddress: {url: 'UpdateAddress_V2', method: 'post', isAuthRequired: true},
  insertAddress: {url: 'InsertAddress_V2', method: 'post', isAuthRequired: true},
  removeAddress: {url: 'RemoveAddress', method: 'post', isAuthRequired: true},

  getAvailablePayments: {
    url: 'GetPayments',
    method: 'get',
    useShoppingCartGuid: true,
    isAuthRequired: false,
  },
  setPaymentsInOrder: {
    url: 'SetPaymentsInOrder',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  getLinkMoneycardToUserRequestForLandingPage: {
    url: 'GetLinkMoneycardToUserRequestForLandingPage',
    method: 'post',
    isAuthRequired: true,
  },

  setLinkMoneycardToUserRequestStatus: {
    url: 'SetLinkMoneycardToUserRequestStatus',
    method: 'post',
    isAuthRequired: true,
  },

  setDishListInShoppingCart: {
    url: 'SetDishListInShoppingCart',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  setFutureTimeInOrder: {
    url: 'SetFutureTimeInOrder',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  getOrderSuccessData: {url: 'GetOrderSuccess', method: 'get', useShoppingCartGuid: false, isAuthRequired: true},

  sendShareOrderLink: {
    url: 'SendShareOrderLink',
    method: 'post',
    useShoppingCartGuid: false,
    isAuthRequired: true,
  },

  getOrdersHistory: {
    url: 'GetUserOrdersHistory',
    method: 'get',
    useShoppingCartGuid: false,
    isAuthRequired: true,
  },

  reorder: {url: 'ReOrder', method: 'get', useShoppingCartGuid: true, isAuthRequired: false},

  setPermitCodeInOrder: {
    url: 'SetPermitCodeInOrder',
    method: 'post',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },

  getSingleOrderViewInfo: {
    method: 'get',
    url: 'GetSingleOrderViewInfo',
    urlPrefix: 'Account/',
    isAuthRequired: true,
  },

  getMoneycardOtlRuleManagement: {url: 'GetMoneycardOtlRuleManagement', method: 'post', isAuthRequired: true},
  UpsertOverTheLimitRule: {url: 'UpsertOverTheLimitRule', method: 'post', isAuthRequired: true},
  searchDishesByCulture: {url: 'SearchDishes', method: 'post', isAuthRequired: true},

  getReOrderMenuItems: {
    url: 'GetReOrderMenuItems',
    method: 'get',
    useShoppingCartGuid: true,
    isAuthRequired: true,
  },
  getUserOrdersHistoryForRestaurant: {
    url: 'GetUserOrdersHistoryForRestaurant',
    method: 'get',
    isAuthRequired: true,
  },

  getRestaurantCuisineTypes: {url: 'GetRestaurantCuisineTypes', method: 'get', isAuthRequired: true},
};

export default apiServiceConfig;

type ApiServiceConfig = {
  [FunctionName in keyof ConfigFunctions]: {
    [Param in ConfigFunctions[FunctionName]['Params'][number]]: ApiServiceConfigParams[Param];
  };
};

export type ApiServiceConfigFunctions = {
  [FunctionName in keyof ConfigFunctions]: ApiServiceFunction<
    ConfigFunctions[FunctionName]['ReturnType'],
    ConfigFunctions[FunctionName]['ErrorType']
  >;
};

type GenericConfigFunction<
  P extends Array<keyof Omit<ApiServiceConfigParams, 'url' | 'method'>> = [],
  R = unknown,
  E extends ApiError = ApiError,
> = {
  Params: [...ConfigFunctionParamsBase, ...P];
  ReturnType: R;
  ErrorType: E;
};

export interface ApiServiceConfigParams {
  url: string;
  method: 'post' | 'get' | 'head';
  noGetTimeStampParam?: boolean;
  useShoppingCartGuid?: boolean;
  urlPrefix?: string;
  isAuthRequired: boolean;
  convertParams?: boolean;
  timeout?: number;
  sendIsMobileDevice?: boolean;
}

export interface ApiServiceFunction<Payload, Error extends ApiError = ApiError> {
  (params?: Record<string, any>, userPayload?: {useDefaultLocale?: boolean}): Promise<
    ApiResponse<Payload>
  >;
  (params?: Record<string, any>, userPayload?: {useDefaultLocale?: boolean}): Promise<
    ApiResponse<Payload | Error>
  >;
}

type ConfigFunctionParamsBase = ['url', 'method', 'isAuthRequired'];

interface ConfigFunctions {
  getUserAuthenticationDataAndSendAuthenticationCodeToUser: GenericConfigFunction;
  getUserV2: GenericConfigFunction<['noGetTimeStampParam', 'useShoppingCartGuid'], User>;
  getUser: GenericConfigFunction<['noGetTimeStampParam', 'useShoppingCartGuid'], User>;
  facebookLogin: GenericConfigFunction<['useShoppingCartGuid'], FacebookLogin>;
  signOut: GenericConfigFunction;
  getTenBisAddressLocationHebrewOnly: GenericConfigFunction<
    ['noGetTimeStampParam', 'useShoppingCartGuid'],
    TenBisAddressLocationFromServer
  >;
  insertPayPalUserToken: GenericConfigFunction<['useShoppingCartGuid']>;
  generatePayPalClientToken: GenericConfigFunction<['noGetTimeStampParam'], string>;
  getInsertNewCreditCardIframeUrl: GenericConfigFunction<['useShoppingCartGuid']>;
  getInsertNewCreditCardIframeUrlV2: GenericConfigFunction<['useShoppingCartGuid']>;
  userTransactionsReport: GenericConfigFunction;
  getBarcodeOrder: GenericConfigFunction<[], UserVouchers>;
  setTransferDebitRequestStatus: GenericConfigFunction;
  insertTransferDebitRequest: GenericConfigFunction<[], InsertTransferDebitResponse>;
  registerUser: GenericConfigFunction<['useShoppingCartGuid'], User>;
  updateUserDetails: GenericConfigFunction<['useShoppingCartGuid', 'convertParams']>;
  updateUserPassword: GenericConfigFunction<['useShoppingCartGuid', 'convertParams']>;
  activateMoneycard: GenericConfigFunction;
  getActivationTokenAndSendActivationCodeToUser: GenericConfigFunction<[]>;
  resetPassword: GenericConfigFunction<[], boolean>;
  cityNameAutoComplete: GenericConfigFunction<[], CityAutoComplete[]>;
  streetNameAutoComplete: GenericConfigFunction<[], StreetAutoComplete[]>;
  isEmailFree: GenericConfigFunction<[]>;
  getRestaurantsByGroupId: GenericConfigFunction<[], RestaurantGroupRestaurants>;
  getRestaurantDescriptionAndFeatures: GenericConfigFunction<['useShoppingCartGuid'], RestaurantAbout>;
  getRestaurantReviews: GenericConfigFunction<['useShoppingCartGuid'], RestaurantReviews>;
  validateInsertReview: GenericConfigFunction<['useShoppingCartGuid'], boolean>;
  upsertRestaurantReview: GenericConfigFunction<['useShoppingCartGuid'], number>;
  getLastTransactionWithoutReview: GenericConfigFunction<[], Transaction>;
  searchRestaurants: GenericConfigFunction<['useShoppingCartGuid', 'sendIsMobileDevice'], RestaurantList>;
  getRestaurantMenu: GenericConfigFunction<['useShoppingCartGuid'], MenuCategories>;
  getOrderData: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  setRestaurantInOrder: GenericConfigFunction<['useShoppingCartGuid', 'sendIsMobileDevice'], OrderData>;
  createLinkMoneycardToUserRequest: GenericConfigFunction;
  getLinkMoneycardToUserRequests: GenericConfigFunction<[], LinkMoneycardToUserRequest[]>;
  setDeliveryMethodInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  setUserInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  setAddressInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  setTipInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  chooseAndSetBestDiscountCouponValueInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  setCouponInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  getRestaurantDiscounts: GenericConfigFunction<[], RestaurantCoupons>;
  getAvailableCouponsForOrder: GenericConfigFunction<['useShoppingCartGuid'], Coupon[]>;
  submitOrder: GenericConfigFunction<['useShoppingCartGuid', 'sendIsMobileDevice', 'timeout'], Order, OrderSubmitError>;
  getAddressDetails: GenericConfigFunction<[], Address>;
  getDishAssignedUsers: GenericConfigFunction<['useShoppingCartGuid'], DishAssignedUsers>;
  getCompanyAddresses: GenericConfigFunction<[], CompanyAddress[]>;
  appendCompanyAddressToUser: GenericConfigFunction;
  getUserAddresses: GenericConfigFunction<[], AddressFromServer[]>;
  updateAddress: GenericConfigFunction<[], number>;
  insertAddress: GenericConfigFunction<[], number>;
  removeAddress: GenericConfigFunction<[], number>;
  getAvailablePayments: GenericConfigFunction<['useShoppingCartGuid'], Payment[]>;
  setPaymentsInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  getLinkMoneycardToUserRequestForLandingPage: GenericConfigFunction;
  setLinkMoneycardToUserRequestStatus: GenericConfigFunction<[], LinkMoneycardToUserRequest>;
  setDishListInShoppingCart: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  setFutureTimeInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  getOrderSuccessData: GenericConfigFunction<['useShoppingCartGuid'], OrderSuccessData>;
  sendShareOrderLink: GenericConfigFunction<['useShoppingCartGuid'], boolean>;
  getOrdersHistory: GenericConfigFunction<['useShoppingCartGuid'], UserOrdersHistory>;
  reorder: GenericConfigFunction<['useShoppingCartGuid'], ReOrderResponse>;
  setPermitCodeInOrder: GenericConfigFunction<['useShoppingCartGuid'], OrderData>;
  getSingleOrderViewInfo: GenericConfigFunction<['urlPrefix']>;
  getMoneycardOtlRuleManagement: GenericConfigFunction;
  UpsertOverTheLimitRule: GenericConfigFunction;
  searchDishesByCulture: GenericConfigFunction<[], SearchedDishList>;
  getReOrderMenuItems: GenericConfigFunction<['useShoppingCartGuid']>;
  getUserOrdersHistoryForRestaurant: GenericConfigFunction<[], UserOrdersHistoryForRestaurant>;
  getRestaurantCuisineTypes: GenericConfigFunction<[], CuisineTypes>;
}
