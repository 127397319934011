import {useMemo} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import Disclaimer from '~/shared/components/Disclaimer';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectCurrentAddress} from '~/shared/store/selectors';
import {DiscountCompanyIds} from '~/shared/consts/restaurantConsts';
import {Address} from '~/shared/store/models';

export interface IFutureOrderDisclaimer {
  shouldShowCancelDisclaimer: boolean;
}

const FODisclaimer = styled(Disclaimer)`
  margin-top: 8px;
  line-height: normal;
`;

const FutureOrderDisclaimer = ({shouldShowCancelDisclaimer}: IFutureOrderDisclaimer) => {
  const {t} = getLocalizationService();
  const currentAddress = useSelector(selectCurrentAddress) as Address;

  const cancelationText = useMemo(() => {
    if (DiscountCompanyIds.includes(Number(currentAddress?.addressCompanyId))) {
      return t('disclaimer_discount');
    }
    return t('disclaimer_cancel');
  }, [currentAddress, t]);

  return (
    <FODisclaimer title={t('disclaimer_estimate')} showInfoIcon>
      {shouldShowCancelDisclaimer && cancelationText}
    </FODisclaimer>
  );
};

export default FutureOrderDisclaimer;
