import {useCallback, useEffect, useMemo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {isUndefined} from 'lodash';
import numeral from 'numeral';

import {
  selectIsOrderBarcodeEnable,
  selectOrderSummaryData,
  selectOrderSummaryError,
  selectOrderSummaryLoading,
  selectUserData,
} from '~/shared/store/selectors';
import {getLocalizationService} from '~/shared/services/localisationService';
import {PageHeader} from '~/shared/components/header';
import {media} from '~/shared/theme/media';
import {body14Bold, body16Bold} from '~/shared/theme/typography';
import {CloseButton as _CloseButton, ModalContent as _ModalContent} from '~/shared/components/Modals';
import {flipOnLTR, rotateOnRTL} from '~/shared/theme/utils';
import {Section, OrderDetailsLine} from '~/shared/components/OrderCompleteSections/parts';
import {getArrivalText} from '~/shared/utils/dates';
import BackArrow from '~/assets/images/svgIconsAsText/back-arrow.svg?react';
import {goBack, Link, useLocation} from '~/shared/router';
// eslint-disable-next-line @welldone-software/modules-engagement
import {OrderSuccessLoader} from '~/orderSuccess/components/OrderSuccessView';
import {SkeletonLoader} from '~/shared/components/Loaders';
import {OrderDetails, ShareOrder} from '~/shared/components/OrderCompleteSections';
import Button from '~/shared/components/Button';
import {flexCenter, flexCenterVertically} from '~/shared/theme/FlexLayout';
import {CloseButtonSVGIcon, InnerWidthContainer, SubheaderContainer} from '~/shared/components/styled';
import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {UserVoucher} from '~/shared/store/models/User/UserVouchers';
import printCoupon from '~/shared/utils/printCoupon';
import PaymentsDetails from '~/shared/components/OrderCompleteSections/PaymentsDetails';
import {handleOnReorderClick} from '~/shared/utils/reorder/utils';
import {
  MOBILE_SUBHEADER_HEIGHT,
  DESKTOP_SUBHEADER_HEIGHT,
  paddingCss,
} from '~/shared/components/OrderCompleteSections/orderSummaryStyle';
import {NumericFormats} from '~/shared/consts/checkoutConsts';
import {setCurrentModal} from '~/shared/store/actions';
import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {RestaurantBusinessTypeName} from '~/shared/store/models';
import {getTimeRangeLabel, isValidTimeRangeString} from '~/shared/utils/timeRangeHelper';
import RestaurantMultipassCard from '~/common/components/RestaurantMultipassCard';

import OrderSummaryBarcode from './OrderSummaryBarcode';
import OrderSummaryPayments from './OrderSummaryPayments';
import OrderSummaryButtons from './OrderSummaryButtons';

const DialogHeader = styled(SubheaderContainer)<{isFullSize?: boolean}>`
  padding: 0 8px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};
  border: 0;
  margin-top: ${({theme}) => theme.header.height}px;
  display: flex;
  align-items: center;
  min-height: ${({isFullSize}) => (isFullSize ? MOBILE_SUBHEADER_HEIGHT : DESKTOP_SUBHEADER_HEIGHT)}px;
  text-align: ${({isFullSize}) => (isFullSize ? 'unset' : 'center')};
  ${media.minLargeMobile`  
    margin: 0;
    height: ${DESKTOP_SUBHEADER_HEIGHT}px;
  `}
`;

const DialogHeaderText = styled.div`
  ${body16Bold};
  color: ${({theme}) => theme.colors.secondary};
  ${media.minLargeTablet`
    width: 100%;
    margin: 0 auto;
    padding: 0;
  `}
  ${flipOnLTR`
    padding-right: 4px;
  `}
`;

const BackButton = styled(Button)`
  ${flexCenterVertically};
`;

const BackIcon = styled.div<{alt: string}>`
  ${flexCenter};
  width: 24px;
  height: 24px;
  ${rotateOnRTL}
`;

const Arrow = styled(BackArrow)`
  display: flex;
  cursor: pointer;
`;

const OrderSummaryDetails = styled(Section)<{stretch?: boolean}>`
  align-items: ${({stretch}) => (stretch ? 'stretch' : 'flex-start')};
  ${paddingCss}
`;

const Container = styled(InnerWidthContainer)<{isLoading?: boolean}>`
  flex-direction: column;
  ${({isLoading}) => isLoading && 'padding: 40px;'}
  ${media.minLargeTablet`
    max-height: calc(90vh - ${MOBILE_SUBHEADER_HEIGHT}px);
    overflow-y: auto;
  `}
`;

const OrderSummaryShareOrder = styled(ShareOrder)`
  ${paddingCss};
`;

const HeaderTitleContainer = styled(InnerWidthContainer)`
  position: relative;
`;

const CloseButton = styled(_CloseButton)`
  display: flex;
  align-items: center;
  top: -4px;
  ${flipOnLTR`
    left: 0;
  `}
`;

const StyledPhone = styled(Link)`
  color: ${({theme}) => theme.colors.secondary};
`;

const DisabledOnDesktopDiv = styled.div`
  ${media.minMobile`
    pointer-events: none;
  `};
`;

const OrderSummaryDishes = styled(OrderDetails)`
  ${paddingCss};
`;

const OrderSummaryPaymentsDetails = styled(PaymentsDetails)`
  ${paddingCss};
`;

const OrderDetailsBoldLine = styled.div`
  ${body14Bold};
  margin-top: 10px;
`;

type OrderSummaryViewProps = {
  onCloseModal?: () => void;
  isFullSize?: boolean;
  hideReorderButton?: boolean;
};

const DEFAULT_DELIVERY_METHOD = 'delivery';
// when passing the orderDate to moment, its required to provide with a date format
const API_DATE_FORMAT = 'DD/MM/YYYY';

const OrderSummaryView = ({onCloseModal: closeModal, isFullSize, hideReorderButton}: OrderSummaryViewProps) => {
  const {t, currentLanguageKey, currentLanguageDirection: direction} = getLocalizationService();
  const isRTL = currentLanguageKey === 'he';
  const orderSummary = useSelector(selectOrderSummaryData);
  const userData = useSelector(selectUserData);
  const {isGovCompany, firstName, lastName} = userData || EMPTY_OBJECT;
  const {query} = useLocation();
  const dispatch = useDispatch();
  const isGroceriesStore = orderSummary?.restaurant?.businessType === RestaurantBusinessTypeName.GroceryStore;

  const orderSummaryLoading = useSelector(selectOrderSummaryLoading);
  const orderSummaryError = useSelector(selectOrderSummaryError);
  const isOrderBarcodeEnable = useSelector(selectIsOrderBarcodeEnable);

  useEffect(() => {
    if (!orderSummaryLoading && orderSummaryError) {
      if (orderSummaryError.status === 401) {
        return;
      }

      dispatch(
        setCurrentModal('generalErrorModal', {
          headerText: t('error'),
          contentText: orderSummaryError.message,
          buttons: [
            {
              label: t('approve'),
              onClick: (closeModalFn: () => void) => {
                closeModalFn();
              },
            },
          ],
        }),
      );
    }
  }, [dispatch, orderSummaryError, orderSummaryLoading, t]);

  const shouldShowLoader =
    isUndefined(orderSummary) || isUndefined(orderSummary?.address) || isUndefined(orderSummary?.restaurant);

  const {
    orderId,
    orderTime,
    orderDate,
    arrivalOrderTime: _arrivalOrderTime,
    restaurant,
    barcode,
    dishToPresent,
    deliveryMethod,
    address,
    isBarcodeOrder,
    billingLines,
    orderPayments,
    numOfLoyaltyProgramPointsEarned,
    shareOrderEnabled,
    arrivalOrderDate,
    userDoesNotWantCutlery,
    orderStatus,
    remarksRequired,
    remarks,
    arrivalOrderDateTimeText,
    orderTotal,
  } = orderSummary;

  const arrivalText = arrivalOrderDate && getArrivalText(arrivalOrderDate, API_DATE_FORMAT);
  const orderFixedTime =
    orderTime && orderTime.includes(':00') && orderTime.split(':').length > 2 ? orderTime.slice(0, -3) : orderTime;
  const showAgeRestrictionDisclaimer = dishToPresent?.some(({ageRestricted}) => ageRestricted);
  const _deliveryMethod = deliveryMethod?.toLowerCase?.();
  const barcodeSum = numeral(barcode?.amount).format(NumericFormats.showZeroDecimals);
  const barcodeSumText = isRTL ? `₪${barcodeSum}` : `${barcodeSum}₪`;
  const barcodeValidDate = barcode?.validDate?.replaceAll('/', '.');
  const barcodeUsedDate = barcode?.dateUsed?.replaceAll('/', '.');
  const orderIdString = String(orderId);
  const formatedOrderId = `${orderIdString.slice(0, -4)}-${orderIdString.slice(-4)}`;
  const isPickup = _deliveryMethod === DeliveryMethods.PICKUP;
  const addressLineLabel = isPickup ? t('your_address') : t('address_for_delivery');
  const isVoucherCard = restaurant?.businessType === RestaurantBusinessTypeName.VoucherCard;

  const summaryDetailsTitle = (() => {
    if (isBarcodeOrder) {
      return 'order_summary_coupon_details';
    }

    if (isVoucherCard) {
      return 'multipass_subheader_details';
    }

    if (_deliveryMethod === 'notrecognized') {
      return `order_summary_${DEFAULT_DELIVERY_METHOD}_details`;
    }

    return `order_summary_${_deliveryMethod || DEFAULT_DELIVERY_METHOD}_details`;
  })();

  const resType = isBarcodeOrder ? 'net' : 'restaurant';

  const getOrderSummaryLabel = useMemo(() => {
    if (!isBarcodeOrder && isGroceriesStore && !barcode?.amount) {
      return t('order_summary_store');
    }

    return t(`order_summary_${resType}`);
  }, [barcode?.amount, isBarcodeOrder, isGroceriesStore, resType, t]);

  const print = useCallback(
    () =>
      printCoupon({
        restaurantName: restaurant?.restaurantName,
        barCode: barcode,
        barcodeSumText,
        direction,
        isGovCompany,
        firstName,
        lastName,
        t,
      }),
    [restaurant, barcodeSumText, barcode, direction, isGovCompany, firstName, lastName, t],
  );

  const arrivalOrderTime = isValidTimeRangeString(arrivalOrderDateTimeText) ?
    getTimeRangeLabel(arrivalOrderDateTimeText) :
    _arrivalOrderTime;

  return (
    <>
      {isFullSize && <PageHeader />}
      <DialogHeader isFullSize={isFullSize}>
        <HeaderTitleContainer>
          {isFullSize && (
            <BackButton onClick={goBack}>
              <BackIcon alt={t('back')}>
                <Arrow />
              </BackIcon>
            </BackButton>
          )}
          <DialogHeaderText id="modal-title">{t('order_summary')}</DialogHeaderText>
          {!isFullSize && (
            <CloseButton data-test-id="modalCloseButton" onClick={closeModal} aria-label={t('close')}>
              <CloseButtonSVGIcon />
            </CloseButton>
          )}
        </HeaderTitleContainer>
      </DialogHeader>
      <Container isLoading={shouldShowLoader}>
        <SkeletonLoader shouldShowLoader={shouldShowLoader} LoaderComponent={OrderSuccessLoader}>
          {orderSummary && (
            <>
              {/* @ts-expect-error TODO: Section -> needs to be rewritten with ts */}
              <OrderSummaryDetails title={t(summaryDetailsTitle)}>
                {!isVoucherCard && (
                  <OrderDetailsLine
                    data-test-id="order_details_restaurant"
                    label={getOrderSummaryLabel}
                  >
                    {restaurant?.restaurantName}
                  </OrderDetailsLine>
                )}
                {isPickup && (
                  <OrderDetailsLine
                    data-test-id="order_details_resFullAddress"
                    label={t('restaurant_address')}
                    isCapitalizedLabel
                  >
                    {restaurant.fullAddress}
                  </OrderDetailsLine>
                )}
                {isBarcodeOrder && (
                  <OrderDetailsLine data-test-id="order_details_barcode_sum" label={t('sum')}>
                    {barcodeSumText}
                  </OrderDetailsLine>
                )}
                {isVoucherCard && (
                  <OrderDetailsLine label={t('total')}>₪{orderTotal}</OrderDetailsLine>
                )}
                {orderTime && orderDate && (
                  <OrderDetailsLine
                    data-test-id="order_details_date_and_time"
                    label={t('order_summary_date')}
                  >
                    {orderDate}, {orderFixedTime}
                  </OrderDetailsLine>
                )}
                {isBarcodeOrder && (
                  <OrderDetailsLine label={t('order_user_name')} data-test-id="order_details_barcode_user">
                    {firstName} {lastName}
                  </OrderDetailsLine>
                )}
                {isBarcodeOrder &&
                  (barcode.used ? (
                    <OrderDetailsBoldLine data-test-id="order_details_barcode">
                      {t('the_voucher_was_used_both', {date: barcodeUsedDate, branchName: barcode.usedInBranchName})}
                    </OrderDetailsBoldLine>
                  ) : (
                    <OrderDetailsBoldLine data-test-id="order_details_barcode">
                      {t('order_success_coupon_remarks', {validDate: barcodeValidDate})}
                    </OrderDetailsBoldLine>
                  ))}
                {!isVoucherCard && !isBarcodeOrder && arrivalText && (
                  <OrderDetailsLine
                    data-test-id="order_details_arrival"
                    label={t('order_summary_expected_delivery_time')}
                  >
                    {arrivalText}, {arrivalOrderTime}
                  </OrderDetailsLine>
                )}
                {restaurant?.restaurantPhone.length > 1 && !isVoucherCard && (
                  <DisabledOnDesktopDiv>
                    <OrderDetailsLine data-test-id="order_details_phone" label={t('phone_number_for_information')}>
                      <StyledPhone href={`tel:${restaurant?.restaurantPhone}`} target="_blank">
                        {restaurant?.restaurantPhone}
                      </StyledPhone>
                    </OrderDetailsLine>
                  </DisabledOnDesktopDiv>
                )}
                {!isVoucherCard && !isBarcodeOrder && (
                  <OrderDetailsLine label={addressLineLabel} data-test-id="order_details_address">
                    {address?.streetName} {address?.houseNumber}, {address?.cityName}
                    {remarksRequired && remarks && userData && userData.userEnabledFeatures.includes('ShowDiscountDiningRoomSelection') && ` - ${remarks}`}
                  </OrderDetailsLine>
                )}
                {isPickup && (
                  <OrderDetailsLine data-test-id="order_details_order_number" label={t('order_number')}>
                    {formatedOrderId}
                  </OrderDetailsLine>
                )}
                {!isBarcodeOrder && (address?.comments || userDoesNotWantCutlery) && (
                  <OrderDetailsLine data-test-id="order_details_remarks" label={t('remarks')}>
                    {address?.comments}
                    {userDoesNotWantCutlery && (
                      <>
                        <br />
                        {t('i_support_the_environment_so_i_will_pass_on_the_cutlery')}
                      </>
                    )}
                  </OrderDetailsLine>
                )}
              </OrderSummaryDetails>
              {isVoucherCard && (
                // @ts-expect-error TODO: - refactor section to ts
                <OrderSummaryDetails title={t('card_info')} stretch>
                  <RestaurantMultipassCard orderData={orderSummary} noTitle />
                </OrderSummaryDetails>
              )}
              {isBarcodeOrder ? (
                <>
                  <OrderSummaryBarcode isGov={isGovCompany} barcode={barcode as UserVoucher} />
                  <OrderSummaryPaymentsDetails payments={orderPayments} />
                </>
              ) : (
                <>
                  {/* @ts-expect-error TODO: Section -> needs to be rewritten with ts */}
                  {!isVoucherCard && <OrderSummaryDishes dishes={dishToPresent} showDishDetails />}
                  <OrderSummaryPayments
                    billingLines={billingLines}
                    points={numOfLoyaltyProgramPointsEarned}
                    showAgeRestriction={showAgeRestrictionDisclaimer}
                    payments={orderPayments}
                    barcodeOrderId={(deliveryMethod?.toLocaleLowerCase() === DeliveryMethods.PICKUP && isOrderBarcodeEnable && orderStatus !== 'Canceled') ? orderId : undefined}
                    isVoucherCard={isVoucherCard}
                  />
                </>
              )}
              {/* @ts-expect-error TODO: Section -> needs to be rewritten with ts */}
              {shareOrderEnabled && <OrderSummaryShareOrder orderId={orderId} />}
              <OrderSummaryButtons
                isVoucherCard={isVoucherCard}
                isBarcodeOrder={isBarcodeOrder}
                onPrint={print}
                onClose={closeModal}
                onReorder={
                  hideReorderButton || query?.showOrder
                    ? undefined
                    : () =>
                        handleOnReorderClick({
                          orderId,
                          restaurantId: restaurant?.restaurantId,
                          reorderItems: [],
                          linkType: 'order summary',
                        })
                }
              />
            </>
          )}
        </SkeletonLoader>
      </Container>
    </>
  );
};

export default OrderSummaryView;
