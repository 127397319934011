import styled, {css} from 'styled-components';

import {media, flipOnLTR} from '~/shared/theme/utils';
import {LongButton} from '~/shared/components/Modals';
import Button from '~/shared/components/Button';
import {flexCenter, flexColumn} from '~/shared/theme/FlexLayout';

import {body13Normal, body14Normal} from '../theme/typography';

export const Label = styled.label`
  text-align: center;
  margin-bottom: 15px;
  color: ${({theme}) => theme.colors.secondary};
`;

export const TextButton = styled(Button)`
  cursor: pointer;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  ${body13Normal};
  line-height: 17px;
  text-decoration: underline;
`;

export const ForgotPasswordLink = styled(TextButton)`
  text-align: end;
  text-decoration: none;
  ${body14Normal};
  line-height: 16px;
  align-self: flex-end;
`;

export const CenteredTextButtonWrapper = styled.div`
  ${flexCenter};
  width: 100%;
  margin-top: 24px;
`;

export const InputWrapper = styled.div`
  margin-bottom: ${({noMarginBottom}) => (noMarginBottom ? 0 : 25)}px;
`;

export const FormWrapper = styled.form`
  ${flexColumn};
  ${media.minMobile`
    margin-bottom:0;
  `};
`;

export const Body = styled.div`
  flex: 1 0 auto;
  ${flexColumn};
  padding: 0 20px 20px 20px;
  ${media.minMobile`
    padding: 20px 130px;
  `};
  position: static;
`;

export const ErrorText = styled.div.attrs(() => ({
  role: 'alert',
}))`
  ${body13Normal};
  line-height: 1em;
  color: ${({theme}) => theme.colors.error};
  ${flipOnLTR`
    text-align: right;
  `};
`;

export const ResendResError = styled(ErrorText)`
  text-align: center;
  line-height: inherit;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  color: ${({theme}) => theme.colors.gray300};
  ${body14Normal};
  ${flipOnLTR`
    text-align: right;
  `};
  margin-top: 20px;
`;

export const FormContentRoot = styled.div`
  ${flexColumn};

  ${/* sc-selector */ FieldWrapper}:first-child {
    margin-top: 0;
  }
`;

export const LoginFormWrapper = styled.div`
  ${flexColumn};
  height: 280px;
`;

export const FormContainer = styled(LoginFormWrapper)`
  justify-content: end;
  ${media.minMobile`
    justify-content: start;
  `};
`;

export const LoginFormFieldsWrapper = styled.div`
  ${flexColumn};
`;

export const LoginBody = styled(Body)`
  padding: 0 20px 20px 20px;
  ${media.minMobile`
    padding: 25px 80px 32px 80px;
  `};
  ${({pageView}) =>
    pageView &&
    css`
      width: 100%;
      padding-top: 10px;
      ${media.minMobile`
      width: 550px;
      margin: 0 auto;
    `}
    `}
`;

export const BodyWrapper = styled.div`
  ${media.maxMobile`
    margin-top: 40px;
  `}
  background-color: ${({backgroundColor, theme}) => backgroundColor || theme.modal.backgroundColor};
  position: static;
  ${media.minTablet`
    ${({pageView}) =>
    pageView &&
      `
      width: 600px;
      height: auto;
      top: 8vh;
      margin: 0 auto;
    `}
  `}
`;

export const HeaderText = styled.div`
  ${body14Normal};
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
  margin: 0;
`;

export const Email = styled(HeaderText)`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const SubmitButton = styled(LongButton)`
  border-radius: 0;
`;

export const BlueLinkButton = styled(Button)`
  color: ${({theme}) => theme.colors.surfacePrimaryAction};

  &&& {
    ${({isDisabled, theme}) =>
    isDisabled &&
      `
      color: ${theme.colors.gray500};
      background: transparent;
    `}
  }

  &:first-child {
    margin-bottom: 5px;
  }
`;

export const LinkButtonsWrapper = styled.div`
  margin-top: 15px;
  ${flexColumn};
`;

export const StyledErrorText = styled(ErrorText)`
  position: absolute;
  width: 100%;
  top: 75px;
  height: 15px;
  background: ${({theme}) => theme.modal.backgroundColor};
`;

export const BackButton = styled(Button)`
  display: flex;
  position: absolute;
  z-index: 3;
  ${flipOnLTR`
    right: 10px;
  `}
  top: 20px;
  ${({theme}) =>
    theme.isLTR &&
    `
    transform: rotate(180deg);
  `}
  ${({pageView}) =>
    pageView &&
    css`
      ${flipOnLTR`
      right: 0;
    `}
    `}
`;

export const BackButtonSvg = ({className}) => (
  <svg
    className={className}
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g id="Back" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle-3" fill="#FFFFFF" fillRule="nonzero" opacity="0" x="0" y="0" width="40" height="40" rx="3" />
      <g
        id="ic_forward"
        transform="translate(10.000000, 8.000000)"
        stroke="#0a3847"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) ">
          <g strokeWidth="2">
            <g transform="translate(8.000000, 4.000000)">
              <path d="M8,0 L0,8" />
              <path d="M0,8 L8,16" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ReSendCodeIndication = styled.label.attrs({
  role: 'alert',
})`
  text-align: center;
  margin-top: 10px;
`;
