import {AxiosRequestConfig} from 'axios';

import {OrderData, OrderScooberJob, RestaurantFromGet} from '~/shared/store/models';
import {ActiveOrder} from '~/shared/types/ActiveOrderBannerTypes';

import {get, patch, post, apiDelete, put} from '../http';

import {IRestaurantGroupsParams, IGetRestaurantMenuParams, IGetRestaurantParams, IOrderParams, IMarketingBannerParams, ILoadTenbisCredit, IUpdateUserParams, IUserConfig} from './interfaces/payloads';
import {IRestaurantGroupsRes, IRestaurantMenuRes, IMarketingBannerRes, IOrderRes, IRefreshTokenRes, ILoadTenbisCreditResponse, IUpdateUserRes, IVoucherCardRes, IDiscountResponse} from './interfaces/responses';

export const updateUserDetails = (params: IUpdateUserParams, config: IUserConfig) =>
  put<IUpdateUserRes>('v1/User/Details', params, config);

export const removeUserAccount = (config: IUserConfig) => apiDelete('v1/User', undefined, config);

export const marketingSubscription = (subscribe: boolean, unsubscribeToken: string) =>
  patch<string>(
    `v1/User/marketingSubscription/${subscribe}`,
    {},
    {},
    {
      headers: {unsubscribeToken},
    },
  );

export const fetchRestaurantGroups = (params: IRestaurantGroupsParams) =>
  get<IRestaurantGroupsRes>(`v1/RestaurantGroups/${params.type}`);

export const fetchRestaurantMenu = ({id, addressId, dateTime}: IGetRestaurantMenuParams) => {
  const options: Omit<IGetRestaurantMenuParams, 'id'> = {
    addressId,
    dateTime,
  };

  return get<IRestaurantMenuRes>(`v1/Restaurants/${id}/Menu`, options);
};

export const fetchRestaurant = ({id, addressId, longitude, latitude}: IGetRestaurantParams) => {
  const options: Omit<IGetRestaurantParams, 'id'> = {
    addressId,
    longitude,
    latitude,
  };
  return get<RestaurantFromGet>(`v1/Restaurants/${id}`, options);
};

export const fetchMarketingBanners = (params: IMarketingBannerParams, headers?: AxiosRequestConfig['headers']) =>
  get<IMarketingBannerRes>('v1/MarketingBanners', params, {headers});

export const cancelActiveOrderByUser = (params: IOrderParams) => patch(`v1/Orders/${params.orderId}/Cancel`);

export const getRefreshToken = () => post<IRefreshTokenRes>('v1/Authentication/RefreshToken');

export const fetchOrderData = (params: IOrderParams) => get<IOrderRes>(`v2/Orders/${params.orderId}`);

export const fetchVoucherCardByOrderId = (params: {orderId: OrderData['orderId']}) => get<IVoucherCardRes>(`v1/VoucherCards?orderId=${params.orderId}`);

export const fetchActiveOrders = () => get<{orderBanners: ActiveOrder[]}>('v1/Orders/Banners');

// export const fetchScooberJob = (orderId: OrderData['orderId']) => get<OrderScooberJob>(`v1/Orders/${orderId}/ScooberJob`);
export const fetchScooberJobV2 = (orderId: OrderData['orderId']) => get<OrderScooberJob>(`v2/Orders/${orderId}/ScooberJob`);

export const loadTenbisCredit = (params: ILoadTenbisCredit) => patch<ILoadTenbisCreditResponse>('v1/Payments/LoadTenbisCredit', {}, params);

export const fetchDiscountInfoById = ({discountId}: {discountId: OrderData['discountCoupon']['id']}) => {
  return get<IDiscountResponse>(`v1/Discounts/Coupons/${discountId}`);
};
